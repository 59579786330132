import { Grid, Typography } from "@mui/material";
import React from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/Add";
import CollapseIcon from "@mui/icons-material/Remove";

const FAQElement = (props) => {
  const questionInfo = props.questionInfo;
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Grid md={6} xs={12}>
        <Typography
          mt={7}
          mb={2}
          sx={{
            color: "#2B5BAA",
            fontSize: { xs: "1.5rem", lg: "1.8rem" },
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
            textAlign: "center",
          }}
        >
          {questionInfo[0].title}
        </Typography>
        {questionInfo[0].questions.map((question, index) => (
          <Grid sx={{ mt: 1 }}>
            <Accordion
              expanded={expanded === "panel" + index}
              onChange={handleChange("panel" + index)}
              sx={{
                m: "auto",
                mt: 1,
                boxShadow: 3,
                "&:hover": { boxShadow: 5 },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel" + index ? (
                    <CollapseIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  sx={{
                    mt: 1,
                    color: "#6b6a6a",
                    fontSize: { xs: "1.3rem", lg: "1.5rem" },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "light",
                  }}
                >
                  {question.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{
                    mt: 0,
                    color: "#6b6a6a",
                    fontSize: { xs: "1.1rem", lg: "1.3rem" },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "light",
                  }}
                >
                  {question.description}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default FAQElement;
