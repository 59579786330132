import React from "react";
import NewsArticle34 from "../elements/NewsArticle34";
import NewsNav from "../elements/NewsNav";

const NewsDetails34 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle34 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails34;