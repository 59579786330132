import React from "react";
import ContactCallMail from "../elements/ContactCallMail";
import ExciseRatesForm from "../elements/ExciseRatesForm";

const DieselRates = () => {
  return (
    <>
      <ExciseRatesForm />
      <ContactCallMail />
    </>
  );
};

export default DieselRates;
