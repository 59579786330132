import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import CoreActivitiesImage from "../../assets/CoreActivities.png";
import { Container } from "@mui/system";
import Image from "mui-image";

const CoreActivities = () => {
  return (
    <>
      <Container maxWidth="100%" sx={{ m: 0, p: 0 }}>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: { xs: 3, md: 5 },
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "71px" },
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Kernactiviteiten
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Delta biedt <span style={{ color: "#9c29ff" }}> u </span> een
                  totaal ontzorg-concept, waarin wij u het proces van uw
                  <span style={{ color: "#2B5BAA" }}>
                    {" "}
                    terugvordering van buitenlandse btw en accijnzen{" "}
                  </span>
                  volledig uit handen nemen.
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Verder laat Delta u{" "}
                  <span style={{ color: "#2b5baa" }}>vaarwel</span> zeggen tegen
                  <span style={{ color: "#2b5baa" }}>
                    {" "}
                    uw liquiditeitskrapte
                  </span>{" "}
                  in de vorm van permante voorfinanciering of voorfinanciering
                  wanneer het u uitkomt.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  // backgroundColor: "pink",
                }}
                justifyContent="center"
                // alignItems="center"
              >
                <Image
                  sx={{ mt: 0 }}
                  style={{
                    alignSelf: "center",
                    // ObjectFit: "cover",
                    maxWidth: "650px",
                  }}
                  img
                  src={CoreActivitiesImage}
                  alt="cur"
                  // height={352}
                  // width={800}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default CoreActivities;
