import React from "react";
import NewsArticle48 from "../elements/NewsArticle48";
import NewsNav from "../elements/NewsNav";

const NewsDetails48 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle48 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails48;