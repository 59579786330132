import React from "react";
import NewsArticle17 from "../elements/NewsArticle17";
import NewsNav from "../elements/NewsNav";

const NewsDetails17 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle17 />
      <NewsNav bottom />
    </>
  );
  };

export default NewsDetails17;