import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle13 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Gaat de papieren aangifte van dieselolie accijns binnenkort vervangen worden door de digitale aangifte?
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage13.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                01.06.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            In de hedendaagse digitale wereld lijkt het gebruik van papieren 
            documenten en administratieve procedures vaak achterhaald. Desondanks 
            blijven overheden, met name bij het terugvragen van buitenlandse 
            accijns over dieselolie, vasthouden aan traditionele papieren aangiftes.
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoewel {" "}
            <span style={{ color: "#2b5baa" }}>
            papieren aangiftes momenteel de norm {" "}
              </span>
            zijn, zijn er verschillende {" "}
            <span style={{ color: "#9c29ff" }}>
            voordelen gebonden aan digitale indiening {" "}
            </span>
            van accijns teruggaaf. Dit omvat {" "}
            <span style={{ color: "#9c29ff" }}>
            snelheid, kostenbesparing, efficiëntie en vereenvoudiging van administratieve processen {" "}
            </span>
            voor internationaal opererende transportondernemingen. Het zou ook bijdragen 
            aan het verminderen van fouten en vertragingen die kunnen optreden bij 
            handmatige verwerking van papieren documenten.
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Ook al lijken alle administratieve procedures sinds de laatste 15 
            jaar een verandering van papier naar digitaal doorgemaakt te hebben, 
            blijken procedures zoals buitenlandse accijnsteruggaaf toch achter 
            te blijven. Hiervoor kunnen een aantal redenen bestaan:
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <ul>
            <li>Complexiteit van internationale accijnsregels:</li>
            <span style={{ color: "#2b5baa" }}>
            Het huidige systeem van internationale accijnsregels is complex
              </span>
            , met verschillende vereisten en procedures per land. Deze 
            complexiteit maakt het moeilijker om uniforme digitale systemen 
            te implementeren voor het terugvragen van buitenlandse accijns over 
            dieselolie. Elk land heeft zijn eigen specifieke regels en vereisten 
            en het ontwikkelen van een gestandaardiseerd digitaal systeem kan 
            een uitdaging zijn.
            </ul>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <ul>
            <li>Technologische beperkingen en standaardisatie:</li>
            De implementatie van een digitaal systeem voor het terugvragen van 
            buitenlandse accijns vereist robuuste technologische infrastructuur 
            en onderlinge compatibiliteit tussen verschillende landen.{" "}
            <span style={{ color: "#9c29ff" }}>
            Verschillende belastingautoriteiten werken met uiteenlopende IT-systemen en standaarden
            </span>
            , waardoor het creëren van een uniform digitaal platform een uitdaging kan zijn.
            </ul>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <ul>
            <li>Fraudepreventie:</li>
            <span style={{ color: "#2b5baa" }}>
            Het voorkomen van fraude {" "}  
            </span>
            is een belangrijke overweging bij het terugvragen van buitenlandse 
            accijns via papieren aangiftes. Papieren aangiftes kunnen fysieke 
            handtekeningen, originele facturen en bijlagen vereisen, wat de controle 
            op de juistheid van de gegevens vergemakkelijkt. 
            </ul>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Er zijn wereldwijd inspanningen gaande om administratieve processen 
            te digitaliseren en te vereenvoudigen. In België is er nu al de 
            mogelijkheid om voor Belgisch gevestigde transportondernemingen 
            een digitaal verzoek tot accijns teruggaaf in te dienen. De vereisten 
            voor teruggaaf van Belgische dieselolie accijns verschillen niet 
            tussen een Belgisch- of niet-Belgisch gevestigde transportonderneming. 
            In dit geval is het systeem er al en zou je je kunnen afvragen waarom 
            niet-Belgisch gevestigde transportondernemingen hier ook geen gebruik 
            van mogen maken. De werkelijke reden hiervoor maakt de Belgische douane 
            niet bekend. Kijkend naar de hierboven genoemde redenen kan het echter 
            maar om één reden gaan. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Net als de reden waarom de natte handtekening vaak nog een vereiste 
            is bij overheidsdocumenten is de papieren aangifte een vereiste voor 
            niet-Belgisch gevestigde transportondernemingen, een extra veiligheid 
            om fraude te voorkomen. Komt fraude dan niet voor bij papieren 
            aangiftes? Wel, maar het kost een hoop meer inspanning voor de fraudeur 
            om de aangiftes te vervalsen. {" "}
            <span style={{ color: "#9c29ff"}}>
            Aangezien het bij dieselolie accijnsteruggaaf om hele grote bedragen gaat, 
            durven overheden zoals België het risico helaas nog niet te nemen om over 
            te stappen op volledig digitale aangiftes. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Met het steeds uniformer worden van regels en procedures 
            tussen Europese lidstaten en de wereldwijde digitalisering 
            van vele administratieve processen is het gek om te constateren 
            dat aangiftes voor dieselolie accijnsteruggaaf achter blijven. 
            Gaat dit in de toekomst veranderen? Lastig te zeggen, maar 
            kijkende naar de feiten is teruggaaf van accijns al digitaal 
            mogelijk. Overheden blijven nog terughoudend hierin en de kans 
            dat deze mindset verandert in de komende tiental jaren lijkt nog ver.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle13;
