import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle36 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Verhoging van BTW-percentage in Luxemburg
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage36.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                07-12-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Naar aanleiding van een aankondiging op 21 september 2022 door de{" "}
            <span style={{color: "#2b5baa"}}>
            Luxemburgse overheid{" "}
            </span>
            werd op{" "} 
            <span style={{color: "#2b5baa"}}>
            26 oktober 2022 een wet aangenomen{" "}
            </span>
            die de meeste Luxemburgse{" "}
            <span style={{color: "#2b5baa"}}>
            BTW-tarieven in 2023 met 1% verlaagden{" "}
            </span>
            als maatregel om de inflatie te bestrijden. Deze wet zal {" "}
            <span style={{color: "#9c29ff"}}>
            na 31 december 2023 niet meer van kracht zijn.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het einde van deze verlaging resulteert in een {" "}
            <span style={{ color: "#9c29ff" }}>
            stijging van het BTW-percentage{" "}
            </span>
            op brandstof{" "}
            <span style={{ color: "#9c29ff" }}>
            van 1% in 2024
            </span>
            . Bovendien gaat het verlaagde tarief omhoog van 7 naar 8%, en het intermediaire tarief stijgt van 13 naar 14%. Deze verhogingen zullen leiden tot aanzienlijke{" "}
            <span style={{ color: "#2b5baa" }}>
            toenames in het staatskapitaal van Luxemburg. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor consumenten in Luxemburg is deze BTW-stijging onplezierig, omdat hun uitgaven zullen toenemen. Gelukkig zijn{" "}
            <span style={{ color: "#9c29ff" }}>
            B2B bedrijven BTW-neutraal
            </span>
            , waardoor deze stijging geen invloed heeft op de financiële situatie van internationale transporteurs. Echter heeft dit{" "}
            <span style={{ color: "#9c29ff" }}>
            wel effect op de beschikbare liquiditeit{" "}
            </span>
            van de transporteurs tot de BTW terugbetaald is.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle36;
