import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle38 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Risico op boetes door uitbreiding van de LEZ in GB 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage38.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                11-01-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de loop van vorig jaar zijn er in diverse steden in {" "}
            <span style={{color: "#9c29ff"}}>
            Groot Brittannië{" "}
            </span>
            de{" "}
            <span style={{color: "#2b5baa"}}>
            LEZ (Low Emission Zone){" "}
            </span>
            geïmplementeerd en verder uitgebreid. Voertuigen die niet voldoen aan bepaalde uitstootnorm (hierover later meer), zijn verplicht een {" "}
            <span style={{color: "#2b5baa"}}>
            dagelijks tarief{" "}
            </span>
            te betalen om deze zones te betreden. Ondanks dat veel lage-emissiezones al voor een lange tijd actief zijn, komt het nog regelmatig voor dat chauffeurs geconfronteerd worden met een {" "}
            <span style={{color: "#9c29ff"}}>
            opstapeling van boetes
            </span>
            . In Januari dit jaar nog heeft er een Nederlandse chauffeur 364 boetes op de deurmat gekregen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            LEZ en CAZ
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Een lage-emissiezone, vaak LEZ of soms{" "}
            <span style={{ color: "#2b5baa" }}>
            CAZ (Clean Air Zone){" "}
            </span>
            genoemd, is een gebied in de binnenstad waar {" "}
            <span style={{ color: "#2b5baa" }}>
            bestuurders van meer vervuilende voertuigen moeten betalen {" "}
            </span>
            om er doorheen te rijden. Het uiteindelijke doel is om automobilisten te stimuleren nieuwere, minder vervuilende voertuigen, te laten gebruiken. De lage-emissiezones bevinden zich vooral in de {" "}
            <span style={{ color: "#9c29ff" }}>
            grotere steden{" "}
            </span>
            in het Verenigd Koninkrijk.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor de Britse LEZ gelden voor vrachtwagens{" "}
            <span style={{ color: "#2b5baa" }}>
            boven de 3,5 ton{" "}
            </span>
            de volgende{" "}
            <span style={{ color: "#2b5baa" }}>
            dagelijkse tarieven:
            </span>
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Euro 6: 
                        </li>
                    </td>
                    <td>
                        £ 0,-
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        Euro 4: 
                        </li>
                    </td>
                    <td>
                        £ 100,-
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        Lager dan Euro 4: 
                        </li>
                    </td>
                    <td>
                        £ 300,-
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoe te herkennen
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            LEZ's en CAZ's zijn over het algemeen goed aangegeven, zowel vóór aanvang ervan (een bord kan bijvoorbeeld 
            'Ultra Low Emission Zone, 3 mijl verderop' luiden) als wanneer u ze binnengaat. Het is,{" "}
            <span style={{ color: "#9c29ff" }}>
            terecht of ten onrechte
            </span>
            , de{" "}
            <span style={{ color: "#2b5baa" }}>
            verantwoordelijkheid van bestuurders{" "}
            </span>
            om te weten waar deze zones zich bevinden, of hun auto aan de regels voldoet en of ze deze zones zijn binnengegaan. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoe voor te bereiden
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Elke LEZ heeft zijn eigen betalingssysteem, maar het is{" "}
            <span style={{ color: "#9c29ff" }}>
            vrij eenvoudig{" "}
            </span>
            om te betalen om de zones binnen te komen. Er is de optie om vooraf{" "}
            <span style={{ color: "#9c29ff" }}>
            online{" "}
            </span>
            te betalen via de{" "}
            <span style={{ color: "#9c29ff" }}>
            website TFL{" "}
            </span>
            van de Britse overheid. U heeft ook de optie om de LEZ{" "}
            <span style={{ color: "#9c29ff" }}>
            automatisch te laten betalen{" "}
            </span>
            op basis van uw kenteken. Als u zich inschrijft voor Auto Pay en een geregistreerd voertuig bestuurt dat niet 
            voldoet aan de LEZ-emissienormen binnen de zone, worden er automatisch kosten in rekening gebracht. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             Als u een voertuig bestuurt dat buiten Groot-Brittannië is geregistreerd, moet het voertuig{" "}
             <span style={{ color: "#2b5baa" }}>
              op voorhand bij TFL worden geregistreerd{" "}
             </span>
             zodat het voertuig aan de database toegevoegd kan worden. 
             </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voordat u een rit naar Engeland maakt, is het verstandig om u{" "}
            <span style={{ color: "#9c29ff" }}>
            in te lezen{" "}
            </span>
            over alle regels over de LEZ op de TFL website en uw{" "}
            <span style={{ color: "#9c29ff" }}>
            voertuig correct te registreren
            </span>
            .  Gezien de regelmaat van boetes voor wegvervoerders, is het geen zaak om laks over te zijn. Heeft u problemen of aanvaringen 
            met de regelgevingen rondom LEZ, overweeg dan om contact op te nemen met{" "}
            <span style={{ color: "#9c29ff" }}>
            Transport In Nood.
            </span>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle38;
