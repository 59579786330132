import { Box, Typography, Grid, Divider } from "@mui/material";
import React from "react";
import SnelGrijs from "../../assets/SnelGrijs.png";
import SnelBlauw from "../../assets/SnelBlauw.png";
import SnelZwart from "../../assets/SnelZwart.png";
import TraagBlauw from "../../assets/TraagBlauw.png";
import TraagZwart from "../../assets/TraagZwart.png";
import HoogGrijs from "../../assets/HoogGrijs.png";
import HoogZwart from "../../assets/HoogZwart.png";
import HoogBlauw from "../../assets/HoogBlauw.png";
import LaagBlauw from "../../assets/LaagBlauw.png";
import TraagGrijs from "../../assets/TraagGrijs.png";
import LaagGrijs from "../../assets/LaagGrijs.png";
import LaagZwart from "../../assets/LaagZwart.png";
import SliderTerugvorderingPetrol from "../../assets/SliderTerugvorderingPetrol.png";
import SliderTerugvorderingHybrid from "../../assets/SliderTerugvorderingHybrid.png";
import SliderTerugvorderingElectric from "../../assets/SliderTerugvorderingElectric.png";
import SliderTerugvorderingDiesel from "../../assets/SliderTerugvorderingDiesel.png";
import { Container } from "@mui/system";
import Image from "mui-image";
import { Link } from "react-router-dom";
import CalculateButton from "./CalculateButton";
// import { Link } from "react-router-dom";

const HomeChooseService = () => {
  // const handleOnClick = (linkId) => {
  //   alert(linkId);
  // };

  return (
    <>
      <Container maxWidth="100%" sx={{ m: 0, p: 0 }}>
        <Container maxWidth="xl">
          <Typography
            sx={{
              mt: 5,
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: { xs: "40px", md: "55px", xl: "71px" },
              lineHeight: { xs: "55px", md: "70px", xl: "85px" },
              letterSpacing: "0px",
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
            }}
          >
            Kies uw vorm
          </Typography>
          <Typography
            mb={5}
            sx={{
              mt: 0,
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: { xs: "16px", md: "21px", xl: "21px" },
              lineHeight: { xs: "20px", md: "25.2px", xl: "25.2px" },
              letterSpacing: "0px",
              fontFamily: "Century Gothic",
              fontWeight: "Regular",
            }}
          >
            Delta's verschillende diensten in één oogopslag.
          </Typography>
          <Grid sx={{ display: "flex" }} container direction="row">
            <Grid
              container
              xl={3}
              md={6}
              xs={12}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  height: "30px",
                  boxShadow: 0,
                  mb: 0,
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container lg={12} md={12} xs={12} direction="row">
                  <Grid lg={2} xs={2}></Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={6} xs={6}>
                    <Typography
                      sx={{
                        color: "#9c29ff",
                        textAlign: "center",
                        fontSize: "29px",
                        fontFamily: "Century Gothic",
                        fontWeight: "900",
                      }}
                    >
                      {" "}
                    </Typography>
                  </Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={2} xs={2}></Grid>
                </Grid>
              </Box>
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  ml: 1,
                  mr: 1,
                  height: "64px",
                  position: "relative",
                  background: "linear-gradient(to right, #2b5baa, #3c7cc6)",
                  borderRadius: "15px 15px 0px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontSize: "29px",
                    fontFamily: "Century Gothic",
                    fontWeight: "900",
                  }}
                >
                  Delta Petrol
                </Typography>
              </Box>
              <Box
                container
                item
                sx={{
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "450px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "21px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Light",
                      height: "25px",
                      letterSpacing: "-0.27px",
                    }}
                  >
                    Normal refund
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2b5baa",
                      fontSize: "23px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Thin",
                      letterSpacing: "-0.3px",
                    }}
                  >
                    <ul>
                      <li style={{ marginBottom: "14px" }}>BTW product</li>
                      <li style={{ marginBottom: "14px" }}>
                        Zonder voorfinanciering
                      </li>
                    </ul>
                  </Typography>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Terugvordering:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={TraagBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingPetrol}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SnelGrijs}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Traag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#dbdbdb",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Snel
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Tarief:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={LaagBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingPetrol}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={HoogGrijs}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Laag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#dbdbdb",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Hoger
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      color: "#2B5BAA",
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                      textDecoration: "underline",
                    }}
                    // onClick={() => handleOnClick("DP")}
                  >
                    <Link to="/DeltaPetrol">Meer informatie</Link>
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              xl={3}
              md={6}
              xs={12}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  height: "30px",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container lg={12} md={12} xs={12} direction="row">
                  <Grid lg={2} xs={2}></Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={6} xs={6}>
                    <Typography
                      sx={{
                        color: "#9c29ff",
                        textAlign: "center",
                        fontSize: "29px",
                        fontFamily: "Century Gothic",
                        fontWeight: "900",
                      }}
                    >
                      Nieuw!
                    </Typography>
                  </Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={2} xs={2}></Grid>
                </Grid>
              </Box>
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  ml: 1,
                  mr: 1,
                  height: "64px",
                  position: "relative",
                  background: "linear-gradient(to right, #9d2bfe, #c75cef)",
                  borderRadius: "15px 15px 0px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontSize: "29px",
                    fontFamily: "Century Gothic",
                    fontWeight: "900",
                  }}
                >
                  Delta Hybrid
                </Typography>
              </Box>
              <Box
                container
                item
                sx={{
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "450px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "21px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Light",
                      height: "25px",
                      letterSpacing: "-0.27px",
                    }}
                  >
                    Flexible refund
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2b5baa",
                      fontSize: "23px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Thin",
                      letterSpacing: "-0.3px",
                    }}
                  >
                    <ul>
                      <li style={{ marginBottom: "14px" }}>BTW product</li>
                      <li style={{ marginBottom: "14px" }}>
                        Af en toe voorfinanciering
                      </li>
                    </ul>
                  </Typography>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Terugvordering:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={TraagBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingHybrid}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SnelBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Traag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Snel
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Tarief:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={LaagBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingHybrid}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={HoogBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Laag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Hoger
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      color: "#2B5BAA",
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                      textDecoration: "underline",
                    }}
                    // onClick={() => handleOnClick("DP")}
                  >
                    <Link to="/DeltaHybrid">Meer informatie</Link>
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              xl={3}
              md={6}
              xs={12}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  height: "30px",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container lg={12} md={12} xs={12} direction="row">
                  <Grid lg={2} xs={2}></Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={6} xs={6}>
                    <Typography
                      sx={{
                        color: "#9c29ff",
                        textAlign: "center",
                        fontSize: "29px",
                        fontFamily: "Century Gothic",
                        fontWeight: "900",
                      }}
                    ></Typography>
                  </Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={2} xs={2}></Grid>
                </Grid>
              </Box>
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  ml: 1,
                  mr: 1,
                  height: "64px",
                  position: "relative",
                  background: "linear-gradient(to right, #3c7bc6, #48A1D3)",
                  borderRadius: "15px 15px 0px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontSize: "29px",
                    fontFamily: "Century Gothic",
                    fontWeight: "900",
                  }}
                >
                  Delta Electric
                </Typography>
              </Box>
              <Box
                container
                item
                sx={{
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "450px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "21px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Light",
                      height: "25px",
                      letterSpacing: "-0.27px",
                    }}
                  >
                    Fast refund
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2b5baa",
                      fontSize: "23px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Thin",
                      letterSpacing: "-0.3px",
                    }}
                  >
                    <ul>
                      <li style={{ marginBottom: "14px" }}>BTW product</li>
                      <li style={{ marginBottom: "14px" }}>
                        Permanent voorfinanciering
                      </li>
                    </ul>
                  </Typography>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Terugvordering:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={TraagGrijs}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingElectric}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SnelBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#dbdbdb",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Traag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Snel
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Tarief:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={LaagGrijs}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingElectric}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={HoogBlauw}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#dbdbdb",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Laag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#2B5BAA",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Hoger
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      color: "#2B5BAA",
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                      textDecoration: "underline",
                    }}
                    // onClick={() => handleOnClick("DP")}
                  >
                    <Link to="/DeltaElectric">Meer informatie</Link>
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              xl={3}
              md={6}
              xs={12}
              direction="column"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  height: "30px",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container lg={12} md={12} xs={12} direction="row">
                  <Grid lg={2} xs={2}></Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={6} xs={6}>
                    <Typography
                      sx={{
                        color: "#9c29ff",
                        textAlign: "center",
                        fontSize: "29px",
                        fontFamily: "Century Gothic",
                        fontWeight: "900",
                      }}
                    ></Typography>
                  </Grid>
                  <Grid lg={1} xs={1}></Grid>
                  <Grid lg={2} xs={2}></Grid>
                </Grid>
              </Box>
              <Box
                container
                item
                sx={{
                  display: "flex",
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 0,
                  mb: 0,
                  ml: 1,
                  mr: 1,
                  height: "64px",
                  position: "relative",
                  background: "#1c1c1c",
                  borderRadius: "15px 15px 0px 0px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    textAlign: "center",
                    fontSize: "29px",
                    fontFamily: "Century Gothic",
                    fontWeight: "900",
                  }}
                >
                  Delta Diesel
                </Typography>
              </Box>
              <Box
                container
                item
                sx={{
                  maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "450px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 1,
                      color: "#1c1c1c",
                      textAlign: "center",
                      fontSize: "21px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Light",
                      height: "25px",
                      letterSpacing: "-0.27px",
                    }}
                  >
                    Excise duties
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#1c1c1c",
                      fontSize: "23px",
                      lineHeight: "25.2px",
                      fontFamily: "HelveticaNeue-Thin",
                      letterSpacing: "-0.3px",
                    }}
                  >
                    <ul>
                      <li style={{ marginBottom: "14px" }}>Accijns product</li>
                      <li style={{ marginBottom: "14px" }}>
                        Af en toe voorfinanciering
                      </li>
                    </ul>
                  </Typography>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Terugvordering:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={TraagZwart}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingDiesel}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SnelZwart}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Traag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Snel
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: { xs: "300px", md: "300px", xl: "320px" },
                      width: "100%",
                      m: 0,
                      position: "relative",
                    }}
                  >
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={6} xs={6}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "center",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Tarief:
                        </Typography>
                      </Grid>
                      <Grid lg={1} xs={1}></Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={LaagZwart}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={6} xs={6}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            alignSelf: "center",
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={SliderTerugvorderingDiesel}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={1} xs={1}>
                        <Image
                          sx={{ mt: 0 }}
                          style={{
                            ObjectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                          img
                          src={HoogZwart}
                          alt="cur"
                          // height={352}
                          // width={500}
                        />
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} direction="row">
                      <Grid lg={2} xs={2}></Grid>
                      <Grid lg={3} xs={3}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Laag
                        </Typography>
                      </Grid>
                      <Grid lg={4} xs={4}></Grid>
                      <Grid lg={1} xs={1}>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            justifyContent: "left",
                            mt: 1,
                            color: "#1c1c1c",
                            textAlign: "left",
                            fontSize: "19px",
                            lineHeight: "25.2px",
                            fontFamily: "HelveticaNeue-Thin",
                            height: "25px",
                            letterSpacing: "-0.27px",
                          }}
                        >
                          Hoog
                        </Typography>
                      </Grid>
                      <Grid lg={2} xs={2}></Grid>
                    </Grid>
                  </Box>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      position: "absolute",
                      bottom: 10,
                      left: 10,
                      color: "#2B5BAA",
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                      textDecoration: "underline",
                    }}
                    // onClick={() => handleOnClick("DP")}
                  >
                    <Link to="/DeltaDiesel">Meer informatie</Link>
                  </Typography>
                </div>
              </Box>
            </Grid>
            <CalculateButton maxWidth="100%" />
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default HomeChooseService;
