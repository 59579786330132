import React from "react";
import NewsArticle29 from "../elements/NewsArticle29";
import NewsNav from "../elements/NewsNav";

const NewsDetails29 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle29 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails29;