import { Box, Typography, Grid, Divider } from "@mui/material";
import React from "react";
import FlexibelVoorfinancierenSmall from "../../assets/FlexibelVoorfinancierenSmall.png";
import AutomatiseringSmall from "../../assets/AutomatiseringSmall.png";
import StabieleCashflowSmall from "../../assets/StabieleCashflowSmall.png";
import BoekhoudingVersimpeldSmall from "../../assets/BoekhoudingVersimpeldSmall.png";
import { Container } from "@mui/system";
import Image from "mui-image";
// import { Link } from "react-router-dom";

const UniqueInOurBranche = () => {
  return (
    <>
      <Container maxWidth="100%" sx={{ m: 0, p: 0 }}>
        <Container maxWidth="xl">
          <Typography
            sx={{
              p: 0,
              m: 0,
              mt: { xs: 5, md: 8 },
              mb: { xs: 5, md: 8 },
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: { xs: "40px", md: "71px" },
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
            }}
          >
            Uniek in onze branche
          </Typography>
          {/* <Typography
            mb={5}
            mt={0}
            pt={0}
            sx={{
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "1rem",
              "@media (min-width:600px)": {
                fontSize: "1.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Regular",
            }}
          >
            Diensten die enkel Delta u kan bieden.
          </Typography> */}
          <Grid container lg={12} md={12} xs={12} direction="row">
            <Grid
              container
              lg={3}
              md={6}
              xs={12}
              direction="row"
              justifyContent="center"
            >
              <Box
                container
                item
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "620px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "1.8rem",
                      lineHeight: "2rem",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      height: "65px",
                    }}
                  >
                    Flexibel voorfinancieren
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: "340px",
                      width: "100%",
                      m: 0,
                      height: "240px",
                      position: "relative",
                    }}
                  >
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        alignSelf: "center",
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                      img
                      src={FlexibelVoorfinancierenSmall}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />
                  </Box>

                  <Typography
                    sx={{
                      m: 0,
                      mt: 1,
                      ml: 1,
                      fontSize: "1.2rem",
                      lineHeight: "1.4rem",
                      color: "#6b6a6a",
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    <span style={{ color: "#2b5baa" }}>Delta Hybrid </span>biedt
                    u de mogelijkheid om zelf te bepalen wanneer u wel- of niet
                    voorfinanciering wilt. In de basis heeft u geen
                    voorfinanciering, op aanvraag kunt u echter binnen 1 werkdag
                    uw financiering krijgen, voor de hoogte van het bedrag dat u
                    wenst.
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              lg={3}
              md={6}
              xs={12}
              direction="row"
              justifyContent="center"
            >
              <Box
                container
                item
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "620px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "1.8rem",
                      lineHeight: "2rem",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      height: "65px",
                    }}
                  >
                    Automatisering
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: "340px",
                      width: "100%",
                      m: 0,
                      height: "240px",
                      position: "relative",
                    }}
                  >
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        alignSelf: "center",
                        ObjectFit: "cover",
                        height: "auto",
                        width: "auto",
                      }}
                      img
                      src={AutomatiseringSmall}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />
                  </Box>

                  <Typography
                    sx={{
                      m: 0,
                      mt: 1,
                      ml: 1,
                      fontSize: "1.2rem",
                      lineHeight: "1.4rem",
                      color: "#6b6a6a",
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    Delta downloadt automatisch uw facturen via de portalen van
                    de oliemaatschappij. Na het verstrekken van uw inloggegevens
                    heeft u geen omkijken meer naar uw BTW en accijns teruggaaf.
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              lg={3}
              md={6}
              xs={12}
              direction="row"
              justifyContent="center"
            >
              <Box
                container
                item
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "620px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "1.8rem",
                      lineHeight: "2rem",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      height: "65px",
                    }}
                  >
                    Stabiele cashflow
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: "340px",
                      width: "100%",
                      m: 0,
                      height: "240px",
                      position: "relative",
                    }}
                  >
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        alignSelf: "center",
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                        maxWidth: "230px",
                      }}
                      img
                      src={StabieleCashflowSmall}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />
                  </Box>
                  <Typography
                    sx={{
                      m: 0,
                      mt: 1,
                      ml: 1,
                      fontSize: "1.2rem",
                      lineHeight: "1.4rem",
                      color: "#6b6a6a",
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    U betaalt in de basis een maandelijks abonnementstarief, wat
                    fluctuaties in kosten beëindigd. Zo weet u elke maand waar u
                    aan toe bent.
                  </Typography>
                </div>
              </Box>
            </Grid>
            <Grid
              container
              lg={3}
              md={6}
              xs={12}
              direction="row"
              justifyContent="center"
            >
              <Box
                container
                item
                sx={{
                  maxWidth: "340px",
                  width: "100%",
                  // border: "1px solid grey",
                  padding: "10px",
                  boxShadow: 4,
                  mt: 0,
                  mb: 2,
                  ml: 1,
                  mr: 1,
                  height: "620px",
                  position: "relative",
                }}
              >
                <div>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mt: 3,
                      color: "#2B5BAA",
                      textAlign: "center",
                      fontSize: "1.8rem",
                      lineHeight: "2rem",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      height: "65px",
                    }}
                  >
                    Heldere Boekhouding
                  </Typography>
                  <Divider sx={{ m: 2, mb: 5 }} />
                  <Box
                    container
                    item
                    sx={{
                      maxWidth: "340px",
                      width: "100%",
                      m: 0,
                      height: "240px",
                      position: "relative",
                    }}
                  >
                    <Image
                      sx={{ mt: 0 }}
                      style={{
                        alignSelf: "center",
                        ObjectFit: "cover",
                        width: "auto",
                        height: "auto",
                        maxWidth: "230px",
                      }}
                      img
                      src={BoekhoudingVersimpeldSmall}
                      alt="cur"
                      // height={352}
                      // width={500}
                    />
                  </Box>
                  <Typography
                    sx={{
                      m: 0,
                      mt: 1,
                      ml: 1,
                      fontSize: "1.2rem",
                      lineHeight: "1.4rem",
                      color: "#6b6a6a",
                      fontFamily: "HelveticaNeue-Thin",
                    }}
                  >
                    Delta houdt vanwege de abonnementsstructuur geen commissie
                    in over de uitbetalingen van uw teruggaafverzoeken. U krijgt
                    het volledige BTW & accijns bedrag terug op uw rekening, wat
                    het afletteren in uw boekhoudings-systeem drastisch
                    versimpelt.
                  </Typography>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default UniqueInOurBranche;
