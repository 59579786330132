import React from "react";
import NewsArticle40 from "../elements/NewsArticle40";
import NewsNav from "../elements/NewsNav";

const NewsDetails40 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle40 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails40;