import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle19 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Eerdere terugsluizing van 170 miljoen voor invoering ‘trucktol’ 2027   
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage19.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                20-07-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb:5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Het kabinet is bezig met plannen om de terugsluis vanuit de trucktol (wet vrachtwagenheffing) naar voren te halen.{" "}
            <span style={{ color: "#2b5baa" }}>
            In plaats van {" "}
              </span>
            het oorspronkelijke jaar 
            {" "}
            <span style={{ color: "#2b5baa" }}>
            2027{" "}
              </span>
            zouden er al{" "}
            <span style={{ color: "#2b5baa" }}>
            volgend jaar{" "}
              </span>
            aanzienlijke bedragen beschikbaar komen voor de {" "}
            <span style={{ color: "#9c29ff" }}>
            bevordering van duurzaamheid{" "}
              </span>
            in het wegvervoer.
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De ‘trucktol’ verwijst naar een vorm van{" "}
            <span style={{ color: "#2b5baa" }}>
            tolheffing{" "}
              </span>
            die in 2027 naar verwachting ingevoerd gaat worden en die specifiek gericht is op vrachtwagens en 
            andere zware voertuigen. Deze tol wordt geheven{" "}
              <span style={{ color: "#2b5baa" }}>
            op basis van het aantal kilometers{" "}
              </span>
            dat een vrachtwagen op de nationale Nederlandse wegen aflegt. Het doel van de trucktol is het{" "}
            <span style={{ color: "#9c29ff" }}>
            genereren van inkomsten{" "}
              </span>
            voor de overheid en het{" "}
            <span style={{ color: "#9c29ff" }}>
            stimuleren van duurzaamheid{" "}
              </span>
            in het wegvervoer. Het stimuleren van het verduurzamen zal op twee manieren gebeuren:  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <table cellpadding="0" cellspacing="0">
                <tbody>
                    <ol>
                 <tr>
                    <td>
                            <li>
                                Ten eerste zal een groot deel van de opbrengsten teruggesluisd worden naar de transportsector in 
                                de vorm subsidies voor het verduurzamen van het wegververvoer. Volgens de plannen van bewindsman 
                                Harbers zal er een {" "}
                                    <span style={{ color: "#2b5baa" }}>
                                        voorfinanciering van 70 miljoen euro {" "}
                                    </span>
                                plaatsvinden in{" "}
                                    <span style={{ color: "#2b5baa" }}>
                                        2024
                                    </span>
                                , gevolgd door{" "}
                                    <span style={{ color: "#9c29ff" }}>
                                        100 miljoen euro in 2025
                                    </span>
                                . Dit geld zal onder andere worden gebruikt om de{" "}
                                    <span style={{ color: "#2b5baa" }}>
                                        AanZET-regeling {" "}
                                    </span>
                                na dit jaar voort te zetten en een regeling op te zetten voor de aanschaf van 
                                laadinfrastructuur voor elektrische vrachtwagens op bedrijventerreinen en distributiecentra.  
                            </li>
                    </td>
                 </tr>
                 <br></br>
                 <tr>
                    <td>   
                            <li>
                                Ten tweede zullen{" "}
                                    <span style={{ color: "#9c29ff" }}>
                                        elektrische trucks maar liefst 80% minder aan trucktol per kilometer betalen
                                    </span>
                                . De hoogte van de trucktol heffing staat in verband met de euro-emissieklasse en het 
                                toegestaan maximaal gewicht van de vrachtwagen. Voor{" "}
                                    <span style={{ color: "#2b5baa" }}>
                                        Euro-6-vrachtwagens {" "}
                                    </span>
                                zou het tarief volgens de minister gemiddeld op{" "}
                                    <span style={{ color: "#2b5baa" }}>
                                        17,1 cent per kilometer{" "}
                                    </span>
                                moeten liggen (prijspeil 2023). {" "}
                                    <span style={{ color: "#9c29ff" }}>
                                        Zero-emissievrachtwagens{" "}
                                    </span>
                                zouden in de plannen slechts gemiddeld{" "}
                                    <span style={{ color: "#9c29ff" }}>
                                        3,3 cent per kilometer {" "}
                                    </span>
                                betalen. De tarieven zullen echter bij de invoering van de vrachtwagenheffing nog worden bijgesteld naar 
                                het prijsniveau van dat moment. 
                            </li>
                    </td>
                 </tr>
                    </ol>
                </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Betekent dit dan dat u als wegvervoerder bovenop al uw huidige heffingskosten ook nog eens de trucktol 
            kosten binnenkort zal betalen? Nee, bij invoering van de trucktol zal de{" "}
            <span style={{ color: "#2b5baa" }}>
            motorrijtuigenbelasting voor vrachtwagens verlaagd worden tot het Europees minimum{" "}
              </span>
            en wordt de heffing van het{" "}
            <span style={{ color: "#9c29ff" }}>
            Eurovignet in Nederland stopgezet.
              </span>
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Is Nederland dan de enige die vrachtwagenheffing zal gaan heffen? Nee, vele Europese landen 
            gingen ons al voor waaronder onze buurlanden België met de “kilometerheffing voor vrachtwagens” 
            en Duitsland met de ‘LKW-Maut’. Nederland wilde al langer naar een soortgelijk systeem over, ook 
            om buitenlandse vervoerders in Nederland te kunnen heffen met belasting.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoe gaat u straks de trucktol betalen? Net als onze buurlanden zal de Nederlandse trucktol berekend worden via een{" "}
            <span style={{ color: "#2b5baa" }}>
            ‘On Board Unit’ (OBU)
              </span>
            , waarna u een factuur krijgt over de aantal gereden kilometers. Om te voorkomen dat u straks nog een extra OBU-systeem 
            moet installeren is de Nederlandse overheid er hard mee bezig om het{" "}
            <span style={{ color: "#9c29ff" }}>
            Nederlandse trucktol systeem aan te sluiten op de wel bekende huidige OBU-systemen{" "}
              </span>
            zoals Satellic en Axxès.
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Oorspronkelijk was de invoering van de kilometerheffing gepland voor 2023, maar dit is inmiddels {" "}
            <span style={{ color: "#2b5baa" }}>
            uitgesteld naar 2027
              </span>
            . Dit omdat er{" "}
            <span style={{ color: "#9c29ff" }}>
            veel kritiek{" "}
              </span> 
            was{" "} 
            <span style={{ color: "#9c29ff" }}>
            op de wet
              </span> 
            , onder andere{" "}
            <span style={{ color: "#9c29ff" }}>
            op gebied van de verdeling van de inkomsten
              </span>
            . Zo maakte TLN zich hard voor de transportsector en wilde voorkomen dat de trucktol voornamelijk een manier zou zijn 
            voor de overheid om de staatskas te spekken. De terugsluis is overeengekomen in een bestuursovereenkomst die in 2021 
            werd ondertekend door de toenmalige minister Barbara Visser van IenW en branchevertegenwoordigers TLN, Evofenedex en 
            de Vern. In deze overeenkomst hebben de partijen afspraken gemaakt over de manier waarop de{" "}
            <span style={{ color: "#2b5baa" }}>
            netto-opbrengsten van de vrachtwagenheffing terugvloeien naar de sector om verduurzaming te bevorderen. 
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Al met al zou naar verwachting de invoering van trucktol moeten leiden tot{" "}
            <span style={{ color: "#9c29ff" }}>
            17.400 extra vrachtwagens zonder emissies in 2030
              </span>
            , bovenop de ‘autonome groei’ van 8.400 elektrische vrachtwagens. Dit zou het{" "}
            <span style={{ color: "#2b5baa" }}>
            totaal in 2030{" "}
              </span> 
            op bijna{" "}
            <span style={{ color: "#2b5baa" }}>
            25.000 elektrische vrachtwagens{" "}
              </span> 
            brengen, ruimschoots meer dan de 16.000 die het kabinet als doel heeft gesteld. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het nieuwe vrachtwagenheffing systeem genaamd{" "}
            <span style={{ color: "#9c29ff" }}>
            ‘trucktol’ zal naar verwachting in 2027 ingevoerd worden
              </span>
            . Het systeem is afgeleid van onze buurlanden en zal met gebruik van een{" "}
            <span style={{ color: "#9c29ff" }}>
            On Board Unit{" "}
              </span> 
            berekenen wat uw kosten zijn gebaseerd op uw type vrachtwagen en het aantal gereden kilometers. Het systeem zorgt ervoor dat iedereen{" "}
            <span style={{ color: "#2b5baa" }}>
            een eerlijke prijs betaalt voor zijn/haar gebruik van de Nederlandse nationale wegen
              </span>
            . De netto-opbrengsten van het trucktol systeem zullen{" "}
            <span style={{ color: "#9c29ff" }}>
            grotendeels teruggesluisd{" "}
              </span>
            moeten worden aan de wegvervoer sector{" "}
            <span style={{ color: "#9c29ff" }}>
            ter bevordering van verduurzaming
              </span>
            . Om echter de verduurzaming van de sector een extra duwtje te geven zal na akkoord{" "}
            <span style={{ color: "#2b5baa" }}>
            de komende twee jaar al 170 miljoen euro voorgefinancierd worden vanuit de overheid.
              </span>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle19;
