import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle16 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Reactietermijnen van informatieverzoeken bij BTW-teruggaaf  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage16.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                29-06-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Het proces van het terugvorderen van buitenlandse BTW kan voor bedrijven die 
            actief zijn in meerdere Europese landen een complexe aangelegenheid zijn. Na 
            het indienen van de BTW teruggaaf verzoeken kunnen bedrijven te maken krijgen met {" "}
            <span style={{ color: "#2b5baa" }}>
            informatieverzoeken{" "}
              </span>
            van buitenlandse belastingdiensten. Het tijdig en correct reageren op deze verzoeken is van
            {" "}
            <span style={{ color: "#2b5baa" }}>
            cruciaal belang
            {" "}
              </span>
            om een{" "}
            <span style={{ color: "#2b5baa" }}>
            soepele afhandeling van de teruggave te waarborgen
            </span>
            . Het niet tijdig reageren kan leiden{" "}
            <span style={{ color: "#9c29ff"}}>
            vertragingen in het proces {" "}
            </span>
            of erger, tot volledige{" "}
            <span style={{ color: "#9c29ff"}}>
            afwijzing van het teruggaveverzoek
            </span>
            . We hebben een overzicht samengesteld van EU-landen en de termijnen die zij hanteren voor het reageren op informatie verzoeken: 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                          
                            <li> Spanje</li>
                          
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 10 werkdagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Polen</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 14 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Oostenrijk</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 14 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Bulgarij</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 14 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Portugal</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 15 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Tsjechië</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 15 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Kroatië</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 15 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Letland</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 15 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Litouwen</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 20 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Ierland</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 21 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Duitsland</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 28 dagen
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li> Italië</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            max 60 dagen
                        </span>
                    </td>
                 </tr>
                 </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In {" "}
            <span style={{ color: "#2b5baa" }}>
            Nederland, Frankrijk, Verenigd Koninkrijk, België, Zweden, Finland, Denemarken, Luxemburg, Slowakije, Estland, Slovenië, Hongarije, Roemenië en Griekenland{" "}
              </span>
            wordt over het algemeen een termijn van {" "}
            <span style={{ color: "#9c29ff" }}>
            30 dagen{" "}
              </span>
            gegeven om te reageren op informatie verzoeken van de belastingdienst. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In {" "}
            <span style={{ color: "#2b5baa" }}>
            Zwitserland en Noorwegen{" "}
              </span>
            kunnen de termijnen variëren tussen de {" "}
            <span style={{ color: "#9c29ff" }}>
            10 en 30 dagen
              </span>
            , afhankelijk van de complexiteit van het verzoek. In Zwitserland is dit afhankelijk van 
            de specifieke kantons en belastingautoriteiten waarin je dit indient. In Noorwegen is 
            dit afhankelijk van de specifieke situatie. 
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Naast de maximale reageertermijn is het ook van belang te weten via welke communicatiemiddelen 
            de buitenlandse belastingdiensten communiceren. De buitenlandse belastingautoriteiten doen dit op drie manieren:
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <table cellpadding="0" cellspacing="0">
                <tbody>
                    <ol>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                          
                            <li> via een schriftelijke brief</li>
                          
                        </span> 
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                          
                            <li> via de mail</li>
                          
                        </span> 
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                          
                            <li> via de telefoon</li>
                          
                        </span> 
                    </td>
                 </tr>
                    </ol>
                </tbody>
            </table>
             </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Verdere specificaties zoals welk land op welke manier communiceert, 
            welke communicatiemiddel je als bedrijf moet gebruiken om te reageren, 
            wat voor informatie er gevraagd kan worden en in welke taal dit allemaal 
            gedaan moet worden, leest u in de volgende artikelen.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Kortom, bedrijven die buitenlandse BTW teruggaaf verzoeken doen moeten rekening 
            houden met de specifieke termijnen waarop ze moeten reageren. De termijnen verschillen 
            tussen de {" "}
            <span style={{ color:"#9c29ff"}}>
                10 werkdagen en 60 dagen
            </span>
            , afhankelijk van het land waar je een teruggaafverzoek doet. Reageer je niet op tijd, onjuist, 
            onvolledig, via het verkeerde communicatiemiddel of zelfs in de verkeerde taal, dan loop je het 
            risico op vertraging- of volledige afwijzingen van teruggaafverzoek. Om dit allemaal te voorkomen 
            is het aan te raden om {" "}
            <span style={{ color:"#2b5baa"}}>
                proffesioneel advies {" "}
            </span>
            in te winnen bij een belastingadviseur, accountant of BTW- & accijns teruggaaf intermediair.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Delta houdt de termijnen scherp in de gaten en streeft naar een vlotte, 
            snelle en correcte communicatie met de klanten, zodat informatie snel 
            kan worden doorgestuurd naar de belastingautoriteiten. Daarmee zijn mogelijke 
            afwijzingen geen optie meer. Wij hopen dat u het ons niet kwalijk neemt als we 
            soms wat aandringen op het verkrijgen van de informatie, het is in ons aller belang! 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle16;
