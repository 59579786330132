import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle52 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Franse dieselolieaccijns teruggaaf sneller dan ooit 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage52.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                20-06-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Bent u een internationale transporteur? Dan let u waarschijnlijk goed op de teruggaaf van Belgische dieselolieaccijns. Maar houdt u ook de{" "}
            <span style={{color: "#2b5baa"}}>
            Franse dieselolieaccijns{" "}
            </span>
            in de gaten? 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In Frankrijk kunt u momenteel een accijnscompensatie van{" "}
             <span style={{color: "#9c29ff"}}>
            15,71 cent per liter{" "}
            </span>
            aanvragen voor diesel die getankt is door N3-vrachtwagens (maximaal toegelaten massa van meer dan 7.500 kg). {" "}
            <span style={{color: "#2b5baa"}}>
            Sinds 2020 kan dit volledig digitaal
            </span>
            , zonder gedoe met papieren post. Het digitale systeem zorgt bovendien voor een snellere uitbetaling. Uit onze ervaring blijkt dat de{" "}
            <span style={{color: "#2b5baa"}}>
            uitbetaling gemiddeld binnen twee weken{" "}
            </span>
            plaatsvindt, met uitschieters tot vier weken, mits er geen vragen zijn over de aangifte. En als wij dit dan ook nog eens{" "}
            <span style={{color: "#2b5baa"}}>
            binnen een maand na einde kwartaal voor u indienen{" "}
            </span>
            kan u weer snel over deze gelden beschikken.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Misschien denkt u dat u niet vaak genoeg in Frankrijk tankt om het de moeite waard te maken. Maar gedurende periode{" "}
            <span style={{color: "#9c29ff"}}>
            Q2 2022 t/m Q4 2022 was diesel in Frankrijk gemiddeld goedkoper dan in België
            </span>
            , met de mogelijkheid om meer accijns per liter terug te vorderen (15,70 cent). Als uw transport richting Zuid-Europa ging, dan is de kans groot dat u gedurende deze periode in Frankrijk heeft getankt. 
            De mogelijkheid voor vergoeding voor deze periode is nog niet verloren, aangezien we{" "}
            <span style={{color: "#2b5baa"}}>
            tot 3 jaar terug kunnen om het in te dienen
            </span>
            . Zelfs voor relatief kleine wagenparken kan de teruggave oplopen tot enkele duizenden euro's per jaar. Laten liggen zou zonde zijn, toch? 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Heeft u interesse in onze diensten? Wij <a href="https://deltarefundsolutions.com/Contact"> bespreken</a> graag de mogelijkheden met u. 

            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle52;
