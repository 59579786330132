import React from "react";
import NewsArticle37 from "../elements/NewsArticle37";
import NewsNav from "../elements/NewsNav";

const NewsDetails37 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle37 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails37;