import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle53 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Tarieven Duitse Maut voor 3,5 ton+ voertuigen

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage53.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                10-07-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Duitse Federal Highway Toll Act heeft bepaald dat{" "}
            <span style={{color: "#2b5baa"}}>
            vanaf 1 juli 2024 voertuigen{" "}
            </span>
            met een technisch toegestane max massa (F.1) van{" "}
            <span style={{color: "#2b5baa"}}>
            meer dan 3,5 ton en minder dan 7,5 ton
            </span>
            , die bestemd of gebruikt zijn voor goederenvervoer over de weg,{" "}
            <span style={{color: "#2b5baa"}}>
            tol plichtig worden
            </span>
            . Voorheen gold de tolplicht alleen voor voertuigen vanaf 7,5 ton.{" "}
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Daartegenover worden de{" "}
            <span style={{color: "#2b5baa"}}>
            tarieven voor vervoerders met voertuigen van 7,5 ton+ gewicht, lichtelijk verlaagd
            </span>
            . Voor commerciële pakketvervoerders (N1 voertuigen) werkt de aangepaste regeling dus in het nadeel, terwijl het voor goederenvervoer (N2+ voertuigen) in hun voordeel werkt. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om u een duidelijk overzicht te geven, hebben wij een tabel voor u opgesteld met de oude en nieuwe tarieven van de Duitse Maut. 
            De nieuwe tarieven gaan in per 1 juli 2024. De tarieven zijn gebaseerd op de componenten{" "}
            <span style={{color: "#9c29ff"}}>
            infrastructuur, luchtvervuiling, geluidshinder en CO2 uitstoot:
            </span>
            
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
              
              <div style={{ position: "relative" }}>
                <CardMedia
                
                  component="img"
                  src={require("../../assets/NewsImageDuitseMautPer01_07_24.png")}
                  alt="news image"
                  maxWidth="1119px"
                 
                />
              </div>
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Zijn uw voertuigen in de toekomst tolplichtig? Registreer dan uw voertuigen <a href="https://www.toll-collect.de/nl/toll_collect/fahren/registrierung/registrierung.html"> hier</a>  via de officiële website van de Duitse Maut. 


            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle53;
