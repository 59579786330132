import React from "react";
import NewsArticle49 from "../elements/NewsArticle49";
import NewsNav from "../elements/NewsNav";

const NewsDetails49 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle49 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails49;