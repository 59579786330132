import React from "react";
import NewsArticle15 from "../elements/NewsArticle15";
import NewsNav from "../elements/NewsNav";

const NewsDetails15 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle15 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails15;