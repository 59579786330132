import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle25 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Onterechte boetes bij gebruik van de nieuwe tachograaf 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage25.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                14-09-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf{" "} 
            <span style={{color: "#2b5baa"}}>
            21 augustus 2023{" "}
            </span>
            is de verplichte integratie van de Smart Tacho 2 in nieuw geregistreerde voertuigen van kracht. 
            De Smart Tacho 2 zal niet alleen dienen voor het {" "}
            <span style={{color: "#9c29ff"}}>
            handhaven van de rij- en rusttijden
            </span>
            , maar zal ook worden ingezet voor de handhaving van{" "} 
            <span style={{color: "#9c29ff"}}>
            cabotage en detachering
            </span>
            . In dit kader zijn nieuwe functionaliteiten toegevoegd, waaronder de{" "}
            <span style={{color: "#2b5baa"}}>
            automatische registratie van grensovergangen
            </span>
            . Helaas heeft deze nieuwe functionaliteit in sommige gevallen geleid tot onterechte{" "}
            <span style={{color: "#9c29ff"}}>
            boetes bij grensovergangen.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het is van belang op te merken dat deze automatische grensovergangen alleen worden vastgelegd op de{" "}
            <span style={{ color: "#2b5baa" }}>
            vanaf eind juli 2023{" "}
            </span>
            geleverde{" "}
            <span style={{ color: "#2b5baa" }}>
            G2V2-bestuurderskaarten{" "}
            </span>
            en niet op oudere bestuurderskaarten. Desalniettemin worden deze automatische grensovergangen wel geregistreerd 
            in het geheugen van de 
            Smart Tacho 2 zelf. Tijdens een inspectie kunnen de handhavende autoriteiten deze gegevens raadplegen 
            in de tachograaf. Echter, dit kan een{" "}
            <span style={{ color: "#2b5baa" }}>
            uitdaging{" "}
            </span>
            vormen voor handhavers wanneer een chauffeur{" "}
            <span style={{color: "#2b5baa" }}>
            afwisselend rijdt in voertuigen zonder Smart Tacho 2.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In situaties waarin de chauffeur gebruikmaakt van een digitale tachograaf of een Smart Tacho 1, zal hij{" "}
            <span style={{ color: "#9c29ff" }}>
            handmatig{" "}
              </span>
            landcodes moeten invoeren om grensovergangen te registreren. Er bestaat bezorgdheid dat{" "} 
            <span style={{ color: "#2b5baa" }}>
            handhavende instanties{" "}
              </span>
            mogelijk{" "}
            <span style={{ color: "#2b5baa" }}>
            onvoldoende op de hoogte{" "}
              </span>
            zijn van de verschillen tussen de nieuwe en oude bestuurderskaarten, wat tot {" "}
            <span style={{ color: "#9c29ff" }}>
            (onterechte) boetes{" "}
              </span>
            zou kunnen leiden. Het is echter belangrijk te benadrukken dat dergelijke boetes{" "}
            <span style={{ color: "#2b5baa" }}>
            juridisch geen stand{" "}
              </span>
            kunnen houden, aangezien chauffeurs volledig in overeenstemming handelen met de Europese verordeningen wanneer 
            zij grensovergangen niet handmatig registreren bij gebruik van een Smart Tacho 2. Dit is tevens een van de 
            voordelen van de Smart Tacho 2. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het is momenteel{" "}
            <span style={{ color: "#2b5baa" }}>
            niet mogelijk{" "}
              </span>
            om een{" "}
            <span style={{ color: "#9c29ff" }}>
            nieuwe bestuurderskaart aan te vragen{" "}
              </span>
            om dit probleem te omzeilen, aangezien dit alleen kan gebeuren wanneer de oude kaart niet meer 
            geldig is, beschadigd is of verloren/gestolen is. {" "}
            <span style={{ color: "#2b5baa" }}>
            De Europese Commissie {" "}
            </span>
            onderzoekt momenteel of 
            deze regels {" "}
            <span style={{ color: "#2b5baa" }}>
            tijdelijk kunnen worden versoepeld
            </span>
            , maar dit zou het probleem niet volledig oplossen, 
            tenzij alle chauffeurs verplicht worden om een nieuwe kaart aan te vragen. Dit wordt echter niet 
            als een aanvaardbare oplossing beschouwd, tenzij deze kaarten kosteloos worden verstrekt en zelfs 
            dan blijft de vraag of een grootschalige vervanging haalbaar en uitvoerbaar is. 
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Transport en Logistiek Nederland (TLN) heeft dit probleem geruime tijd geleden al aangekaart binnen de 
            handhavingsgemeenschap in Brussel, zowel via de International Road Transport Union (IRU) als via de 
            Conference of European Road Transport Enforcement (CORTE). In de komende periode zal dit probleem{" "}
            <span style={{ color: "#2b5baa" }}>
            verder worden besproken{" "}
              </span>
            en zal er worden{" "}
            <span style={{ color: "#2b5baa" }}>
            gezocht naar een oplossing{" "}
              </span>
            voor de handhaving. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle25;
