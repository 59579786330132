import { Box, Typography, Grid } from "@mui/material";
import React from "react";
// import ArmAt from "../../assets/ArmAt.png";
import BezoekOns from "../../assets/BezoekOns.png";
// import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import Image from "mui-image";
// import { Link } from "react-router-dom";
// import Collapse from "@mui/material/Collapse";
// import { useNavigate } from "react-router-dom";

const BannerContactMapsMeeting = () => {
  // const [contactFormVisible, setContactFormVisible] = React.useState(false);

  // const navigate = useNavigate();
  // const handleContactForm = () => {
  //   // setContactFormVisible((prevCheck) => !prevCheck);
  //   navigate("#formulier");
  // };
  const HandleMapsClick = () => {
    window.open(
      "https://www.google.nl/maps/place/Pelmolenlaan+2,+3447+GW+Woerden/@52.0779844,4.8921555,17z/data=!3m1!4b1!4m6!3m5!1s0x47c679c2c233b0cf:0xb22d13e6b74e8276!8m2!3d52.0779844!4d4.8943442!16s%2Fg%2F11bw3y3qc0",
      "_blank"
    );
  };
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          pt: 5,
          pb: 5,
          backgroundColor: "#FAFCFF",
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  // p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  p: 0,
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: "2.8rem",
                    lineHeight: "2.6rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "4.4rem",
                      lineHeight: "4.2rem",
                    },
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Bezoek ons
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Spreekt u ons liever{" "}
                  <span style={{ color: "#9c29ff" }}> face to face?</span> Wij
                  van Delta zijn niet vies van een bakje koffie, dus kom gerust
                  bij ons langs!
                </Typography>
                {/* <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Heeft u liever dat wij bij u langskomen? Ook dat is mogelijk!
                  Plan uw afspraak in via de knop hieronder.
                </Typography> */}
              </Box>
              {/* <Box container direction="column">
                <Button
                  display="flex"
                  onClick={handleContactForm}
                  // onClick={handleCalculateNow}
                  // variant="Outlined"
                  sx={{
                    textTransform: "unset",
                    // my: 0,
                    mt: 7,
                    backgroundColor: "#C85EEE",
                    "&:hover": {
                      backgroundColor: "#9d49bb",
                    },
                    color: "#FFFFFF",
                    width: 250,
                    height: 67,
                    borderRadius: 35,
                    fontSize: "1.3rem",
                    "@media (min-width:600px)": {
                      fontSize: "1.7rem",
                      mt: 10,
                      width: 304,
                      height: 86,
                    },
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                    background: "linear-gradient(to right, #9b29ff, #c85eee)",
                  }}
                >
                  Afspraak inplannen
                </Button>
              </Box> */}
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                onClick={(e) => HandleMapsClick(e, "Normal refund")}
                sx={{
                  display: "flex",
                  // p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  p: 0,
                  // height: "100%",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                    maxWidth: "100%",
                    cursor: "pointer",
                  }}
                  img
                  src={BezoekOns}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default BannerContactMapsMeeting;
