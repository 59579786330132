import React from "react";
import NewsArticle3 from "../elements/NewsArticle3";
import NewsNav from "../elements/NewsNav";

const NewsDetails3 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle3 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails3;
