import React from "react";
import NewsArticle36 from "../elements/NewsArticle36";
import NewsNav from "../elements/NewsNav";

const NewsDetails36 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle36 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails36;