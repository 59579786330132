import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

const NewsArticle5 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Minimum bedragen per BTW teruggaaf verzoek
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage5.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                07.04.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Wellicht vraagt u zich wel eens af waarom u geconfronteerd wordt
              met bepaalde minimum bedragen per teruggaaf land. Of uw
              intermediair communiceert dat een bedrag niet aan het minimum
              voldoet. Het is de intermediair niet aan te rekenen, want het zijn
              bedragen die ieder teruggaafland (ieder afzonderlijk) zelf
              hanteert. Haalt u dat minimum niet, dan kan er simpelweg geen
              teruggaaf verzoek worden ingediend.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              In 2008 (ontwerp richtlijn) is er in de toepasselijke EU richtlijn
              (richtlijn 2008/9/EG van de raad, Art 17) bepaald dat de
              <span style={{ color: "#2B5BAA" }}> minimum bedragen </span>
              per land op
              <span style={{ color: "#9c29ff" }}>
                {" "}
                kwartaalbasis gesteld is op &euro; 400{" "}
              </span>
              en op
              <span style={{ color: "#2B5BAA" }}> jaarbasis &euro; 50</span>. Er
              zijn echter een paar kleine uitzonderingen. Zo zijn er nog steeds
              landen in de EU die hun eigen valuta hebben. Toen de EU richtlijn
              in 2010 van kracht werd en omgezet in nationale wetgeving, werd er
              op dat moment bepaald wat de omrekening in eigen valuta’s was en
              daarmee een equivalent van € 400 op kwartaal- en € 50 op
              jaarbasis. Vanwege de altijd veranderende wisselkoersen kan dit
              anno 2023 wat scheefgetrokken zijn ten opzichte van 2010.
              Hieronder een overzicht van de EU landen met eigen valuta’s en de
              daarbij horende omgerekende minimum bedragen per kwartaal- en jaar
              in euro’s:
            </Typography>
            <div style={{ position: "relative" }}>
              {windowWidth > 990 ? (
                <CardMedia
                  component="img"
                  src={require("../../assets/NewsImage5_2x2.png")}
                  alt="news image"
                  maxWidth="1119px"
                />
              ) : (
                <CardMedia
                  component="img"
                  src={require("../../assets/NewsImage5_3.png")}
                  alt="news image"
                  maxWidth="1119px"
                />
              )}
            </div>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Verder kunnen er ook
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                BTW teruggaaf verzoeken worden gedaan in non-EU landen
              </span>
              , zoals bijv.
              <span style={{ color: "#9c29ff" }}> Zwitserland</span>,{" "}
              <span style={{ color: "#9c29ff" }}> Groot Brittannië </span>en
              <span style={{ color: "#9c29ff" }}> Noorwegen</span>. Deze landen
              hoeven zich niet te houden aan de EU richtlijnen. In Zwitserland
              bijv. kunt u alleen op jaarbasis BTW teruggaaf verzoeken indienen,
              waarbij het minimum bedrag CHF 500 is (omgerekend anno 2023 ± €
              500). In Groot Brittannië kunt u wel BTW teruggaaf verzoek doen op
              kwartaal- of jaarbasis en is het minimum per kwartaal GBP 130
              (omgerekend ± € 150) en per jaar GBP 16 (omgerekend ± € 20).
            </Typography>
            <div style={{ position: "relative" }}>
              {windowWidth > 990 ? (
                <CardMedia
                  component="img"
                  src={require("../../assets/NewsImage5_4x2.png")}
                  alt="news image"
                  maxWidth="1119px"
                />
              ) : (
                <CardMedia
                  component="img"
                  src={require("../../assets/NewsImage5_5.png")}
                  alt="news image"
                  maxWidth="1119px"
                />
              )}
            </div>
            <Typography
              sx={{
                mt: 0,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Het valt u misschien op dat het
              <span style={{ color: "#2B5BAA" }}> kwartaal minimum </span>een
              <span style={{ color: "#2B5BAA" }}> hoger bedrag </span>is
              <span style={{ color: "#2B5BAA" }}> dan </span>het
              <span style={{ color: "#2B5BAA" }}> jaarminimum</span>. Het heeft
              te maken met het feit dat lidstaten willen voorkomen dat er ieder
              kwartaal te lage bedragen worden teruggevraagd. Daardoor stelt men
              kwartaalminimum hoger dan het jaarminimum. Toch willen ze (bijna)
              iedereen de mogelijkheid geven het BTW-bedrag terug te geven.
              Vandaar dat het jaarminimum in bijna alle lidstaten op € 50 (of
              equivalent ervan) staat. Als u minder dan € 50 per jaar aan BTW
              heeft opgespaard en wil u het terugkrijgen, dan vist u helaas
              achter het net. Wij hopen dat u daar niet wakker van ligt, maar
              kunnen het niet mooier voor u maken.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle5;
