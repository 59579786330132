import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle47 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Geen uitbreiding van tolwegennetwerk in België  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage47.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                15-03-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Transportbedrijven in België en over de hele wereld keken de afgelopen periode met bezorgdheid naar de plannen van de{" "}
            <span style={{color: "#2b5baa"}}>
            Vlaamse{" "}
            </span>
            regering om het tolwegennetwerk uit te breiden en kilometerheffing in te voeren. Dankzij een {" "}
            <span style={{color: "#2b5baa"}}>
            sterke lobby {" "}
            </span>
            van de Belgische transportsector werd dit echter{" "}
            <span style={{color: "#2b5baa"}}>
            voorkomen.
            </span>
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De afgelopen maanden kreeg de Belgische transportsector al te maken met de introductie van tol op bijna 700 kilometer 
            wegen in Vlaanderen. Recentelijk zijn er plannen besproken om op{" "}
            <span style={{ color: "#2b5baa" }}>
            1 juli 2024{" "}
            </span>
            nog eens{" "}
            <span style={{ color: "#9c29ff" }}>
            500 kilometer{" "}
            </span>
            aan het tolwegennet in Vlaanderen toe te voegen. Dit was voorgesteld door Vlaams minister van Mobiliteit, Lydia Peeters. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
        Transport & Logistics Belgium (TLB), de overkoepelende organisatie van de drie erkende transportfederaties in 
        België, liet het er niet bij zitten. Alle ministers ontvingen schriftelijke protesten. De vastberadenheid en 
        argumenten hebben hun vruchten afgeworpen. TLB verklaarde: "Na weken van inspanningen en verschillende gesprekken 
        hebben we uiteindelijk de toezegging gekregen dat de Vlaamse regering deze{" "}
        <span style={{color: "#2b5baa"}}>
        uitbreidingen niet zal doorvoeren
        </span>
        ."
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Betekent dit dat er in 2024 geen extra tolkosten zullen zijn in Vlaanderen?{" "}
              <span style={{ color: "#9c29ff" }}>
              hoogstwaarschijnlijk toch wel
            </span>
            , aangezien er al gesprekken zijn geweest over een alternatief. Volgens TLB zou dit{" "}
            <span style={{ color: "#2b5baa" }}>
            alternatief{" "}
            </span>
            kunnen zijn dat de tol op alle bestaande wegen, waar al tol wordt geheven,{" "}
            <span style={{ color: "#2b5baa" }}>
            wordt verhoogd. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Hoewel het in eerste instantie goed nieuws lijkt dat er geen verdere uitbreiding van het tolwegennetwerk zal plaatsvinden, 
            blijft het onzeker of er een tariefverhoging komt over de bestaande tolwegen. Het is dus verstandig om deze ontwikkelingen{" "}
            <span style={{ color: "#9c29ff" }}>
            nauwlettend te volgen
            </span>
            , zodat er tijdig op geanticipeerd kan worden. 
              </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle47;
