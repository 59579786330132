import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import Logo from "../assets/logo_white.png";
import { Link, useNavigate } from "react-router-dom";
import Image from "mui-image";
import EmailIcon from "../assets/icons/EmailIcon.png";
import PhoneIcon from "../assets/icons/PhoneIcon.png";
import LocationIcon from "../assets/icons/LocationIcon.png";
import CopyrightIcon from "../assets/icons/copyrightIcon.png";
import Facebook from "../assets/Facebook.png";
import LinkedIn from "../assets/Linked in.png";

const Footer = () => {
  const navigate = useNavigate();
  const logoOnClick = (e) => {
    navigate("/Home");
  };
  return (
    <Container maxWidth="100%" sx={{ backgroundColor: "#2B5BAA" }}>
      <Container maxWidth="xl">
        <Grid
          container
          md={12}
          xs={12}
          justifyContent="center"
          sx={{ pt: 10, pb: 10 }}
        >
          <Grid xs={12} md={12} lg={3}>
            <Box
              component="img"
              sx={{
                height: 82,
                cursor: "pointer",
                display: { xs: "none", md: "flex" },
              }}
              alt="Delta Refund Solutions"
              src={Logo}
              onClick={logoOnClick}
            />
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Typography
              sx={{
                mb: 2,
                color: "#FFFFFF",
                textAlign: "left",
                fontSize: { xs: "20px", md: "20px", xl: "20px" },
                lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
                a: {
                  color: "#FFFFFF",
                },
                "a:visited": {
                  color: "#FFFFFF",
                },
              }}
            >
              <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                Diensten
              </Link>
            </Typography>
            <Stack
              sx={{
                textAlign: "left",
                fontSize: { xs: "20px", md: "20px", xl: "20px" },
                lineHeight: { xs: "30px", md: "30px", xl: "30px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
                a: {
                  color: "#FFFFFF",
                },
                "a:visited": {
                  color: "#FFFFFF",
                },
              }}
              display="flex"
              direction="column"
            >
              <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                Delta Petrol
              </Link>
              <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                Delta Hybrid
              </Link>
              <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                Delta Electric
              </Link>
              <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                Delta Diesel
              </Link>
            </Stack>
          </Grid>
          <Grid xs={12} md={4} lg={2}>
            <Stack
              sx={{
                mb: 2,
                color: "#FFFFFF",
                textAlign: "left",
                fontSize: { xs: "20px", md: "20px", xl: "20px" },
                lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
                a: {
                  color: "#FFFFFF",
                },
                "a:visited": {
                  color: "#FFFFFF",
                },
              }}
              display="flex"
              direction="column"
            >
              <Link
                style={{ marginBottom: "25px", textDecoration: "none" }}
                to="/DieselRates"
              >
                Accijns vergoedingen
              </Link>
              <Link
                style={{ marginBottom: "25px", textDecoration: "none" }}
                to="/News"
              >
                Nieuws
              </Link>
              <Link
                style={{ marginBottom: "25px", textDecoration: "none" }}
                to="/OverDelta"
              >
                Over Delta
              </Link>
              {/* <Link style={{ textDecoration: "none" }} to="/DeltaPetrol">
                  Login
                </Link> */}
            </Stack>
          </Grid>
          <Grid xs={12} md={4} lg={2}></Grid>
          <Grid xs={12} md={12} lg={3}>
            <Stack
              sx={{
                textAlign: "left",
                fontSize: { xs: "20px", md: "20px", xl: "20px" },
                lineHeight: { xs: "30px", md: "30px", xl: "30px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
                a: {
                  color: "#FFFFFF",
                },
                "a:visited": {
                  color: "#FFFFFF",
                },
              }}
              display="flex"
              direction="column"
            >
              <Typography
                sx={{
                  mb: 2,
                  color: "#FFFFFF",
                  textAlign: "left",
                  fontSize: { xs: "20px", md: "20px", xl: "20px" },
                  lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                  letterSpacing: "0px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Bold",
                  a: {
                    color: "#FFFFFF",
                  },
                  "a:visited": {
                    color: "#FFFFFF",
                  },
                }}
              >
                <Link style={{ textDecoration: "none" }} to="/Contact">
                  Contact
                </Link>
              </Typography>
              <List>
                <ListItem sx={{ p: 0, mb: 3, alignItems: "flex-start" }}>
                  <ListItemIcon>
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:info@deltarefundsolutions.com"
                    >
                      <Image
                        img
                        src={EmailIcon}
                        alt="cur"
                        height={23}
                        width={29}
                      />
                    </a>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "20px", md: "20px", xl: "20px" },
                        lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                        letterSpacing: "0px",
                        fontFamily: "HelveticaNeue-Light",
                      }}
                    >
                      <a
                        style={{ textDecoration: "none" }}
                        href="mailto:info@deltarefundsolutions.com"
                      >
                        info@deltarefundsolutions.com
                      </a>
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0, mb: 3, alignItems: "flex-start" }}>
                  <ListItemIcon>
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel:+31858085522"
                    >
                      <Image
                        img
                        src={PhoneIcon}
                        alt="cur"
                        height={29}
                        width={29}
                      />
                    </a>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "20px", md: "20px", xl: "20px" },
                        lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                        letterSpacing: "0px",
                        fontFamily: "HelveticaNeue-Light",
                      }}
                    >
                      <a
                        style={{ pb: 3, textDecoration: "none" }}
                        href="tel:+31858085522"
                      >
                        +31 85 808 55 22
                      </a>
                      <br />
                      Bereikbaar op
                      <br />
                      ma t/m vrij van 8:30 - 16:30
                    </Typography>
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ p: 0, mb: 3, alignItems: "flex-start" }}>
                  <ListItemIcon>
                    <a
                      href="https://www.google.nl/maps/place/Pelmolenlaan+2,+3447+GW+Woerden/@52.0779844,4.8921555,17z/data=!3m1!4b1!4m6!3m5!1s0x47c679c2c233b0cf:0xb22d13e6b74e8276!8m2!3d52.0779844!4d4.8943442!16s%2Fg%2F11bw3y3qc0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Image
                        img
                        src={LocationIcon}
                        alt="cur"
                        height={39}
                        width={29}
                      />
                    </a>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "20px", md: "20px", xl: "20px" },
                        lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                        letterSpacing: "0px",
                        fontFamily: "HelveticaNeue-Light",
                        a: {
                          color: "#FFFFFF",
                        },
                        "a:visited": {
                          color: "#FFFFFF",
                        },
                      }}
                    >
                      <a
                        style={{ textDecoration: "none" }}
                        href="https://www.google.nl/maps/place/Pelmolenlaan+2,+3447+GW+Woerden/@52.0779844,4.8921555,17z/data=!3m1!4b1!4m6!3m5!1s0x47c679c2c233b0cf:0xb22d13e6b74e8276!8m2!3d52.0779844!4d4.8943442!16s%2Fg%2F11bw3y3qc0"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Pelmolenlaan 2<br />
                        3447GW Woerden
                      </a>
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Stack>
          </Grid>
        </Grid>
        <Grid container md={12} xs={12} sx={{ pt: 10, pb: 10 }}>
          <Grid xs={0} md={0} lg={2}></Grid>
          <Grid xs={12} md={6} lg={1}>
            <Stack display="flex" direction="row">
              <a
                href="https://www.facebook.com/DeltaRefundSolutions"
                target="_blank"
                rel="noreferrer"
              >
                <Typography>
                  <Image src={Facebook} width="20px" sx={{ mt: 0.4 }} />
                </Typography>
              </a>
              <a
                href="https://www.linkedin.com/company/delta-refund-solutions/"
                target="_blank"
                rel="noreferrer"
              >
                <Typography>
                  <Image src={LinkedIn} width="35px" sx={{ mt: 0.35, ml: 3 }} />
                </Typography>
              </a>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Stack
              display="flex"
              direction="row"
              height={37}
              alignItems="flex-end"
            >
              <Image
                src={CopyrightIcon}
                width="20px"
                height="20px"
                sx={{ mt: 0.35 }}
              />
              <Typography
                sx={{
                  ml: 1,
                  color: "#FFFFFF",
                  fontSize: { xs: "20px", md: "20px", xl: "20px" },
                  lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                  letterSpacing: "0px",
                  fontFamily: "HelveticaNeue-Light",
                }}
              >
                2023 Delta Refund Solutions B.V.
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={2}>
            <Stack
              display="flex"
              direction="row"
              height={37}
              alignItems="flex-end"
            >
              <Typography
                sx={{
                  ml: 1,
                  color: "#FFFFFF",
                  fontSize: { xs: "20px", md: "20px", xl: "20px" },
                  lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                  letterSpacing: "0px",
                  fontFamily: "HelveticaNeue-Light",
                }}
              >
                • KvK 89304578
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={3}>
            <Stack
              display="flex"
              direction="row"
              height={37}
              alignItems="flex-end"
            >
              <Typography
                sx={{
                  ml: 1,
                  color: "#FFFFFF",
                  fontSize: { xs: "20px", md: "20px", xl: "20px" },
                  lineHeight: { xs: "24px", md: "24px", xl: "24px" },
                  letterSpacing: "0px",
                  fontFamily: "HelveticaNeue-Light",
                }}
              >
                • BTW-nr NL864941602B01
              </Typography>
            </Stack>
          </Grid>
          <Grid xs={0} md={0} lg={1}></Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Footer;
