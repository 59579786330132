import React from "react";
import NewsArticle10 from "../elements/NewsArticle10";
import NewsNav from "../elements/NewsNav";

const NewsDetails10 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle10 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails10;