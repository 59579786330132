import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle17 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Hoe bereid je je goed voor op informatieverzoeken? 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage17.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                06-07-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Naast dat het belangrijk is om als onderneming die buitenlandse BTW-teruggaaf verzoeken doet op de hoogte te zijn van de reactietermijnen van {" "}
            <span style={{ color: "#2b5baa" }}>
            informatieverzoeken
              </span>
            , is het ook belangrijk om te weten
            {" "}
            <span style={{ color: "#2b5baa" }}>
            waarom- en hoe
            {" "}
              </span>
            te reageren op de informatieverzoeken.
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Waarom sturen buitenlandse belastingdiensten informatieverzoeken? Bij teruggaaf van grote bedragen is het{" "}
            <span style={{ color: "#2b5baa" }}>
            risico op fraude{" "}
              </span>
              altijd aanwezig. Om fraude te voorkomen sturen de buitenlandse autoriteiten informatieverzoeken wanneer 
              ze de aanvraag niet vertrouwen, wanneer het om grote bedragen gaat en het risico dus groot is, maar ook gewoon{" "}
              <span style={{ color: "#9c29ff" }}>
            steekproefsgewijs
              </span>
            . Deze steekproef hebben wij empirisch kunnen vaststellen op{" "}
            <span style={{ color: "#9c29ff" }}>
            ca. 10%
              </span>
            . Dit betekent dat ondanks het feit dat uw aanvraag volledig is, u{" "}
            <span style={{ color: "#2b5baa" }}>
            vroeg of laat te maken kunt krijgen met informatieverzoeken.
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De meest voorkomende communicatiemiddelen die lidstaten hanteren zijn{" "}
            <span style={{ color: "#9c29ff" }}>
            per brief (schriftelijk) of per email
              </span>
            . Het is dus cruciaal om een{" "}
            <span style={{ color: "#2b5baa" }}>
            geordend systeem{" "}
              </span>
            te hebben waarbij de kans op het over het hoofd zien van zo'n informatieverzoek 
            nihil is. De buitenlandse belastingdiensten nemen helaas{" "}
            <span style={{ color: "#2b5baa" }}>
            geen genoegen{" "}
              </span>
            met: “sorry, we hebben het informatieverzoek over het hoofd gezien”.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Instructies hoe je moet reageren staat vaak beschreven in de brief/email zelf. Staan 
            de instructies er niet in beschreven, dan wordt er over het algemeen verwacht dat je in{" "}
            <span style={{ color: "#9c29ff" }}>
            dezelfde taal- en via hetzelfde communicatiemiddel reageert{" "}
              </span>
            als waarmee het informatieverzoek binnenkomt. Informatieverzoeken komen vrijwel altijd in de{" "}
            <span style={{ color: "#9c29ff" }}>
            native taal
              </span>
            . Een algemene beheersing van de taal waarin je een teruggaaf verzoek doet is dus vereist. 
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Waar gaan de informatieverzoeken over? Tenzij er een specifieke aanleiding is voor een informatieverzoek, 
            is het vaak een verzoek voor het aanleveren van{" "}
            <span style={{ color: "#2b5baa" }}>
            kopieën van facturen, -bewijs van indiening en -aankoop of betaalbewijzen
              </span>
            . Het op tijd{" "}
            <span style={{ color: "#2b5baa" }}>
            lokaal opslaan{" "}
              </span>
            van deze documenten is van cruciaal belang om te voorkomen dat u deze documenten tijdig voorhanden hebt 
            wanneer een informatieverzoek binnenkomt. Zo laten bijvoorbeeld bepaalde tankkaartleveranciers het slechts 
            toe om de facturen te downloaden{" "}
            <span style={{ color: "#9c29ff" }}>
            tot een maand na uitgifte van de factuur.
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Binnen welke termijn moet ik reageren op de informatieverzoeken? In vorig artikel is specifiek beschreven 
            per Europees land wat de termijn is waarin gereageerd moet worden om{" "}
            <span style={{ color: "#2b5baa"}}>
            vertraging- of volledige afwijzing van het BTW teruggaaf verzoek{" "}   
            </span>
            te voorkomen. De meeste landen hanteren een termijn van{" "}
            <span style={{ color: "#2b5baa"}}>
            30 dagen 
            </span>
            . Dan zijn er ook uitschieters zoals Spanje die een termijn van maar 10 werkdagen hanteert. 
             </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de EU-richtlijn 2008/9/EG VAN DE RAAD Art 20 lid 1 en 2 wordt gesteld dat ingeval de lidstaat van teruggaaf meent
            niet alle dienstige informatie te hebben ontvangen om met betrekking tot het geheel of een deel van het teruggaafverzoek 
            een beschikking te kunnen geven, kan hij binnen de in artikel 19, lid 2, genoemde{" "}
            <span style={{ color: "#9c29ff" }}>
            termijn van 4 maanden
              </span>
              , langs elektronische weg in het bijzonder de aanvrager of de lidstaat van vestiging om aanvullende gegevens 
              verzoeken. In lid 2 wordt een{" "}
              <span style={{ color: "#9c29ff" }}>
            maximale termijn gesteld van 1 maand{" "}
              </span>
            na ontvangst van het verzoek om informatie door de bestemmeling van het verzoek aan de lidstaat van teruggaaf te worden verstrekt.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de praktijk blijkt dat vele lidstaten deze termijnen strak hanteren en te laat reageren op informatieverzoeken{" "}
            <span style={{ color: "#2b5baa"}}>
            afgestraft wordt door een onherroepelijke afwijzing 
            </span>
            . Al met al is het van cruciaal belang om{" "}
            <span style={{ color: "#9c29ff" }}>
            geordend te werk te gaan
              </span>
            . Dit houdt in het strak in de gaten houden van uw{" "}
            <span style={{ color: "#9c29ff" }}>
            post- en mail verkeer
              </span>
            , het bijhouden van{" "}
            <span style={{ color: "#9c29ff" }}>
            benodigde documenten
              </span>
            , het bewust zijn van{" "}
            <span style={{ color: "#9c29ff" }}>
            hoe je moet reageren{" "}
              </span>
            en het{" "}
            <span style={{ color: "#9c29ff" }}>
            in de gaten houden van de termijnen{" "}
              </span>
            waarop dit moet gebeuren.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle17;
