import React from "react";
import NewsArticle26 from "../elements/NewsArticle26";
import NewsNav from "../elements/NewsNav";

const NewsDetails26 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle26 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails26;