import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle43 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Grote rechtszaak tegen Duits tolbeheer  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage43.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                15-02-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De transportvereniging{" "}
            <span style={{color: "#2b5baa"}}>
            Camion Pro eV{" "}
            </span>
            heeft een petitie ingediend tegen{" "}
            <span style={{color: "#2b5baa"}}>
            Toll Collect GmbH
            </span>
            , de exploitant van het Duitse tolsysteem, vanwege vermeend misbruik van haar dominante marktpositie.
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
           De Bondsrepubliek Duitsland heeft de tol voor vrachtwagens{" "}
            <span style={{ color: "#9c29ff"}}>
            verkeerd berekend{" "}
            </span>
            door de kosten van de snelwegpolitie op te nemen in de kosten van de wegeninfrastructuur, wat in 
            strijd was met de EU-regels. Deze kwestie werd beoordeeld door het Hof van Justitie van de Europese 
            Unie naar aanleiding van een zaak van een Poolse vervoerder die te veel betaalde tol probeerde terug te vorderen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Met als doel het veiligstellen van een vordering, heeft de rechtbank van Berlijn
             een hoorzitting gepland op{" "}
            <span style={{color: "#2b5baa"}}>
            26 februari{" "}
            </span>
            van dit jaar. Camion Pro eV benadrukt dat als de rechtbank een kort geding
             uitvaardigt, Toll Collect geconfronteerd zal worden met een zware rechtszaak en{" "}
            <span style={{color: "2b5baa"}}>
            mogelijk miljoenen{" "}
            </span>
            aan schadevergoedingen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In totaal {" "}
            <span style={{ color: "#9c29ff" }}>
            15.000 transportbedrijven
            </span>
            , waaronder 480 uit Nederland, eisen claims voor illegaal geheven vrachtwagentol
             – meldt de Duitse transportwebsite dvz.de. Dit zijn tolgelden die op een later 
             tijdstip te veel zijn betaald dan de restituties die door Camion Pro-leden worden
              geclaimd.
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >Volgens de door het advocatenkantoor verstrekte informatie bedraagt het 
            totale bedrag aan tolgelden dat door bovengenoemde bedrijven tussen januari 
            2017 en september 2021 is betaald {" "}
            <span style={{ color: "#9c29ff" }}>
            € 7,5 miljard
            </span>
            , waarvan{" "}
            <span style={{ color: "#9c29ff" }}>
            € 330 miljoen {" "}
            </span>
            onrechtmatig in rekening gebrachte verkeerspolitiekosten. Bij dit bedrag moet de wettelijke rente worden opgeteld. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >De uitkomst van deze juridische strijd zal niet alleen grote 
            financiële gevolgen hebben voor Toll Collect en de Duitse overheid, 
            maar kan ook{" "}
            <span style={{ color: "#2b5baa" }}>
            bredere implicaties{" "}
            </span>
            hebben voor toekomstige tolheffingssystemen en de handhaving van EU-regels 
            met betrekking tot wegvervoer. Het is duidelijk dat deze zaak de komende
             maanden en mogelijk jaren een belangrijk aandachtspunt zal blijven binnen 
             de transportsector in Europa.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle43;
