import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle20 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Britse truckheffing vanaf 1 augustus weer ingevoerd   
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage20.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                27-07-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb:5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Vanaf{" "}
            <span style={{ color: "#2b5baa" }}>
            1 augustus {" "}
              </span>
            wordt de 
            {" "}
            <span style={{ color: "#9c29ff" }}>
            Britse tolheffing
              </span>
            , ook wel{" "}
            <span style={{ color: "#9c29ff" }}>
            HGV road user levy{" "}
              </span>
              genoemd, opnieuw ingevoerd. Deze tolheffing was opgeschort tussen 1 augustus 2020 en 31 juli
               2023 om transportbedrijven tijdens de {" "}
            <span style={{ color: "#2b5baa" }}>
            coronacrisis{" "}
              </span>
            te ondersteunen.
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            HGV is een afkorting voor Heavy Goods Vehicle en deze wordt vooral gebruikt in het Verenigd Koninkrijk
             en Ierland. Deze regeling geldt voor goederenvoertuigen waarvan de{" "}
            <span style={{ color: "#2b5baa" }}>
            maximale massa groter is dan 3,5 ton
              </span>
            . De voertuigen worden onderverdeeld in categorie{" "}
              <span style={{ color: "#9c29ff" }}>
              N2 voor voertuigen tot 12.000 kg{" "}
              </span>
            en categorie{" "}
            <span style={{ color: "#9c29ff" }}>
            N3 voor voertuigen zwaarder dan 12.000 kg
              </span>
            .
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf 1 augustus zijn alle voertuigen{" "}
            <span style={{ color: "#2b5baa" }}>
            vanaf 12 ton tol plichtig{" "}
              </span>
            wanneer ze gebruik maken van{" "}
            <span style={{ color: "#2b5baa" }}>
            snelwegen of A-wegen{" "}
              </span>
              in het Verenigd Koninkrijk. Zowel Britse als buitenlandse voertuigen vallen onder deze tolheffing. 
              Voor vrachtwagens die niet in het Verenigd Koninkrijk geregistreerd zijn, geldt een tarief per dag, 
              week, maand of jaar. Een dagtarief loopt van{" "}
              <span style={{ color: "#9c29ff" }}>
              0.00 uur tot 23.59 uur{" "}
                </span>
              op dezelfde dag.
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De hoogte van het tolbedrag varieert tussen de{" "}
            <span style={{ color: "#2b5baa" }}>
            3 en 10 Britse Pond per dag   
            </span>
            , afhankelijk van het gewicht van het voertuig (inclusief het gewicht van de aanhangwagen in geval van een trekker-oplegger 
            combinatie met aanhangwagen), de Euro-emissieklasse van het voertuig en de duur van het verblijf van het voertuig in het Verenigd 
            Koninkrijk. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 5,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om eventuele complicaties te voorkomen bestaat er de mogelijkheid om de tolheffing al{" "}
            <span style={{ color: "#9c29ff" }}>
            voor de rit{" "}
              </span>
              in Groot-Brittannië{" "}
            <span style={{ color: "#9c29ff" }}>
            te betalen
              </span>
              . Omwille van toegankelijkheid kan deze betaling van de Britse tolheffing ook zonder registratie worden gedaan.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Deze heffing is iets om rekening mee te houden bij het aangaan van een vervoerscontract 
            (kort- of langdurig) van en naar Engeland, aangezien het kostprijsverhogend werkt. Wellicht
             kan (een deel) aan opdrachtgevers worden doorbelast. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle20;
