import { Box, Typography, Grid, TextField } from "@mui/material";
import React, { useRef } from "react";
import ArmAt from "../../assets/ArmAt.png";
import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import Image from "mui-image";
// import { Link } from "react-router-dom";
// import Switch from "@mui/material/Switch";
// import Slide from "@mui/material/Slide";
// import FormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import emailjs from "@emailjs/browser";

const BannerContactEmail = () => {
  const form = useRef();
  const [contactFormVisible, setContactFormVisible] = React.useState(false);
  const [succesLabelVisible, setSuccesLabelVisible] = React.useState(false);
  const [errorLabelVisible, setErrorLabelVisible] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const sendEmail = (e) => {
    setSubmitButtonDisabled(true);
    // e.preventDefault();
    // var hasError;
    emailjs
      .sendForm(
        "service_pcmrdxj",
        "template_yzhhh3p",
        form.current,
        "_yi0_0d3ScPyf_1Dl"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(succesLabelVisible);
          setSuccesLabelVisible(true);
          console.log(succesLabelVisible);
        },
        (error) => {
          console.log(error.text);
          setErrorLabelVisible(true);
        }
      );
  };

  const handleContactForm = () => {
    setContactFormVisible((prevCheck) => !prevCheck);
  };

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          pt: 10,
          pb: 5,
          backgroundColor: "#FAFCFF",
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  // p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  p: 0,
                  height: "100%",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ m: 0 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                    maxWidth: "100%",
                  }}
                  img
                  src={ArmAt}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>

            <Grid item lg={6} md={12}>
              <Collapse in={!contactFormVisible}>
                <Box
                  id="formulier"
                  sx={{
                    position: "relative",
                    // p: { xs: 3, md: 6 },
                    pr: { md: 0 },
                    p: 0,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 3,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: "2.8rem",
                      lineHeight: "2.6rem",
                      "@media (min-width:600px)": {
                        mt: 5,
                        fontSize: "4.4rem",
                        lineHeight: "4.2rem",
                      },
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Bericht ons
                  </Typography>
                  <Typography
                    sx={{
                      mt: { xs: 3, md: 5 },
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "24px", md: "40px" },
                      lineHeight: { xs: "36px", md: "50px" },
                      fontFamily: "HelveticaNeue-Light",
                      a: {
                        color: "#2B5BAA",
                      },
                      "a:visited": {
                        color: "#2B5BAA",
                      },
                    }}
                  >
                    Liever contact over de mail?
                    <br />
                    Wij beantwoorden graag uw vragen via:{" "}
                    <span style={{ color: "#2b5baa" }}>
                      <a
                        style={{ textDecoration: "none" }}
                        href="mailto:info@deltarefundsolutions.com"
                      >
                        info@deltarefundsolutions.com
                      </a>
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      mt: { xs: 3, md: 5 },
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "24px", md: "40px" },
                      lineHeight: { xs: "36px", md: "50px" },
                      fontFamily: "HelveticaNeue-Light",
                    }}
                  >
                    Wij streven ernaar om binnen 2 werkdagen uw vraag te
                    beantwoorden.
                  </Typography>
                  <Typography
                    sx={{
                      mt: { xs: 3, md: 5 },
                      color: "#6b6a6a",
                      textAlign: "left",
                      fontSize: { xs: "24px", md: "40px" },
                      lineHeight: { xs: "36px", md: "50px" },
                      fontFamily: "HelveticaNeue-Light",
                    }}
                  >
                    Heeft u zeer specifieke vragen aan ons? Vul het{" "}
                    <span style={{ color: "#9c29ff" }}>contactformulier</span>{" "}
                    hieronder in, zo kunnen wij u gerichter helpen.
                  </Typography>
                  <Box container direction="column">
                    <Button
                      display="flex"
                      onClick={handleContactForm}
                      // variant="Outlined"
                      sx={{
                        textTransform: "unset",
                        // my: 0,
                        mt: 4,
                        backgroundColor: "#C85EEE",
                        "&:hover": {
                          backgroundColor: "#9d49bb",
                        },
                        color: "#FFFFFF",
                        width: 250,
                        height: 67,
                        borderRadius: 35,
                        fontSize: "1.3rem",
                        "@media (min-width:600px)": {
                          fontSize: "1.7rem",
                          mt: 4,
                          width: 304,
                          height: 86,
                        },
                        fontFamily: "Century Gothic",
                        fontWeight: "Bold",
                        background:
                          "linear-gradient(to right, #9b29ff, #c85eee)",
                      }}
                    >
                      Contactformulier
                    </Button>
                  </Box>
                </Box>
              </Collapse>
              <Collapse in={contactFormVisible}>
                <form ref={form} onSubmit={sendEmail}>
                  <TextField
                    name="from_name"
                    variant="outlined"
                    sx={{
                      m: "auto",
                      mb: 1,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                      mt: { xs: 8 },
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Voornaam + Achternaam"
                  ></TextField>
                  <TextField
                    name="company_name"
                    sx={{
                      m: "auto",
                      mb: 1,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Uw bedrijfsnaam"
                  ></TextField>
                  <TextField
                    name="country"
                    sx={{
                      m: "auto",
                      mb: 1,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Land"
                  ></TextField>
                  <TextField
                    name="phone"
                    type="tel"
                    sx={{
                      m: "auto",
                      mb: 1,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Telefoonnummer"
                  ></TextField>
                  <TextField
                    name="email"
                    type="email"
                    sx={{
                      m: "auto",
                      mb: 1,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Email"
                  ></TextField>
                  <TextField
                    name="message"
                    multiline
                    rows={4}
                    sx={{
                      m: "auto",
                      mb: 0,
                      maxWidth: "100%",
                      width: "100%",
                      boxShadow: 4,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder="Uw bericht"
                  ></TextField>
                  <Typography
                    sx={{
                      mt: 3,
                      color: succesLabelVisible
                        ? "green"
                        : errorLabelVisible
                        ? ""
                        : null,
                      fontSize: "18px",
                      lineHeight: "24px",
                      fontFamily: "HelveticaNeue-Light",
                      textAlign: "center",
                    }}
                  >
                    {succesLabelVisible
                      ? "Uw bericht is succesvol verzonden. U krijgt z.s.m. van ons bericht."
                      : errorLabelVisible
                      ? "Het is niet gelukt om uw bericht te versturen. Probeert u het later nog eens of neem direct telefonisch contact met ons op."
                      : null}
                  </Typography>
                  <Box
                    container
                    display="flex"
                    direction="column"
                    justifyContent="center"
                  >
                    <Button
                      onClick={
                        contactFormVisible ? sendEmail : handleContactForm
                      }
                      // variant="Outlined"
                      sx={{
                        textTransform: "unset",
                        // my: 0,
                        mt: 4,
                        backgroundColor: "#C85EEE",
                        "&:hover": {
                          backgroundColor: "#9d49bb",
                        },
                        color: "#FFFFFF",
                        width: 250,
                        height: 67,
                        borderRadius: 35,
                        fontSize: "1.3rem",
                        "@media (min-width:600px)": {
                          fontSize: "1.7rem",
                          mt: 4,
                          width: 304,
                          height: 86,
                        },
                        fontFamily: "Century Gothic",
                        fontWeight: "Bold",
                        background:
                          "linear-gradient(to right, #9b29ff, #c85eee)",
                      }}
                      disabled={submitButtonDisabled ? true : false}
                    >
                      Verstuur
                    </Button>
                  </Box>
                </form>
              </Collapse>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                mt={5}
                display="flex"
                sx={{
                  position: "relative",
                  // p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  p: 0,
                }}
              ></Box>
            </Grid>
          </Grid>
          <Grid container></Grid>
        </Container>
      </Container>
    </>
  );
};

export default BannerContactEmail;
