import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from "@mui/icons-material/Adb";
import Logo from "../assets/logo_white.png";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";

// const pages = ["Homepage"];
// const pages = ["Diensten", "Pricing", "Nieuws", "Over Delta", "Contact"];
const pages = [
  {
    name: "Diensten",
    url: "/diensten",
    subMenu: [
      { name: "Delta Petrol", url: "DeltaPetrol" },
      { name: "Delta Hybrid", url: "DeltaHybrid" },
      { name: "Delta Electric", url: "DeltaElectric" },
      { name: "Delta Diesel", url: "DeltaDiesel" },
    ],
  },
  { name: "Accijns vergoedingen", url: "/DieselRates", subMenu: [] },
  { name: "Nieuws", url: "/News", subMenu: [] },
  { name: "Over Delta", url: "/OverDelta", subMenu: [] },
  { name: "FAQ", url: "/FAQ", subMenu: [] },
  { name: "Contact", url: "/Contact", subMenu: [] },
];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logoOnClick = (e) => {
    navigate("/Home");
  };

  return (
    <AppBar sx={{ background: "#2B5BAA", opacity: 0.98 }} position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ height: 80 }}>
          {/* Mobile screen menu */}
          <Box
            component="img"
            sx={{
              height: 42,
              mr: 1,
              cursor: "pointer",
              display: { xs: "flex", md: "none" },
            }}
            alt="Delta Refund Solutions"
            src={Logo}
            onClick={logoOnClick}
            justifyContent="right"
          />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
            justifyContent="right"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) =>
                page.subMenu.length > 0 ? (
                  <>
                    <MenuItem key={page.name}>
                      <Typography
                        sx={{
                          m: 0,
                          p: 0,
                          // minWidth: "200px",
                          color: "#6b6a6a",
                          textAlign: "left",
                          fontSize: "20px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Bold",
                          textTransform: "none",
                          "a:visited": {
                            color: "#6b6a6a",
                          },
                          a: {
                            textDecoration: "none",
                            color: "#6b6a6a",
                          },
                        }}
                      >
                        <div>
                          {page.name}
                          <FiChevronDown />
                        </div>
                      </Typography>
                    </MenuItem>
                    <ul>
                      {page.subMenu.map((subMenuItem) => (
                        <li onClick={handleCloseNavMenu}>
                          <Typography
                            sx={{
                              pr: 4,
                              color: "#6b6a6a",
                              textAlign: "left",
                              fontSize: "20px",
                              fontFamily: "Century Gothic",
                              fontWeight: "Bold",
                              textTransform: "none",
                              "a:visited": {
                                color: "#6b6a6a",
                              },
                              a: {
                                textDecoration: "none",
                                color: "#6b6a6a",
                              },
                            }}
                          >
                            <Link to={subMenuItem.url}>{subMenuItem.name}</Link>
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : (
                  <>
                    <MenuItem key={page.name}>
                      <Typography
                        sx={{
                          color: "#6b6a6a",
                          textAlign: "center",
                          fontSize: "20px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Bold",
                          textTransform: "none",
                          "a:visited": {
                            color: "#6b6a6a",
                          },
                          a: {
                            textDecoration: "none",
                            color: "#6b6a6a",
                          },
                        }}
                        onClick={handleCloseNavMenu}
                      >
                        <Link to={page.url}>{page.name}</Link>
                      </Typography>
                    </MenuItem>
                  </>
                )
              )}
            </Menu>
          </Box>

          {/* Full screen menu */}
          <Box
            component="img"
            sx={{
              height: 49,
              mr: 20,
              cursor: "pointer",
              display: { xs: "none", md: "flex" },
            }}
            alt="Delta Refund Solutions"
            src={Logo}
            onClick={logoOnClick}
          />
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) =>
              page.subMenu.length > 0 ? (
                <>
                  <Menu
                    sx={{ mt: "45px", borderRadius: "0px" }}
                    id="Producten"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {page.subMenu.map((subMenuItem) => (
                      <MenuItem>
                        <Link
                          style={{
                            color: "inherit",
                            textDecoration: "inherit",
                          }}
                          onClick={handleCloseUserMenu}
                          to={subMenuItem.url}
                        >
                          {subMenuItem.name}
                        </Link>
                      </MenuItem>
                    ))}
                  </Menu>
                  <Typography
                    onClick={handleOpenUserMenu}
                    sx={{
                      my: 2,
                      ml: 1,
                      mr: 5,
                      cursor: "pointer",
                      color: "#FFFFFF",
                      display: "block",
                      textAlign: "center",
                      fontSize: "20px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      textTransform: "none",
                      textDecoration: "none",
                      "a:visited": {
                        color: "#FFFFFF",
                      },
                      a: {
                        textDecoration: "none",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <div>
                      {page.name}
                      <FiChevronDown />
                    </div>
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    my: 2,
                    ml: 1,
                    mr: 5,
                    cursor: "pointer",
                    color: "#FFFFFF",
                    display: "block",
                    textAlign: "center",
                    fontSize: "20px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                    textTransform: "none",
                    textDecoration: "none",
                    "a:visited": {
                      color: "#FFFFFF",
                    },
                    a: {
                      textDecoration: "none",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <Link
                    style={{
                      color: "inherit",
                      textDecoration: "inherit",
                    }}
                    to={page.url}
                  >
                    {page.name}
                  </Link>
                </Typography>
              )
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
