import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle2 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Achtergrond van accijnsvergoedingen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage2.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                17.03.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Is het nog interessant om in België te tanken en hoe werkt het
              cliquet systeem in België nou precies?
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              We zitten in de EU met 26 andere lidstaten. Toch compenseert niet
              iedere lidstaat de transporteur voor een deel van de aan de pomp
              betaalde accijnzen. Hoe zit dat nou precies? Delta licht u in
              <span style={{ color: "#9c29ff" }}>
                {" "}
                3 opeenvolgende stappen{" "}
              </span>
              in over hoe dat precies werkt.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Om te beginnen in Europa en in het bijzonder in de Europese Unie.
              Zoals u wellicht al weet zijn er een aantal EU-lidstaten die een
              vergoeding aan de professionele transporteur op de accijnzen
              toekent. Momenteel zijn dat de landen:
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                België, Frankrijk, Hongarije, Spanje, Slovenië, Kroatië en
                Italië
              </span>
              . Eén van de basisbeginselen van de EU verdragen is dat er geen
              protectionistische maatregelen mogen worden genomen door de
              afzonderlijke lidstaten, waarbij een verbod op bevoordelen van
              eigen inwoners en bedrijven geldt. Daarnaast harmoniseert de EU zo
              veel mogelijk belastingen en tarieven. Dat zijn namelijk ook
              instrumenten om protectionisme te bewerkstelligen, hetgeen dus
              onwenselijk is. Immers, lidstaten concurreren onderling namelijk
              ook. Een verlaging van accijnzen in een lidstaat zou ertoe kunnen
              leiden dat het tankgedrag verandert, waarmee er meer consumptie in
              dat land zal plaatsvinden en dus{" "}
              <span style={{ color: "#9c29ff" }}>
                {" "}
                meer accijnsinkomsten voor de staat
              </span>
              . Dat is dus in beginsel onwenselijk. Toch heeft men in
              <span style={{ color: "#2B5BAA" }}> 2004 </span>in onderling
              overleg tussen de lidstaten besloten om een uitzondering te maken
              op deze regel en de lidstaten dus de vrijheid te geven om een
              compensatie te geven voor de hoge brandstofprijzen. Het is hiermee
              <span style={{ color: "#9c29ff" }}> vrij aan de landen </span>om
              een compensatieregeling van de accijnzen aan te bieden aan de
              transportsector. De landen die de compensatie bieden beogen
              hiermee een hogere consumptie van de dieselolie en daarmee hogere
              accijnsinkomsten en daarmee een hogere bijdrage aan de schatkist.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Het feit dat er landen zijn die wel andere landen die niet gebruik
              maken van enige compensatie regeling, bewijst dat we in alle
              lidstaten niet allemaal hetzelfde denken over de manier van vullen
              van de schatkist.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Hoe de bedragen worden vastgesteld door bijvoorbeeld België en wat
              de toekomst is leest u in het volgende artikel.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle2;
