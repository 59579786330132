import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsContent = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Vernieuwde duurzame benzine & Diesel nu verkrijgbaar in Duitsland
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage1x2.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                01.03.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Er zijn twee nieuwe emissie verlagende brandstoffen recent op de
              markt gebracht door Aral. Het gaat hier om
              <span style={{ color: "#9c29ff" }}> Aral Futura Super 95 </span>
              en <span style={{ color: "#9c29ff" }}> Aral Futura Diesel</span>.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              In vergelijking met pure fossiele brandstoffen, verminderen deze
              nieuwe varianten de CO2 uitstoot met minstens
              <span style={{ color: "#2b5baa" }}> 25%</span>, en bevatten ze
              minstens<span style={{ color: "#2b5baa" }}> 30% </span>
              hoogwaardige hernieuwbare componenten. Dit is technisch mogelijk
              gemaakt door het bijmengen van recyclebare componenten, waardoor
              ze nog steeds voldoen aan de huidige normen van DIN EN 590
              (diesel) en DIN EN 228 (benzine).
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              De vernieuwde brandstoffen zijn momenteel beschikbaar voor klanten
              op één Aral-station in
              <span style={{ color: "#2b5baa" }}> Berlijn </span>en één in
              <span style={{ color: "#2b5baa" }}> Düsseldorf</span>.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Naast dat het bijdraagt aan de klimaatdoelstellingen is de
              brandstof ook goed voor de verzorging van de motor. Het verwijdert
              namelijk bestaand vuil van kritieke motoronderdelen en beschermt
              tegen ophoping ervan.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsContent;
