import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle33 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Naderende deadline voor BTW-teruggaafverzoeken in het Verenigd Koninkrijk 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage33.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                16-11-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            De deadline{" "}
            </span>
            voor het{" "} 
            <span style={{color: "#2b5baa"}}>
            BTW-teruggaafverzoek{" "}
            </span>
            in het{" "}
            <span style={{color: "#2b5baa"}}>
            Verenigd Koninkrijk
            </span>
            nadert snel voor bedrijven die niet in het Verenigd Koninkrijk zijn gevestigd. In tegenstelling tot de BTW-teruggaafverzoeken in EU-landen, die op 30 september moeten zijn ingediend, kan de{" "}
            <span style={{color: "#9c29ff"}}>
            de deadline voor BTW-teruggaafverzoeken in non-EU-landen variëren
            </span>
            . Voor het Verenigd Koninkrijk is deze deadline {" "}
            <span style={{color: "#2b5baa"}}>
            31 december
            </span>
            .  Bovendien verschilt de jaarlijkse teruggaafverzoek-periode in het Verenigd Koninkrijk van die in EU-landen.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In tegenstelling tot EU-landen, waar alle facturen van het voorgaande jaar worden meegenomen in de jaarlijkse teruggaafverzoeken (suppletieverzoek), is het{" "}
            <span style={{ color: "#9c29ff" }}>
            tijdsbestek voor facturen in de jaarlijkse teruggaafverzoeken in het Verenigd Koninkrijk vrij asymmetrisch
            </span>
            . Facturen die vóór 31 december 2023 kunnen worden ingediend, betreffen de periode van{" "}
            <span style={{ color: "#2b5baa" }}>
            1 juli 2022 tot en met 30 juni 2023
            </span>
            , oftewel het tweede halfjaar 2022 en het eerste halfjaar 2023.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hier zijn de feiten nogmaals samengevat:
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Drempelwaarde voor teruggaafverzoek-bedrag: £16 
                        </li>
                        <li>   
                        Teruggaafverzoeken via het Engelse belastingportaal 
                        </li>
                        <li> 
                        Bonnetjes zijn toegestaan 
                        </li>
                        <li> 
                        Tijdsbestek: van 1 juli 2022 tot en met 30 juni 2023 
                        </li>
                        <li> 
                        Deadline: 31 december 2023 
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Heeft u geen ervaring met de BTW-teruggaafverzoeken in het Verenigd Koninkrijk, maar hebt u meer dan £16 terug te vorderen en hulp nodig? Neem gerust{" "}
            <span style={{ color: "#9c29ff" }}>
            <a href="https://deltarefundsolutions.com/Contact">contact</a>{" "}
            </span>
            met ons op en we zullen samen kijken wat we kunnen doen voor u. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle33;
