import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
// import Image from "mui-image";
import background1 from "../../assets/BelOns.jpg";
import background2 from "../../assets/BelOnsOverlay.jpg";
// import liveInzicht from "../../assets/Live inzicht icoontje.png";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";

import Button from "@mui/material/Button";
import { Container } from "@mui/system";
// import { useNavigate } from "react-router-dom";

const ContactBanner = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          // color: "#fff",
          m: 0,
          p: 0,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: {
            xs: `url(${background2})`,
            md: `url(${background2})`,
            lg: `url(${background1})`,
          },
          backgroundPosition: "center",
          borderRadius: "0px",
          boxShadow: "none",
          height: { xs: "650px", md: "902px", lg: "902px" },
          width: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Typography
            sx={{
              mt: 5,
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "2.8rem",
              "@media (min-width:600px)": {
                mt: 5,
                fontSize: "4.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
            }}
          >
            Contact
          </Typography>
          <Typography
            mb={7}
            sx={{
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "1rem",
              "@media (min-width:600px)": {
                mt: 0,
                fontSize: "1.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Regular",
            }}
          >
            Bel- , bericht- of bezoek ons. Wij staan voor u klaar!
          </Typography>
        </Container>
        <Container maxWidth="xl">
          <Paper
            // maxWidth="xl"
            // sx={{
            //   position: "relative",
            //   // color: "#fff",
            //   mt: 1,
            //   pb: 0,
            //   backgroundSize: "contain",
            //   backgroundRepeat: "no-repeat",
            //   backgroundPosition: "right bottom",
            //   backgroundImage: `url(${background1})`,
            //   borderRadius: "0px",
            //   boxShadow: "none",
            // }}
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
          >
            {/* Increase the priority of the hero background image */}
            {<img style={{ display: "none" }} src={background1} alt="Text" />}
            <Box
              border="none"
              sx={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                // backgroundColor: "rgba(0,0,0,.3)",
              }}
            />
            <Grid container>
              <Grid item lg={6} md={12}>
                <Box
                  sx={{
                    position: "relative",
                    // p: { xs: 3, md: 6 },
                    pr: { md: 0 },
                    p: 0,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: "2.8rem",
                      "@media (min-width:600px)": {
                        mt: 1,
                        fontSize: "4.4rem",
                      },
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Bel ons
                  </Typography>
                  <Typography
                    sx={{
                      mt: 3,
                      color: "#6B6A6A",
                      textAlign: "left",
                      fontSize: "1.4rem",
                      "@media (min-width:600px)": {
                        mt: 5,
                        fontSize: "2.5rem",
                      },
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                    }}
                  >
                    Wij zijn maandag t/m vrijdag tussen{" "}
                    <span style={{ color: "#9C29FF" }}>8:30 en 16:30 </span>
                    telefonisch bereikbaar.
                  </Typography>
                  {/* <Typography
                    sx={{
                      mt: 3,
                      color: "#6B6A6A",
                      textAlign: "left",
                      fontSize: "1.4rem",
                      "@media (min-width:600px)": {
                        mt: 5,
                        fontSize: "2.5rem",
                      },
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "light",
                    }}
                  >
                    Wilt u liever teruggebeld worden op een ander tijdstip?
                    <br />
                    <span style={{ color: "#2B5BAA" }}>
                      <a href="/">Laat ons u terugbellen!</a>
                    </span>
                  </Typography> */}
                  <Box container direction="column">
                    <Button
                      display="flex"
                      // onClick={handleCalculateNow}
                      // variant="Outlined"
                      sx={{
                        textTransform: "unset",
                        // my: 0,
                        mt: 7,
                        backgroundColor: "#C85EEE",
                        "&:hover": {
                          backgroundColor: "#9d49bb",
                        },
                        color: "#FFFFFF",
                        width: 250,
                        height: 67,
                        borderRadius: 35,
                        fontSize: "1.3rem",
                        "@media (min-width:600px)": {
                          fontSize: "1.7rem",
                          mt: 10,
                          width: 304,
                          height: 86,
                        },
                        fontFamily: "Century Gothic",
                        fontWeight: "Bold",
                        background:
                          "linear-gradient(to right, #9b29ff, #c85eee)",
                      }}
                    >
                      +31 85 808 55 22
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Container>
    </>
  );
};

export default ContactBanner;
