import React from "react";
import NewsArticle41 from "../elements/NewsArticle41";
import NewsNav from "../elements/NewsNav";

const NewsDetails41 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle41 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails41;