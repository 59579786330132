import React from "react";
import BannerPricingDeltaDiesel from "../elements/BannerPricingDeltaDiesel";
import InfoCards from "../elements/InfoCards";
import MostAskedQuestions from "../elements/MostAskedQuestions";
import PricingTable from "../elements/PricingTable";
import ProductNav from "../elements/ProductNav";
// import QuestionsDeltaDiesel from "../elements/QuestionsDeltaDiesel";
// import Questions from "../elements/QuestionsDeltaPetrol";
import Werkwijze from "../elements/Werkwijze";
import Banner04 from "../pages/Banner04";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const DeltaDiesel = () => {
  const questions = [
    {
      question: "Is er voorfinanciering mogelijk over diesel accijnzen? ",
      answer:
        "Op belgische dieselaccijnzen is er de mogelijkheid van voorfinanciering. Vraag naar onze tarieven.",
    },
    {
      question: "Is Delta Diesel te combineren met BTW producten?",
      answer:
        "U dient te kiezen uit de 3 BTW producten (Delta Petrol, Delta Hybrid of Delta Electric). In veel gevallen wordt dat gecombineerd met de dieselolieaccijns teruggaaf (Delta Diesel).",
    },
    {
      question: "Welke documenten moet ik aanleveren?",
      answer:
        "Wij streven naar een zo zorgeloos mogelijke accijns teruggaaf voor u. Wij zorgen ervoor dat alle nodige documenten gereed gemaakt worden zodat u in aanmerking komt met accijns teruggaaf.",
    },
    {
      question: "Waarom een tarifering op abonnementsbasis?",
      answer:
        "Met een abonnement weet u waar u elke maand aan toe bent en leidt tot stabiele cashflow. Een abonnement is ook iets voordeliger geprijsd ten opzichte van de traditionele no-cure-no-pay methode.",
    },
    {
      question: "Wat is de looptijd van een abonnement?",
      answer: "Dat varieert van 1 tot 5 jaar.",
    },
  ];
  return (
    <>
      <ProductNav deltaDieselActive />
      <Banner04 />
      <InfoCards deltaDieselActive />
      <Werkwijze deltaDieselActive />
      <BannerPricingDeltaDiesel />
      <MostAskedQuestions Questions={questions} />
      <PricingTable deltaDieselActive />
    </>
  );
};

export default DeltaDiesel;
