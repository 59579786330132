import React from "react";
import BannerPricingDeltaElectric from "../elements/BannerPricingDeltaElectric";
// import BannerPricing from "../elements/BannerPricingDeltaPetrol";
import InfoCards from "../elements/InfoCards";
import MostAskedQuestions from "../elements/MostAskedQuestions";
import PricingTable from "../elements/PricingTable";
import ProductNav from "../elements/ProductNav";
// import QuestionsDeltaElectric from "../elements/QuestionsDeltaElectric";
// import Questions from "../elements/QuestionsDeltaPetrol";
import Werkwijze from "../elements/Werkwijze";
import Banner03 from "../pages/Banner03";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const DeltaElectric = () => {
  const questions = [
    {
      question: "Waarom zou ik voor Delta Electric kiezen ipv. Hybrid?",
      answer:
        "Indien uw bedrijf groeit, dan is de behoefte aan liquiditeit over het algemeen groter. Daarbij past een permanente en constante geldstroom, waardoor Delta Electric beter bij u past. Als u af en toe behoefte heeft om de liquiditeit op orde te brengen, dan zou Delta Hybrid wellicht de beste keuze zijn.",
    },
    {
      question: "Hoe snel heb ik bij Delta Electric voorfinanciering?",
      answer:
        "Afhankelijk van de door u gewenste financieringsfrequentie (per factuur/per maand/ per kwartaal) maken wij het gewenste bedrag binnen 10 werkdagen over.",
    },
    {
      question: "Waarom een tarifering op abonnementsbasis?",
      answer:
        "Met een abonnement weet u waar u elke maand aan toe bent en leidt tot stabiele cashflow. Een abonnement is ook iets voordeliger geprijsd ten opzichte van de traditionele no-cure-no-pay methode. Let op, bij Delta Electric komt er voor voorfinanciering een toegevoegde fee over de BTW som in de vorm van een percentage die gevormd wordt door meerdere factoren. Vraag naar onze tarieven.",
    },
    {
      question: "Wat is de looptijd van een abonnement?",
      answer: "Dat varieert van 1 tot 5 jaar.",
    },
    {
      question: "Kan ik later nog switchen van dienst/product?",
      answer: "Ja, dat is geen probleem.",
    },
  ];
  return (
    <>
      <ProductNav deltaElectricActive />
      <Banner03 />
      <InfoCards deltaElectricActive />
      <Werkwijze deltaElectricActive />
      <BannerPricingDeltaElectric />
      <MostAskedQuestions Questions={questions} />
      <PricingTable deltaElectricActive />
    </>
  );
};

export default DeltaElectric;
