import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle3 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Belgische Accijns Compensatie (Professionele Diesel), hoe wordt
              dat berekend?
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage3.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                23.03.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             
              Op willekeurige tijdstippen kondigt de Belgische overheid via het Belgisch staatsblad aan dat 
              het compensatiebedrag van de Belgische dieselolie accijns verandert. Laatstelijk in het Belgisch 
              staatsblad van 29-12-2023. Hoe zit dat nou eigenlijk en vooral, hoe worden de accijns vergoedingen 
              in België vastgesteld? 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Dat begint bij de periodieke vaststelling door de Belgische staat van het totale accijnstarief per 
              liter diesel (Bijzondere Accijnzen). Ter verduidelijking, de Bijzondere Accijnzen betreft niet de 
              vergoeding aan u (de compensatie), maar het bedrag dat de Belgische staat per liter diesel incasseert. Op
              <span style={{ color: "#2B5BAA" }}> 31 maart 2023 (momenteel laatste wijziging) </span>
              is door de Belgische staat de
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                Bijzondere Accijnzen op 37,2781 ct per liter vastgesteld
              </span>
              . Dat tarief is dus geen percentage, maar een vast bedrag per
              liter.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Waar is die vaststelling op gebaseerd? Als de dieselprijzen in de internationale markt lager worden 
            (prijs per vat olie), dan kan de Belgische staat overgaan tot aanpassing van de Bijzondere Accijnzen. 
            Is de prijs erg volatiel (veel fluctuaties), dan volgen er dus ook veel aanpassingen in de Bijzondere 
            Accijnzen. Het kan dus voorkomen dat de Belgische staat het accijns bedrag per liter juist verlaagt als 
            de dieselprijs stijgt, teneinde consument en bedrijven te beschermen voor al te grote brandstofprijs 
            verhogingen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Daarnaast wordt ook
              <span style={{ color: "#9c29ff" }}>
                {" "}
                periodiek een drempelbedrag per liter vastgesteld
              </span>
              , waarboven transportbedrijven een vergoeding (compensatie)
              krijgen.
              <span style={{ color: "#9c29ff" }}> Vanaf 1 januari 2024 </span>is
              dat alles boven de
              <span style={{ color: "#9c29ff" }}> 17,9281 ct per liter</span>.
              Het verschil (37,2781 ct - 17,9281 ct =) 19,35 ct per liter
              geeft het compensatie- of teruggaaf bedrag (cliquet systeem). Het
              compensatiebedrag fluctueert dus mee met de nieuwe vaststelling
              van de Bijzondere Accijnzen. Het kan dus voorkomen dat de
              Belgische staat bij stijgende dieselprijzen de Bijzondere
              Accijnzen verlaagt, waarmee de vergoedingen dus ook verlaagd
              wordt. Omgekeerd kan ook, lagere brandstofprijzen, hogere
              bijzondere accijnzen en een hogere vergoeding aan de transporteur.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Delta houdt voor u de vastgestelde bedragen in de gaten.
              Consulteer van tijd tot tijd onze website, teneinde op de hoogte
              te blijven van de accijnsvergoedingen. Laatste nieuws is dat het compensatiebedrag {" "}
              <span style={{ color: "#9c29ff" }}>
              per getankte liter dus 19,35 ct is
              </span>
              . De moeite waard om het tankgedrag weer eens onder de loep te nemen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Volgende artikel meer over onze voorspelling hoe lang de
              compensatie regeling in stand zal blijven.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle3;
