import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle35 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Verhoging van CO2 toeslag in Oostenrijk vanaf 2024
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage35.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                30-11-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Oostenrijkse tol zal vanaf {" "}
            <span style={{color: "#2b5baa"}}>
            1 januari 2024{" "}
            </span>
            stijgen als het land de nieuwe CO2-heffingen invoert. Hoeveel deze toeslag is hangt af van de{" "} 
            <span style={{color: "#9c29ff"}}>
            Euro-klasse{" "}
            </span>
            van het desbetreffende voertuig. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf 1 januari 2024 zal Oostenrijk de nieuwe EU-richtlijn inzake infrastructuurkosten implementeren door wijzigingen aan te brengen in de federale{" "}
            <span style={{ color: "#9c29ff" }}>
            wegentolwet{" "}
            </span>
            en de{" "}
            <span style={{ color: "#9c29ff" }}>
            Asfinagwet
            </span>
            . Deze wijzigingen houden in dat naast de infrastructuurkosten en C02-uitstoot, ook nog wordt gekeken naar {" "}
            <span style={{ color: "#9c29ff" }}>
            lucht- en geluidsvervuiling
            </span>
            . Deze aanpassingen stellen de Oostenrijkse regering in staat de impact van voertuigemissies op de infrastructuurkosten vollediger in overweging te nemen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>
            De kosten van de tol{" "}
            </span>
            zijn afhankelijk van de{" "} 
            <span style={{ color: "#2b5baa" }}>
            afgelegde afstand, het aantal assen, de emissieklasse van de motor en de CO2-uitstoot van de motor.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                          
                            Tariefgroep:
                          
                        </span> 
                    </td>
                    <td>
                        <span>
                            Twee assen:
                        </span>
                    </td>
                    <td>
                        <span>
                            Drie assen:
                        </span>
                    </td>
                    <td>
                        <span>
                            Van vier assen:
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li>E: Elektrisch/waterstof</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            5,38
                        </span>
                    </td>
                    <td>
                        <span>
                            7,41
                        </span>
                    </td>
                    <td>
                        <span>
                            10,97
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li>A: Euro 6</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            22,78
                        </span>
                    </td>
                    <td>
                        <span>
                            31,77
                        </span>
                    </td>
                    <td>
                        <span>
                            47,30
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li>B: Euro 5 en EEV</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            24,08
                        </span>
                    </td>
                      <td>
                        <span>
                            33,77
                        </span>
                    </td>
                    <td>
                        <span>
                            49,90
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li>C: Euro 4</li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            25,68
                        </span>
                    </td>
                    <td>
                        <span>
                            35,57
                        </span>
                    </td>
                    <td>
                        <span>
                            52,50
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span style={{ color: "#2b5baa" }}>
                           
                            <li>Euro 0 t/m 3 </li>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                            27,08
                        </span>
                    </td>
                    <td>
                        <span>
                            37,67
                        </span>
                    </td>
                    <td>
                        <span>
                            55,40
                        </span>
                    </td>
                 </tr>
                 </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: -5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor voertuigen geregistreerd vanaf 1 juli 2019 kan de bijbehorende betere CO2-emissieklasse worden bepaald door de overeenkomstige waarden in te voeren in de {" "}
            <span style={{ color: "#2b5baa" }}>
            Asfinag-calculator
            </span>
            , die de snelwegbeheerder online heeft gezet. Voor deze voertuigen is het mogelijk om voor de CO2 -prijs een{" "}
            <span style={{ color: "#9c29ff" }}>
            goedkoper toltarief te realiseren.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Voor de controle zijn drie waarden vereist: 
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Datum van eerste registratie (registratiedocumenten)
                        </li>
                        <li>   
                        Subgroep voertuigen
                        </li>
                        <li> 
                        Specifieke CO2-emissiewaarde
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            <span style={{ color: "#9c29ff" }}>Hogere tol op de snelwegen A13 en A12 tussen Kufstein en Innsbruck: </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Om de bouw van de Brenner-basistunnel mede te financieren, wordt een{" "}
            <span style={{ color: "#9c29ff" }}>
            toeslag van maximaal 25%{" "}
            </span>
            van de basiskilometer geheven op de Brenner-snelweg A13 en de Inntal-snelweg A12 vanaf de staatsgrens bij Kufstein tot Innsbruck/ Amras-knooppunt. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Kortom vanaf januari 2024 zullen de kosten voor goederenvervoer in Oostenrijk flink stijgen. Deze wijzigingen weerspiegelen een groeiende druk op{" "}
            <span style={{ color: "#9c29ff" }}>
            milieubewustzijn en duurzaamheid
            </span>
            . Transportbedrijven zullen vroeg of laat hun strategieën moeten aanpassen om de nadelen van de nieuwe regelgevingen te verminderen en tegelijkertijd efficiënte transportoplossingen te bevorderen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle35;
