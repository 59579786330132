import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle39 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Verhoging van toltarieven in Polen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage39.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                18-01-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Per {" "}
            <span style={{color: "#2b5baa"}}>
            1 januari 2024 {" "}
            </span>
            zijn de toltarieven op Poolse nationale tolwegen voor zware voertuigen, bekend als de elektronische tol, aanzienlijk gestegen 
            volgens het Poolse Elektronische Tolheffingssysteem. De stijging bedraagt tussen de {" "}
            <span style={{color: "#9c29ff"}}>
            15 en 16%.  {" "}
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Per heden zullen er{" "}
            <span style={{color: "#2b5baa"}}>
            twee tariefstructuren{" "}
            </span>
            zijn op basis van{" "}
            <span style={{color: "#9c29ff"}}>
            de klasse van de nationale weg
            </span>
            .  De verdeling is gemaakt tussen wegen van{" "}
            <span style={{color: "#2b5baa"}}>
            Klasse A & S{" "}
            </span>
             en {" "}
             <span style={{color: "#9c29ff"}}>
             klasse GP & G.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De tarieven vanaf 1 januari 2024 {" "}
            <span style={{color: "#2b5baa"}}>
            per kilometer{" "}
            </span>
            voor de wegklasse{" "}
            <span style={{color: "#2b5baa"}}>
            A & S{" "}
            </span>
            (PLN):
            <table cellpadding="10" cellspacing="0"> 
                <tbody>
                    <ul>
                 <tr>
                    <td>
                       {" "}
                    </td>
                    <td width="15%">
                        <span style={{color: "#2b5baa"}}>
                            Euro 2 of lager:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#2b5baa"}}>
                            Euro 3:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#2b5baa"}}>
                            Euro 4:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#2b5baa"}}>
                            Euro 5 of hoger:
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                            Voertuigen met een maximaal gewicht tussen de 3,5 t en 12 t:
                        </span> 
                    </td>
                    <td>
                        <span>
                            € 0,55
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,48
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,39
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,28
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                           
                        Voertuigen met een maximaal gewicht van 12 t of hoger:
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                           € 0,73
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,63
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,51
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,38
                        </span>
                    </td>
                 </tr>
                 </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
                       De tarieven vanaf 1 januari 2024 {" "}
            <span style={{color: "#9c29ff"}}>
            per kilometer{" "}
            </span>
            voor de wegklasse{" "}
            <span style={{color: "#9c29ff"}}>
            GP & G{" "}
            </span>
            (PLN):
            <table cellpadding="10" cellspacing="0"> 
                <tbody>
                    <ul>
                 <tr>
                    <td>
                       {" "}
                    </td>
                    <td width="15%">
                        <span style={{color: "#9c29ff"}}>
                            Euro 2 of lager:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#9c29ff"}}>
                            Euro 3:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#9c29ff"}}>
                            Euro 4:
                        </span>
                    </td>
                    <td width="15%">
                    <span style={{color: "#9c29ff"}}>
                            Euro 5 of hoger:
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                            Voertuigen met een maximaal gewicht tussen de 3,5 t en 12 t:
                        </span> 
                    </td>
                    <td>
                        <span>
                            € 0,45
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,39
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,31
                        </span>
                    </td>
                    <td>
                        <span>
                            € 0,23
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                           
                        Voertuigen met een maximaal gewicht van 12 t of hoger:
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                           € 0,57
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,51
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,41
                        </span>
                    </td>
                    <td>
                        <span>
                           € 0,29
                        </span>
                    </td>
                 </tr>
                 </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Indien u regelmatig door Polen reist, houd er dan rekening mee dat u meer zult uitgeven aan tolheffingen. 
            Voor meer informatie over deze vernieuwde regeling, bezoek de:{" "}
            <a href="https://etoll.gov.pl/en/collateral-amount-calculator-2024/"> Collateral amount calculator 2024</a>.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle39;
