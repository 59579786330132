import {
  // Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";

import { Box } from "@mui/system";
// import { height } from "@mui/system";

import PictureCallMaartje from "../../assets/PictureCallMaartje.png";
import emailjs from "@emailjs/browser";

const ContactCallMail = () => {
  const form = useRef();
  const [succesLabelVisible, setSuccesLabelVisible] = React.useState(false);
  const [errorLabelVisible, setErrorLabelVisible] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);

  const sendEmail = (e) => {
    setSubmitButtonDisabled(true);
    // e.preventDefault();
    // var hasError;
    emailjs
      .sendForm(
        "service_pcmrdxj",
        "template_81qf0c6",
        form.current,
        "_yi0_0d3ScPyf_1Dl"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(succesLabelVisible);
          setSuccesLabelVisible(true);
          console.log(succesLabelVisible);
        },
        (error) => {
          console.log(error.text);
          setErrorLabelVisible(true);
        }
      );
  };

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ m: 0, p: 0, mt: 2, backgroundColor: "#FAFCFF" }}
      >
        <Container maxWidth="xl" sx={{ mt: 10, mb: 0, pb: 2 }}>
          <Grid container>
            <Grid md={6} xs={12}>
              <Typography
                sx={{
                  mt: 3,
                  fontSize: { xs: "24px", md: "30px", lg: "40px" },
                  lineHeight: { xs: "34px", md: "40px", lg: "50px" },
                  fontFamily: "HelveticaNeue-Light",
                  color: "#6b6a6a",
                  p: 4,
                  a: {
                    color: "#2B5BAA",
                  },
                  "a:visited": {
                    color: "#2B5BAA",
                  },
                }}
              >
                Nog vragen? <br />
                Stel gerust uw vragen hiernaast,
                <br /> mail ons naar:{" "}
                <span style={{ color: "#2b5baa" }}>
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:info@deltarefundsolutions.com"
                  >
                    info@deltarefundsolutions.com
                  </a>
                </span>
                <br />
                of neem telefonisch contact op.
              </Typography>
              <Grid
                sx={{
                  mb: 5,
                  ml: 0,
                  "@media (min-width:899px)": {
                    ml: 1,
                  },
                }}
                md={12}
                xs={12}
                container
              >
                <Grid lg={4} xs={4}>
                  <img
                    src={PictureCallMaartje}
                    alt=""
                    style={{ minWidth: "80%" }}
                  />
                  {/* <Image
                    display="flex"
                    justifyContent="left"
                    sx={{ maxWidth: "150px", justifyContent: "left" }}
                    style={{ justifyContent: "left" }}
                    img
                    src={PictureCallMaartje}
                    alt="cur"

                    // height={132}
                    // width={128}
                  /> */}
                </Grid>
                <Grid lg={8} xs={8}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        pl: 3,
                        fontSize: { xs: "20px", lg: "29px" },
                        fontFamily: "HelveticaNeue-Light",
                        color: "#6b6a6a",
                        a: {
                          color: "#2B5BAA",
                        },
                        "a:visited": {
                          color: "#2B5BAA",
                        },
                      }}
                    >
                      <span style={{ color: "#9c29ff" }}>Liever bellen?</span>
                      <br />
                      Neem nu contact op
                      <br /> met ons!{" "}
                      <a style={{ pb: 3 }} href="tel:+31858085522">
                        +31 85 808 55 22
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid md={6} xs={12}>
              <form ref={form} onSubmit={sendEmail}>
                <Grid align="left" md={12} xs={12} container>
                  <TextField
                    name="message"
                    multiline
                    rows={13}
                    sx={{
                      m: "auto",
                      mt: 8,
                      mb: 0,
                      width: "100%",
                      "@media (min-width:899px)": {
                        ml: 2,
                        mr: 2,
                      },
                      maxWidth: "699px",
                      boxShadow: 3,
                    }}
                    placeholder="Type hier uw vraag!"
                  ></TextField>
                </Grid>
                <Grid
                  container
                  direction="row"
                  item
                  align="left"
                  sx={{ position: "relative" }}
                >
                  <Grid md={12} xs={12} container>
                    <Box
                      sx={{
                        m: "auto",
                        mt: 1,
                        mb: 0,
                        // ml: 0,
                        width: "100%",
                        "@media (min-width:899px)": {
                          ml: 2,
                          mr: 2,
                        },
                        maxWidth: "699px",
                      }}
                    >
                      <Grid md={12} xs={12} container>
                        <Grid md={8} xs={12} container justifyContent="center">
                          <TextField
                            name="email"
                            // inputProps={{
                            //   style: {
                            //     height: "34px",
                            //   },
                            // }}
                            sx={{
                              // position: "absolute",
                              // top: 0,
                              // left: 0,

                              m: "auto",
                              mb: 0,
                              maxWidth: "100%",
                              width: "100%",

                              "@media (min-width:899px)": {
                                maxWidth: "100%",
                                mr: 1,
                              },
                              boxShadow: 3,
                            }}
                            placeholder="Uw e-mailadres"
                          ></TextField>
                        </Grid>
                        <Grid md={4} xs={12} container justify="flex-end">
                          <Button
                            onClick={sendEmail}
                            variant="outlined"
                            sx={{
                              // position: "absolute",
                              // top: 0,
                              // right: 0,
                              m: "auto",
                              // m: 0,
                              mt: 1,
                              height: "55px",
                              width: "100%",
                              maxWidth: "699px",
                              "@media (min-width:899px)": {
                                // m: 0,
                                height: "55px",
                                maxWidth: "100%",
                                ml: 1,
                              },
                              boxShadow: 3,
                            }}
                            disabled={submitButtonDisabled ? true : false}
                          >
                            Verstuur
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Typography
                          sx={{
                            mt: 3,
                            color: succesLabelVisible
                              ? "green"
                              : errorLabelVisible
                              ? ""
                              : null,
                            fontSize: "18px",
                            lineHeight: "24px",
                            fontFamily: "HelveticaNeue-Light",
                            textAlign: "center",
                          }}
                        >
                          {succesLabelVisible
                            ? "Uw bericht is succesvol verzonden. U krijgt z.s.m. van ons bericht."
                            : errorLabelVisible
                            ? "Het is niet gelukt om uw bericht te versturen. Probeert u het later nog eens of neem direct telefonisch contact met ons op."
                            : null}
                        </Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default ContactCallMail;
