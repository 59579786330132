import React from "react";
import NewsArticle56 from "../elements/NewsArticle56";
import NewsNav from "../elements/NewsNav";

const NewsDetails56 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle56 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails56;