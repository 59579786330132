import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import KernwaardenModern from "../../assets/KernwaardenModern2.png";
import KernwaardenFlexibel from "../../assets/KernwaardenFlexibel2.png";
import KernwaardenTransparant from "../../assets/KernwaardenTransparant2.png";
import { Container } from "@mui/system";
import Image from "mui-image";

const CoreValues = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ m: 0, p: 0, backgroundColor: "#FAFCFF" }}
      >
        <Container maxWidth="xl" sx={{ pb: 12 }}>
          <Grid container>
            <Grid item lg={6} xs={0}></Grid>
            <Grid item lg={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  //   pr: { md: 0 },
                  // backgroundColor: "yellow",
                }}
              >
                <Typography
                  sx={{
                    mt: { xs: 5, lg: 8 },
                    mb: { xs: 5, lg: 5 },
                    color: "#2B5BAA",
                    textAlign: { xs: "center", lg: "left" },
                    // fontSize: { xs: "2.8rem", lg: "4.4rem" },
                    fontSize: { xs: "52px", lg: "71px" },
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Kernwaarden
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   mt: { sx: 3, lg: 6 },
                  //   p: { xs: 3, lg: 6 },
                  pr: { md: 0 },
                  maxWidth: { xs: "275px", lg: "450px" },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 2 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                  }}
                  img
                  src={KernwaardenModern}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   p: { xs: 3, md: 6 },
                  //   pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 2,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "32px", lg: "40px" },
                    lineHeight: { xs: "42px", lg: "50px" },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>Modern: </span>bij Delta
                  geniet u van een vlekkeloze, snelle en geautomatiseerde
                  workflow met geen ruimte voor menselijke errors.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   mt: { sx: 3, lg: 6 },
                  //   p: { xs: 3, lg: 6 },
                  pr: { md: 0 },
                  maxWidth: { xs: "275px", lg: "450px" },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 2 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                  }}
                  img
                  src={KernwaardenFlexibel}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   p: { xs: 3, md: 6 },
                  //   pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 2,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "32px", lg: "40px" },
                    lineHeight: { xs: "42px", lg: "50px" },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>Flexibel: </span>u kent uw
                  eigen bedrijf door en door en u weet als geen ander op welk
                  moment een mogelijke liquiditeitskrapte kan worden weggewerkt.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   mt: { sx: 3, lg: 6 },
                  //   p: { xs: 3, lg: 6 },
                  pr: { md: 0 },
                  maxWidth: { xs: "275px", lg: "450px" },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 2 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                  }}
                  img
                  src={KernwaardenTransparant}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  //   p: { xs: 3, md: 6 },
                  //   pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 2,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "32px", lg: "40px" },
                    lineHeight: { xs: "42px", lg: "50px" },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>Transparant: </span>u kunt
                  direct berekenen wat uw prijs is.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default CoreValues;
