import React from "react";
import NewsArticle21 from "../elements/NewsArticle21";
import NewsNav from "../elements/NewsNav";

const NewsDetails21 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle21 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails21;