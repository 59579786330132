import React from "react";
import NewsArticle16 from "../elements/NewsArticle16";
import NewsNav from "../elements/NewsNav";

const NewsDetails16 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle16 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails16;