import React from "react";
import NewsArticle46 from "../elements/NewsArticle46";
import NewsNav from "../elements/NewsNav";

const NewsDetails46 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle46 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails46;