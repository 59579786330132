import { Container, Typography } from "@mui/material";
import React from "react";

const NewsBanner = () => {
  return (
    <>
      <Container maxWidth="100%" sx={{ m: 0, p: 0 }}>
        <Container maxWidth="xl">
          <Typography
            sx={{
              mt: 5,
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "2.8rem",
              "@media (min-width:600px)": {
                mt: 5,
                fontSize: "4.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
            }}
          >
            Nieuws
          </Typography>
          <Typography
            mb={7}
            sx={{
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "1rem",
              "@media (min-width:600px)": {
                mt: 0,
                fontSize: "1.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Regular",
            }}
          >
            De laatste gebeurtenissen in de transportsector.
          </Typography>
        </Container>
      </Container>
    </>
  );
};

export default NewsBanner;
