import React from "react";
import NewsContent from "../elements/NewsContent";
import NewsNav from "../elements/NewsNav";

const NewsDetails = () => {
  return (
    <>
      <NewsNav top />
      <NewsContent />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails;
