import React from "react";
import NewsArticle47 from "../elements/NewsArticle47";
import NewsNav from "../elements/NewsNav";

const NewsDetails47 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle47 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails47;