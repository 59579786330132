import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle51 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Vergelijk van alternatieve brandstoffen

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage51.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                30-05-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Verduurzaming is de afgelopen jaren uitgegroeid tot een centraal thema in de transportsector. Dit heeft geleid tot een{" "}
            <span style={{color: "#2b5baa"}}>
            herziening van de traditionele methoden {" "}
            </span>
            van vervoer. Diesel, dat jarenlang de ruggengraat van de transportsector vormde, komt steeds meer onder vuur te liggen 
            vanwege de negatieve impact op het milieu. Wat is op dit moment en in de toekomst het beste alternatief voor zware vrachtwagens 
            en welke duurzame opties zijn nu al beschikbaar? 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Op dit moment worden diverse technologieën voorgesteld als oplossingen, waaronder accu-elektrische aandrijving en waterstof-brandstofcellen, 
            evenals hernieuwbare brandstoffen zoals gehydrogeneerde plantaardige olie (HVO) en Bio-LNG. {" "}
             <span style={{color: "#9c29ff"}}>
             Elk van deze systemen voldoet aan de 30% CO2-reductie tegen 2030.  {" "}
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>
            Bio-LNG 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Bio-LNG is de vloeibare vorm van biogas dat wordt verkregen door afval- en reststoffen zoals voedselafval en mest te vergisten. Het is GEEN aardgas en daarom{" "} 
              <span style={{color: "#2b5baa"}}>
            fossielvrij
            </span> 
            .  Van de productie van Bio-LNG tot en met de verbranding in de motor van de truck (well-to-wheel) zijn de{" "}
            <span style={{color: "#2b5baa"}}>
            emissies veel lager{" "}
            </span> 
            dan die van fossiele diesel. Het voordeel van Bio-LNG is enerzijds dat er momenteel flink geïnvesteerd wordt in de aanleg van een{" "}
            <span style={{color: "#2b5baa"}}>
            brede infrastructuur
            </span> 
            , het een{" "}
            <span style={{color: "#2b5baa"}}>
            hoge energiedichtheid{" "}
            </span> 
            heeft en bestaande LNG-vrachtwagens{" "}
            <span style={{color: "#2b5baa"}}>
            geen aanpassingen aan hun motor{" "}
            </span>
            hoeven te maken om Bio-LNG te kunnen tanken. 
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >Hoewel rijden op Bio-LNG een stuk minder vervuilend is dan rijden op fossiele brandstoffen, is er nog steeds sprake van uitstoot bij de verbranding van de brandstof 
            in de truck, zoals CO2, NOx en fijnstof. Dit maakt een Bio-LNG truck minder geschikt voor zero-emissiezones in de stad. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  <span style={{color: "#9c29ff"}}>
            HVO
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            HVO staat voor Hydrotreated Vegetable Oil, oftewel gehydrogeneerde plantaardige olie. Het is een{" "}
            <span style={{color: "#9c29ff"}}>
            duurzame diesel{" "}
            </span>
            die wordt geproduceerd uit hernieuwbare en duurzame bronnen, zoals plantaardige oliën en vetten. Een groot voordeel van deze brandstof is dat deze in{" "}
            <span style={{color: "#9c29ff"}}>
            bestaande dieselmotoren{" "}
            </span>
            gebruikt kan worden zonder aanpassingen aan de verbrandingsmotor. De uitstoot van broeikasgassen van HVO worden tot 90% gereduceerd in vergelijking met fossiele diesel. 
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            Nadelen zijn dat de{" "}
            <span style={{color: "#9c29ff"}}>
            productiecapaciteit van HVO momenteel beperkt is{" "}
            </span>
            wat kan leiden tot schaarste en logistieke uitdagingen. HVO is doorgaans{" "}
            <span style={{color: "#9c29ff"}}>
            duurder dan conventionele diesel{" "}
            </span>
            en andere hernieuwbare brandstoffen. Dit kan een aanzienlijke impact hebben op de operationele kosten van transportbedrijven. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
             <span style={{color: "#2b5baa"}}>
            Waterstof
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            In een waterstof-brandstofcelsysteem wordt waterstof gecombineerd met zuurstof in een brandstofcel om elektriciteit op te wekken. 
            Deze elektriciteit wordt gebruikt om een elektrische motor aan te drijven. Een groot voordeel is dat waterstof een{" "}
            <span style={{color: "#2b5baa"}}>
            hogere energiedichtheid {" "}
            </span>
            per gewichtseenheid heeft{" "}
            <span style={{color: "#2b5baa"}}>
            dan batterijen
            </span>
            ,  wat betekent dat voertuigen{" "}
            <span style={{color: "#2b5baa"}}>
            een groter bereik{" "}
            </span>
            kunnen hebben{" "}
            <span style={{color: "#2b5baa"}}>
            zonder dat de tanks te zwaar worden.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            Nadelen van waterstof is dat er momenteel een{" "}
            <span style={{color: "#2b5baa"}}>
            beperkte infrastructuur{" "}
            </span> 
            voor waterstoftankstations is, wat de praktische bruikbaarheid van waterstofvoertuigen beperkt. Het ontwikkelen van een uitgebreid 
            netwerk van tankstations vereist aanzienlijke investeringen. Hiernaast is de productie, opslag en distributie van waterstof {" "}
            <span style={{color: "#2b5baa"}}>
            momenteel een stuk duurder{" "}
            </span> 
            dan conventionele brandstoffen en zelfs andere alternatieve brandstoffen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
               <span style={{color: "#9c29ff"}}>
               Accu-elektrische aandrijving 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
             Elektrische vrachtwagens zie je tegenwoordig steeds meer in het nieuws en op de weg. Wat elektrische trucks zo aantrekkelijk maakt is dat ze{" "}
             <span style={{color: "#9c29ff"}}>
            geen{" "}
            </span>
            stoten{" "}
            <span style={{color: "#9c29ff"}}>
            schadelijke uitlaatgassen uit{" "}
            </span>
            tijdens het rijden, waardoor ze een aanzienlijke bijdrage leveren aan de{" "}
            <span style={{color: "#9c29ff"}}>
            verbetering van de luchtkwaliteit
            </span>
            . Hiernaast hebben over het algemeen minder bewegende delen dan voertuigen met een verbrandingsmotor, wat mogelijk resulteert in{" "}
            <span style={{color: "#9c29ff"}}>
            lagere onderhoudskosten en minder behoefte aan regelmatig onderhoud {" "}
            </span>
            zoals olieverversingen en uitlaatreparaties. Hiernaast zijn ze ook een stuk stiller dan traditionele verbrandingsmotoren. 
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            Nadelen van elektrische aangedreven trucks is o.a. de{" "}
            <span style={{color: "#9c29ff"}}>
            lage actieradius{" "}
            </span>
            van elektrische vrachtwagens in vergelijking met voertuigen met een verbrandingsmotor, waardoor langere afstanden en continue 
            werking moeilijker kunnen zijn zonder tussentijds opladen. Hiernaast {" "}
            <span style={{color: "#9c29ff"}}>
            duurt het opladen van de accu's aanzienlijk langer{" "}
            </span>
            dan het voltanken van een diesel voertuig. Als laatst is de{" "}
            <span style={{color: "#9c29ff"}}>
            infrastructuur beperkt
            </span>
            , waardoor het lastig kan zijn om geschikte oplaadpunten te vinden, vooral voor langeafstandsvrachtvervoer. 
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            In de zoektocht naar duurzame alternatieven voor zware vrachtwagens hebben verschillende technologieën hun opmars gemaakt. 
            Bio-LNG, HVO, waterstof-brandstofcellen en accu-elektrische aandrijving bieden allemaal hun eigen set van voordelen en uitdagingen. 
            Elk van deze opties draagt bij aan het groeiende landschap van duurzaam transport, maar vereist verdere technologische vooruitgang en 
            infrastructuurontwikkeling om hun volledige potentie te benutten. Als ondernemer is het cruciaal om de ontwikkelingen van diverse 
            alternatieven voor diesel in de gaten te houden. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle51;
