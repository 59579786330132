import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle12 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >             
                Tijdelijke Nederlandse accijnsverlaging in juli 23 ten einde. Over de grens tanken binnenkort weer aantrekkelijker?

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage12.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                25.05.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             Een verhoging van accijns, daar zit nooit iemand op te wachten (behalve overheden), 
             zeker een transporteur niet. Wetende dat accijns een groot deel van de totale 
             brandstofkosten is en het brandstofaandeel een groot deel is van de transporteur’s totale 
             bedrijfskosten, kan elke cent die bespaard wordt op brandstof het verschil maken 
             tussen een positief- of negatief resultaat.

            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#2b5baa" }}>
              De laatste paar jaren zijn de prijzen van energie
              </span>
              , waaronder fossiele brandstoffen als dieselolie,{" "}
              <span style={{ color: "#2b5baa" }}>
              uit de pan geschoten
              </span>
              .{" "}
              <span style={{ color: "#9c29ff" }}>
              Ter compensatie{" "}
              </span>
              voerden overheden zoals die in Nederland{" "}
              <span style={{ color: "#9c29ff" }}>
              een tijdelijke verlaging van accijns{" "}
              </span>
              in ter ondersteuning. Deze tijdelijke verlaging bedroeg{" "}
              <span style={{ color: "#9c29ff" }}>
              in Nederland 21%
              </span>
              , wat op 11 cent per liter diesel neerkwam. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Helaas zijn feestjes maar van korte duur en zal{" "} 
            <span style={{ color: "#2b5baa" }}>
            per 1 juli 2023 de accijns compensatie deels afgebouwd worden{" "} 
            </span>
            met daarbovenop ook nog eens een doorgevoerde inflatiecorrectie. 
            Het huidige accijnstarief bedraagt 41,75 cent per liter en 
            stijgt per 1 juli 2023 naar 51,63 cent per liter diesel. Dit 
            komt neer op een stijging van{" "}
            <span style={{ color: "#2b5baa" }}>
            +/- 10 cent (excl. BTW){" "} 
            </span> 
            per liter diesel in Nederland.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Nederland was uiteraard niet het enige land dat maatregelen trof om de 
            consument te compenseren voor de abrupte prijsstijgingen van energie. 
            Zo kwam onder andere buurland België ook met een tijdelijke 
            accijnsverlaging over dieselolie. Inmiddels is in België al per 31 
            maart 2023 de hoogte van het accijnsbedrag over dieselolie op het 
            oude niveau. Is de dieselprijs per 1 juli 2023 dan lager in België 
            vergeleken met Nederland? Nee, de bruto dieselprijs niet, maar zo heeft{" "}
            <span style={{ color: "#2b5baa" }}>
            België{" "}
            </span>
            wel speciale regelingen die het voor de transporteur wellicht een{" "}
            <span style={{ color: "#2b5baa" }}>
            aantrekkelijker land maken om in te tanken
            </span>
            .{" "}
            <span style={{ color: "#9c29ff" }}>
            Zo biedt de Belgische douane een mogelijkheid om als professioneel 
            transporteur teruggaaf te kunnen doen{" "}
            </span>
            over een deel van het accijnsbedrag. 
            Op dit moment ligt deze vergoeding op{" "}
            <span style={{ color: "#9c29ff" }}>
            20,51 cent per liter diesel
            </span> 
            . Verder stelt de Belgische douane periodiek een drempelbedrag vast, 
            waarboven de transporteur een vergoeding krijgt op de accijns.{" "}
            <span style={{ color: "#2b5baa" }}>
            Zo heeft de transporteur in België geen last van de abrupte accijns stijgingen{" "}
            </span>
             omdat deze enkel het periodieke drempelbedrag betaalt.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Door de abrupte energiekosten stijgingen in 2022 namen overheden in 
            Europa actie om de consument te compenseren door middel van 
            accijnsverlagingen over onder andere dieselolie. Inmiddels is in 
            vele lidstaten het accijnsbedrag weer op het oude niveau en gaat 
            per 1 juli 2023 de accijnsverlaging in Nederland er helaas ook af. 
            Reden voor de transporteur om in de buurlanden te kijken of er 
            goedkoper getankt kan worden. Zo biedt België reeds voordelen in 
            de vorm van regelingen die de netto dieselprijs stabieler- en 
            voordeliger maken voor de transporteur.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle12;
