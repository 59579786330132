import React from "react";
import NewsArticle24 from "../elements/NewsArticle24";
import NewsNav from "../elements/NewsNav";

const NewsDetails24 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle24 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails24;