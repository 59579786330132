import React from "react";
import BannerPricingDeltaPetrol from "../elements/BannerPricingDeltaPetrol";
// import BannerPricing from "../elements/BannerPricingDeltaPetrol";
import InfoCards from "../elements/InfoCards";
import MostAskedQuestions from "../elements/MostAskedQuestions";
import PricingTable from "../elements/PricingTable";
import ProductNav from "../elements/ProductNav";
// import QuestionsDeltaPetrol from "../elements/QuestionsDeltaPetrol";
import Werkwijze from "../elements/Werkwijze";
import Banner01 from "../pages/Banner01";
// import Banner02 from "../pages/Banner02";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const DeltaPetrol = () => {
  const questions = [
    {
      question: "Waarom zou ik Delta Petrol kiezen ipv. Hybrid?",
      answer:
        "Als u soms kiest voor voorfinanciering, dan vergt binnenkomst van het bedrag bij u een extra handeling in de boekhouding en verstoort dit de vorderingspositie. Bij Delta Petrol blijft uw boekhouding altijd goed afgeletterd.",
    },
    {
      question: "Waarom een tarifering op abonnementsbasis?",
      answer:
        "Met een abonnement weet u waar u elke maand aan toe bent wat leidt tot een stabiele cashflow. Een abonnement is ook iets voordeliger geprijsd ten opzichte van de traditionele no-cure-no-pay methode.",
    },
    {
      question: "Wat is de looptijd van een abonnement?",
      answer: "Dat varieert van 1 tot 5 jaar.",
    },
    {
      question: "Hoe zorg ik dat Delta mijn facturen krijgt?",
      answer:
        "Wij streven naar een zo zorgeloos mogelijke BTW teruggaaf voor u. Daarbij hoort dat u ook geen omkijken heeft naar het aanleveren van facturen. Wij hebben hier diverse methoden voor en u kiest degene die het best bij u past. Allen zijn gericht op 'zero-handling' voor u.",
    },
    {
      question: "Kan ik later nog switchen van dienst/product?",
      answer: "Ja, dat is geen probleem.",
    },
  ];
  return (
    <>
      <ProductNav deltaPetrolActive />
      <Banner01 />
      <InfoCards deltaPetrolActive />
      <Werkwijze deltaPetrolActive />
      <BannerPricingDeltaPetrol />
      <MostAskedQuestions Questions={questions} />
      <PricingTable deltaPetrolActive />
    </>
  );
};

export default DeltaPetrol;
