import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle44 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            ESTA zwaar transport overbrugt nieuwe hindernis 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage44.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                22-02-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De langlopende inspanningen van {" "}
            <span style={{color: "#2b5baa"}}>
            ESTA{" "}
            </span>
            om de regelgeving voor zwaar transport en abnormale ladingen binnen de Europese Unie te hervormen en te harmoniseren, hebben recent een{" "}
            <span style={{color: "#2b5baa"}}>
            belangrijk mijlpaal bereikt
            </span>
            . De Europese Vereniging van Abnormaal Wegvervoer en Mobiele Kranen heeft aangegeven dat deze voorstellen zijn opgenomen in de herzieningen van de 
            EU-richtlijn Gewichten en Afmetingen (96/53). Het wordt nu verwacht dat deze herzieningen in{" "}
            <span style={{color: "#9c29ff"}}>
            april 2024{" "}
            </span>
            door het voltallige Europese Parlement zullen worden{" "}
            <span style={{color: "#9c29ff"}}>
            geratificeerd
            </span>
            , waarna een overlegproces tussen de Europese Raad, het Parlement en de Commissie zal plaatsvinden. 
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Een van de belangrijke aspecten van deze herzieningen is de langverwachte Europese adoptie van de standaard{" "}
            <span style={{ color: "#9c29ff" }}>
            SERT-documentatie{" "}
            </span>
            voor voertuigregistratie met abnormale lading. Deze stap, lang ondersteund door ESTA, beoogt de bureaucratische 
            last voor de sector te verminderen. SERT, de speciale Europese registratie voor vrachtwagens en opleggers, is bedoeld om het {" "}
            <span style={{ color: "#9c29ff" }}>
            papierwerk te stroomlijnen.
            </span> 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Andere voorstellen die ter goedkeuring zijn gepland, zijn onder meer:   {" "}
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Een 'one-stop-shop' per land voor vergunningen 
                        </li>
                        <li>   
                        Gestandaardiseerde aanvraagformulieren voor vergunningen 
                        </li>
                        <li> 
                        Gebruik van elektronische vergunningen  
                        </li>
                        <li> 
                        Harmonisatie van regels voor begeleiding en voertuigmarkeringen 
                        </li>
                        <li> 
                        Een verbod op taalvereisten voor chauffeurs 
                        </li>
                        <li> 
                        Het beschikbaar stellen van systemen in alle EU-talen 
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Bij volledige aanvaarding zal de recent herziene richtlijn ook eisen dat EU-lidstaten {" "}
            <span style={{ color: "#2b5baa" }}>
            één enkel toegangspunt {" "}
            </span>
            hebben voor alle informatie over de nationale maximale afmetingen en gewichten van voertuigen, 
            inclusief eventuele restricties zoals hoogtebeperkingen in aangewezen gebieden of op specifieke wegen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De herziening vereist ook dat de Europese Commissie een{" "}
            <span style={{ color: "#9c29ff" }}>
            webportaal{" "}
            </span>
            ontwikkelt dat alle eerdergenoemde informatie over de regelgeving voor zwaar en abnormaal transport 
            bevat, inclusief gedetailleerde informatie over routes die geschikt zijn voor voertuigen die ondeelbare ladingen vervoeren. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Voor transportbedrijven in de EU belooft deze recente ontwikkeling een{" "}
              <span style={{ color: "#2b5baa" }}>
              aanzienlijke vereenvoudiging en harmonisatie {" "}
            </span>
            van regelgeving met betrekking tot zwaar transport en abnormale ladingen. 
            De langdurige inspanningen van ESTA hebben geleid tot belangrijke voorstellen die nu worden 
            opgenomen in de herzieningen van de EU-richtlijn Gewichten en Afmetingen, waardoor de 
            complexiteit binnen de sector wordt verminderd. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle44;
