import React from "react";
import NewsArticle22 from "../elements/NewsArticle22";
import NewsNav from "../elements/NewsNav";

const NewsDetails22 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle22 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails22;