import React from "react";
import NewsArticle14 from "../elements/NewsArticle14";
import NewsNav from "../elements/NewsNav";

const NewsDetails14 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle14 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails14;