import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import ThirtyPlus from "../../assets/30plus.png";
import FlexiblePreFinance from "../../assets/flexiblePreFinance.png";
import Automation from "../../assets/automation.png";
import StabieleCashflow from "../../assets/StabieleCashflow.png";
import Boekhoudingversimpeld from "../../assets/Boekhoudingversimpeld.png";
import Onafhankelijk from "../../assets/Onafhankelijk.png";
import { Container } from "@mui/system";
import Image from "mui-image";
import { Link } from "react-router-dom";

const HomePageSellingPoints = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          backgroundColor: "#FAFCFF",
          m: 0,
          p: 0,
          mt: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  30+ jaren ervaring
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>
                    Delta is de hernieuwde visie{" "}
                  </span>
                  op een eerder succesvolle onderneming in de buitenlandse BTW-
                  en accijns terugvorder branche.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "600px",
                    alignItems: "center",
                  }}
                  img
                  src={ThirtyPlus}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "600px",
                    alignItems: "center",
                  }}
                  img
                  src={FlexiblePreFinance}
                  alt="cur"
                  // height={402}
                  // width={793}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Flexibel voorfinancieren
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                    "a:visited": {
                      color: "#2b5baa",
                    },
                    a: {
                      textDecoration: "none",
                      color: "#2b5baa",
                    },
                  }}
                >
                  Met
                  <span style={{ color: "#9c29ff" }}>
                    {" "}
                    <Link
                      style={{ textDecoration: "underline" }}
                      to="/DeltaHybrid"
                    >
                      Delta Hybrid
                    </Link>{" "}
                  </span>
                  bieden wij als eerste en
                  <span style={{ color: "#9c29ff" }}> enige in de markt </span>
                  de mogelijkheid om flexibel te laten voorfinanciering,
                  namelijk op het moment en voor het bedrag dat u wenst.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Automatisering
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>
                    {" "}
                    De workflow tussen u en Delta is 100% geautomatiseerd.{" "}
                  </span>
                  Na aansluiting bij Delta heeft u
                  <span style={{ color: "#9c29ff" }}>
                    {" "}
                    nooit meer omkijken{" "}
                  </span>
                  naar uw buitenlandse BTW- en accijnzen terugvordering.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "600px",
                    alignItems: "center",
                  }}
                  img
                  src={Automation}
                  alt="cur"
                  // height={380}
                  // width={650}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "600px",
                    alignItems: "center",
                  }}
                  img
                  src={StabieleCashflow}
                  alt="cur"
                  // height={360}
                  // width={653}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Stabiele cashflow
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U betaalt in de basis een vaste abonnementsprijs per maand. Zo
                  heeft u
                  <span style={{ color: "#9c29ff" }}>
                    {" "}
                    geen mutaties in lasten{" "}
                  </span>
                  wat tot een stabiele cashflow leidt.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Heldere boekhouding
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Delta houdt geen commissie in op uw buitenlandse vorderingen.
                  U kunt het gehele BTW- & accijns bedrag terug verwachten op uw
                  rekening wat het
                  <span style={{ color: "#9c29ff" }}>
                    {" "}
                    afletteren in uw boekhoudingssysteem drastisch versimpelt.{" "}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "600px",
                    alignItems: "center",
                  }}
                  img
                  src={Boekhoudingversimpeld}
                  alt="cur"
                  // height={403}
                  // width={836}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                  width: "100%",
                }}
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Image
                  sx={{ mt: 5 }}
                  style={{
                    // ObjectFit: "cover",
                    maxWidth: "260px",
                    alignItems: "center",
                  }}
                  img
                  src={Onafhankelijk}
                  alt="cur"
                  // height={360}
                  // width={653}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Onafhankelijk
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "30px", md: "35px", xl: "40px" },
                    lineHeight: { xs: "40px", md: "45px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>
                    Delta is niet geliëerd aan een kaartuitgever of
                    oliemaatschappij.
                  </span>
                  <br />
                  Door de onafhankelijkheid behandelen wij uw facturen allemaal
                  gelijk en tegen hetzelfde tarief.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default HomePageSellingPoints;
