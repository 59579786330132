import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle49 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Aanscherping regels Belgische BTW teruggaaf 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage49.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                25-04-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Recentelijk hebben we gemerkt dat er{" "}
            <span style={{color: "#2b5baa"}}>
            strengere controles {" "}
            </span>
            worden uitgevoerd op BTW-teruggaaf dossiers, vooral met betrekking tot de  {" "}
            <span style={{color: "#2b5baa"}}>
            Belgische BTW
            </span>
            . Hierbij worden enkele{" "}
            <span style={{color: "#9c29ff"}}>
            regels strenger toegepast{" "}
            </span>
            die betrekking hebben op de hoogte van de teruggaaf. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Één van die regels is dat {" "}
            <span style={{color: "#2b5baa"}}>
            alleen voertuigen van het type N2 en N3{" "}
            </span>
            (en M2 en M3 via binnenlandse BTW-aangifte) recht hebben op{" "}
            <span style={{color: "#2b5baa"}}>
            100% BTW-teruggaaf
            </span>
            . Voertuigen van de types {" "}
            <span style={{color: "#9c29ff"}}>
            N1 en M1{" "}
            </span>
            hebben een{" "}
             <span style={{color: "#9c29ff"}}>
             aangepaste vergoeding{" "}
            </span>
            ongeacht of de kosten (diesel en tol) voor deze voertuigen volledig zakelijk zijn: 
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <table cellpadding="10" cellspacing="0"> 
                <tbody>
                    <ul>
                 <tr>
                    <td width="25%">
                       Type:
                    </td >
                    <td width="50%">
                        Omschrijving:
                    </td>
                    <td width="25%">
                        Vergoeding:
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                        <span style={{color: "#2b5baa"}}> N2 en N3</span>
                        </span> 
                    </td>
                    <td>
                        <span>
                            Zware bedrijfsauto (zwaarder dan 3500 kg) 
                        </span>
                    </td>
                    <td>
                        <span>
                        <span style={{color: "#2b5baa"}}>100%</span>
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                           
                        <span style={{color: "#9c29ff"}}>M2 & M3</span>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                           Bus, touringcar, trolleybus
                        </span>
                    </td>
                    <td>
                        <span>
                        <span style={{color: "#9c29ff"}}>100%</span>
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                        <span style={{color: "#2b5baa"}}>N1</span>
                        </span> 
                    </td>
                    <td>
                        <span>
                            Lichte bedrijfsauto (lichter dan 3500 kg) 
                        </span>
                    </td>
                    <td>
                        <span>
                        <span style={{color: "#2b5baa"}}>85%</span>
                        </span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <span>
                           
                        <span style={{color: "#9c29ff"}}>M1</span>
                            
                        </span> 
                    </td>
                    <td>
                        <span>
                        Personenauto 
                        </span>
                    </td>
                    <td>
                        <span>
                        <span style={{color: "#9c29ff"}}>35%</span>
                        </span>
                    </td>
                 </tr>
                 </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >Bij het indienen van uw verzoeken voor teruggaaf van buitenlandse BTW (voor Belgische BTW) is 
            het cruciaal om op {" "}
            <span style={{color: "#2b5baa"}}>
            transactieniveau {" "}
            </span>
            te berekenen hoeveel procent u recht hebt op BTW-teruggaaf. 
            Bij Delta adviseren we ten zeerste om kosten voor voertuigen anders dan N2 en N3 niet op één factuur 
            te plaatsen met die van N2 en N3 voertuigen. Dit helpt om de {" "}
            <span style={{color: "#9c29ff"}}>
            verscherpte controles {" "}
            </span>
            van de Belgische 
            overheid {" "}
            <span style={{color: "#9c29ff"}}>
            te vermijden
            </span>
            . Dergelijke consolidatie kan namelijk leiden tot {" "}
            <span style={{color: "#2b5baa"}}>
            vertragingen in het proces{" "} 
            </span>
            of zelfs tot {" "}
            <span style={{color: "#2b5baa"}}>
            volledige afwijzing {" "}
            </span>
            van het dossier, wanneer er fouten worden gemaakt in de aangifte. In de 
            praktijk is het echter niet altijd mogelijk om dit te vermijden. Daarom is het hebben van een buitenlandse 
            BTW-intermediair die nauwlettend alles op transactieniveau volgt, van groot belang. Bij Delta maken we 
            gebruik van semi-geautomatiseerde registratiesystemen die de data filteren om ervoor te zorgen dat alles correct 
            wordt verwerkt. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle49;
