import React from "react";
import BannerContactEmail from "../elements/BannerContactEmail";
import BannerContactMapsMeeting from "../elements/BannerContactMapsMeeting";
import ContactBanner from "../elements/ContactBanner";

const contact = () => {
  return (
    <>
      <ContactBanner />
      <BannerContactEmail />
      <BannerContactMapsMeeting />
    </>
  );
};

export default contact;
