import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle45 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            EU-rijbewijs toegankelijker voor jonge chauffeurs 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage45.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                29-02-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Op {" "}
            <span style={{color: "#2b5baa"}}>
            28 februari{" "}
            </span>
            heeft het Europees Parlement zijn rapport over de {" "}
            <span style={{color: "#2b5baa"}}>
            rijbewijsrichtlijn aangenomen
            </span>
            , waarbij belangrijke hervormingen zijn overeengekomen om cruciale obstakels voor de toegang tot het beroep van chauffeur weg te nemen.
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De plenaire vergadering van het Europees Parlement heeft ook het tempo bepaald voor de trialoogonderhandelingen met de Raad en de Commissie 
            en heeft zijn standpunt over de rijbewijsrichtlijn vastgesteld. Deze ontwikkeling markeert een aanzienlijke vooruitgang in het{" "}
            <span style={{ color: "#9c29ff" }}>
            verwijderen van leeftijdsbeperkingen {" "}
            </span>
            voor jonge bestuurders en{" "}
            <span style={{ color: "#9c29ff" }}>
            vergemakkelijkt de integratie{" "}
            </span> 
            van chauffeurs uit derde landen in de EU. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            IRU EU-directeur voor belangenbehartiging Raluca Marian zei: “De wegvervoersector in de EU heeft zowel lokale 
            chauffeurs als professionals uit derde landen nodig om het chronische tekort aan chauffeurs te overwinnen. 
            We missen nu al ruim{" "}
            <span style={{color: "#2b5baa"}}>
            
            500.000 beroepschauffeurs 
            </span>
            ."
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            “Onze focus ligt in de eerste plaats op het aantrekken van meer lokaal talent, waaronder jongeren en vrouwen. 
            Gezien de omvang van het chauffeurstekort, in combinatie met het feit dat veel chauffeurs de pensioengerechtigde 
            leeftijd naderen, moeten we de lokale talentenpool aanvullen met chauffeurs uit derde landen.”
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De plenaire vergadering steunde een EU-programma voor begeleid rijden, waardoor {" "}
            <span style={{ color: "#9c29ff" }}>
            17-jarige vrachtwagenchauffeurs{" "}
            </span>
            (categorieën C en C1){" "}
            <span style={{ color: "#9c29ff" }}>
            onder toezicht van ervaren chauffeurs praktijkervaring kunnen opdoen
            </span>
            . Dit initiatief wordt gezien als een cruciale hervorming voor de vrachtwagensector, 
            omdat het jonge chauffeurs helpt vertrouwd te raken met het beroep terwijl ze onder begeleiding rijden. 
            Raluca Marian benadrukte het belang van de term 'begeleid' in deze context, waarbij ze de waarde van 
            praktijkervaring naast een ervaren chauffeur benadrukte als een effectieve vorm van on-the-job training. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Voor Nederlandse transportbedrijven betekent de aangenomen rijbewijsrichtlijn in het Europees Parlement belangrijke hervormingen 
              die cruciale obstakels voor de toegang tot het beroep van chauffeur zullen wegnemen.  Deze ontwikkeling duidt op een{" "}
              <span style={{ color: "#2b5baa" }}>
              aanzienlijke vooruitgang {" "}
            </span>
            in het verwijderen van leeftijdsbeperkingen voor jonge bestuurders en vergemakkelijkt de integratie van chauffeurs 
            uit derde landen in de EU. Dit kan ervoor zorgen dat er een{" "}
            <span style={{ color: "#2b5baa" }}>
            stijging zal komen in het aantal chauffeurs in Nederland
            </span>
            , maar ook in de rest van Europa.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle45;
