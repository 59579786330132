// import { Typography } from "@mui/material";
// import { ContactEmergency, RampRight } from "@mui/icons-material";
// import Container from "@mui/material/Container";
import { Box, Typography, Grid, Paper } from "@mui/material";
// import Image from "mui-image";
import React from "react";
import Pricing04 from "../../assets/Pricing04.png";
// import liveInzicht from "../../assets/Live inzicht icoontje.png";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";

// import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import CalculateButton from "./CalculateButton";
import { Link } from "react-router-dom";

// const handleCalculateNow = () => {
//   alert("ref");
// };

const BannerPricingDeltaDiesel = (props) => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: "#FAFCFF",
      }}
    >
      <Container maxWidth="xl">
        <Paper
          // maxWidth="xl"
          sx={{
            position: "relative",
            // color: "#fff",
            mt: 1,
            pb: 0,
            backgroundSize: "200%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left bottom",
            "@media (min-width:600px)": {
              backgroundPosition: "left bottom",
              backgroundSize: "contain",
            },

            backgroundImage: {
              xs: "none",
              md: "none",
              lg: `url(${Pricing04})`,
            },
            borderRadius: "0px",
            boxShadow: "none",
            backgroundColor: "#FAFCFF",
          }}
        >
          {/* Increase the priority of the hero background image */}
          {/* {<img style={{ display: "none" }} src={Pricing04} alt="Text" />} */}
          <Box
            border="none"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              // backgroundColor: "rgba(0,0,0,.3)",
            }}
          />
          <Grid container>
            <Grid item lg={6} md={0}></Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Tarieven
                </Typography>
                <Typography
                  sx={{
                    mt: 6,
                    color: "#6b6a6a",
                    fontSize: { xs: "24px", md: "36px", xl: "40px" },
                    lineHeight: { xs: "34px", md: "46px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Voor <span style={{ color: "#000000" }}>Delta Diesel </span>
                  hanteren wij een maandelijks abonnementstarief.
                </Typography>
                <Typography
                  sx={{
                    mt: 6,
                    color: "#6b6a6a",
                    fontSize: { xs: "24px", md: "36px", xl: "40px" },
                    lineHeight: { xs: "34px", md: "46px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Neem contact met ons op over uw maandelijkse tarief.
                </Typography>
                <Typography
                  sx={{
                    mt: 6,
                    color: "#6b6a6a",
                    fontSize: { xs: "24px", md: "36px", xl: "40px" },
                    lineHeight: { xs: "34px", md: "46px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                    a: {
                      color: "#2B5BAA",
                    },
                    "a:visited": {
                      color: "#2B5BAA",
                    },
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>Let op! </span>U kunt
                  mogelijk profiteren van kortingen die het maandtarief
                  reduceert. Neem daarvoor{" "}
                  <Link
                    // style={{ textDecoration: "none" }}
                    sx={{}}
                    to="/Contact"
                  >
                    hier
                  </Link>{" "}
                  contact met ons op.
                </Typography>
                <Typography
                  sx={{
                    mt: 6,
                    color: "#6b6a6a",
                    fontSize: { xs: "24px", md: "36px", xl: "40px" },
                    lineHeight: { xs: "34px", md: "46px", xl: "50px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Wenst u voorfinanciering over uw Diesel accijnzen? Neem
                  contact met ons op over de mogelijkheden!
                </Typography>
                <CalculateButton maxWidth="290px" />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

export default BannerPricingDeltaDiesel;
