import {
  // Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/Add";
import CollapseIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/system";
// import { height } from "@mui/system";

import PictureCallMaartje from "../../assets/PictureCallMaartje.png";
import Image from "mui-image";
import emailjs from "@emailjs/browser";

const MostAskedQuestions = (props) => {
  const form = useRef();
  const [expanded, setExpanded] = React.useState(false);
  const [succesLabelVisible, setSuccesLabelVisible] = React.useState(false);
  const [errorLabelVisible, setErrorLabelVisible] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);

  const sendEmail = (e) => {
    setSubmitButtonDisabled(true);
    // e.preventDefault();
    // var hasError;
    emailjs
      .sendForm(
        "service_pcmrdxj",
        "template_81qf0c6",
        form.current,
        "_yi0_0d3ScPyf_1Dl"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(succesLabelVisible);
          setSuccesLabelVisible(true);
          console.log(succesLabelVisible);
        },
        (error) => {
          console.log(error.text);
          setErrorLabelVisible(true);
        }
      );
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <Typography
          sx={{
            mt: 8,
            mb: 1,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "40px", md: "55px", xl: "71px" },
            lineHeight: { xs: "55px", md: "70px", xl: "85px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
          }}
        >
          Heeft u vragen?
        </Typography>
        <Typography
          mb={5}
          sx={{
            mt: 0,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "16px", md: "21px", xl: "21px" },
            lineHeight: { xs: "20px", md: "25.2px", xl: "25.2px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Regular",
          }}
        >
          Wij beantwoorden graag uw vragen.
        </Typography>

        <Grid md={12} container direction="row" item align="center">
          <Grid md={6} xs={12}>
            <Typography
              mt={5}
              mb={5}
              sx={{
                color: "#2B5BAA",
                fontSize: { xs: "24px", md: "30px", xl: "36px" },
                lineHeight: { xs: "22.8px", md: "28.8px", xl: "34.8px" },
                fontFamily: "Century Gothic",
                fontWeight: "bold",
              }}
            >
              De meestgestelde vragen
            </Typography>
            {props.Questions.map((item, index) => (
              <Grid sx={{ mt: 1 }}>
                <Accordion
                  expanded={expanded === "panel" + index}
                  onChange={handleChange("panel" + index)}
                  sx={{
                    m: "auto",
                    mt: 1,
                    maxWidth: "699px",
                    boxShadow: 3,
                    "&:hover": { boxShadow: 5 },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === "panel" + index ? (
                        <CollapseIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      sx={{
                        color: "#6b6a6a",
                        fontSize: { xs: "22px", md: "26px", xl: "29px" },
                        lineHeight: { xs: "31px", md: "35px", xl: "38px" },
                        letterSpacing: 0,
                        fontFamily: "HelveticaNeue-Thin",
                        textAlign: "left",
                      }}
                    >
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      sx={{
                        color: "#6b6a6a",
                        textAlign: "left",
                        fontSize: { xs: "18px", md: "21px", xl: "24px" },
                        lineHeight: { xs: "21px", md: "24px", xl: "28px" },
                        letterSpacing: 0,
                        fontFamily: "HelveticaNeue-Thin",
                        mt: 1,
                      }}
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </Grid>
          <Grid md={6} xs={12}>
            <Typography
              mt={5}
              mb={5}
              sx={{
                color: "#2B5BAA",
                fontSize: { xs: "24px", md: "30px", xl: "36px" },
                lineHeight: { xs: "22.8px", md: "28.8px", xl: "34.8px" },
                fontFamily: "Century Gothic",
                fontWeight: "bold",
              }}
            >
              Stel direct uw vraag!
            </Typography>
            <form ref={form} onSubmit={sendEmail}>
              <Grid align="left" md={12} xs={12} container>
                <TextField
                  name="message"
                  multiline
                  rows={10}
                  sx={{
                    m: "auto",
                    mt: 1,
                    mb: 0,
                    width: "100%",
                    "@media (min-width:899px)": {
                      ml: 2,
                      mr: 2,
                    },
                    maxWidth: "699px",
                    boxShadow: 3,
                  }}
                  placeholder="Type hier uw vraag!"
                ></TextField>
              </Grid>
              <Grid
                container
                direction="row"
                item
                align="left"
                sx={{ position: "relative" }}
              >
                <Grid md={12} xs={12} container>
                  <Box
                    sx={{
                      m: "auto",
                      mt: 1,
                      mb: 0,
                      // ml: 0,
                      width: "100%",
                      "@media (min-width:899px)": {
                        ml: 2,
                        mr: 2,
                      },
                      maxWidth: "699px",
                    }}
                  >
                    <Grid md={12} xs={12} container>
                      <Grid md={8} xs={12} container justifyContent="center">
                        <TextField
                          name="email"
                          // inputProps={{
                          //   style: {
                          //     height: "34px",
                          //   },
                          // }}
                          sx={{
                            // position: "absolute",
                            // top: 0,
                            // left: 0,

                            m: "auto",
                            mb: 0,
                            maxWidth: "100%",
                            width: "100%",

                            "@media (min-width:899px)": {
                              maxWidth: "100%",
                              mr: 1,
                            },
                            boxShadow: 3,
                          }}
                          placeholder="Uw e-mailadres"
                        ></TextField>
                      </Grid>
                      <Grid md={4} xs={12} container justify="flex-end">
                        <Button
                          onClick={sendEmail}
                          variant="outlined"
                          sx={{
                            // position: "absolute",
                            // top: 0,
                            // right: 0,
                            m: "auto",
                            // m: 0,
                            mt: 1,
                            height: "55px",
                            width: "100%",
                            maxWidth: "699px",
                            "@media (min-width:899px)": {
                              // m: 0,
                              height: "55px",
                              maxWidth: "100%",
                              ml: 1,
                            },
                            boxShadow: 3,
                          }}
                          disabled={submitButtonDisabled ? true : false}
                        >
                          Verstuur
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          mt: 3,
                          color: succesLabelVisible
                            ? "green"
                            : errorLabelVisible
                            ? ""
                            : null,
                          fontSize: "18px",
                          lineHeight: "24px",
                          fontFamily: "HelveticaNeue-Light",
                          textAlign: "center",
                        }}
                      >
                        {succesLabelVisible
                          ? "Uw bericht is succesvol verzonden. U krijgt z.s.m. van ons bericht."
                          : errorLabelVisible
                          ? "Het is niet gelukt om uw bericht te versturen. Probeert u het later nog eens of neem direct telefonisch contact met ons op."
                          : null}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    mb: 5,
                    ml: 0,
                    "@media (min-width:899px)": {
                      ml: 1,
                    },
                  }}
                  md={12}
                  xs={12}
                  container
                >
                  <Grid md={2} xs={4}>
                    <Image
                      sx={{
                        mt: 6,
                        ml: 0,
                        "@media (min-width:899px)": {
                          ml: 0,
                        },
                      }}
                      img
                      src={PictureCallMaartje}
                      alt="cur"
                      height={132}
                      width={128}
                    />
                  </Grid>
                  <Grid md={10} xs={8}>
                    <Typography
                      sx={{
                        ml: 0,
                        mt: 6,
                        pl: 3,
                        fontSize: "18px",
                        "@media (min-width:899px)": {
                          fontSize: "22px",
                          mt: 6,
                          ml: 0,
                        },
                        fontFamily: "HelveticaNeue-Light",
                        color: "#6b6a6a",
                        a: {
                          color: "#2B5BAA",
                        },
                        "a:visited": {
                          color: "#2B5BAA",
                        },
                      }}
                    >
                      <span style={{ color: "#9c29ff" }}>Liever bellen?</span>
                      <br />
                      Neem nu contact op
                      <br /> met ons!{" "}
                      <a style={{ pb: 3 }} href="tel:+31858085522">
                        +31 85 808 55 22
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MostAskedQuestions;
