import { Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NewsNav = (props) => {
  console.table(props);
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          backgroundColor: "#FAFCFF",
          borderTop: props.bottom ? "1px solid #DBDBDB" : null,
          borderBottom: props.top ? "1px solid #DBDBDB" : null,
          height: "76px",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "25px", xl: "25px" },
              lineHeight: { xs: "20.2px", md: "25.2px", xl: "25.2px" },
              letterSpacing: "-0.33px",
              fontFamily: "HelveticaNeue-Light",
              a: {
                color: "#2b5baa",
              },
              "a:visited": {
                color: "#2b5baa",
              },
            }}
          >
            <Link to="/News" style={{ textDecoration: "none" }}>
              &#60; Terug
            </Link>
          </Typography>
        </Container>
      </Container>
    </>
  );
};

export default NewsNav;
