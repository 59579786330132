import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle32 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Tachograafkaart volgend jaar nog duurder  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage32.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                09-11-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Uit een recentelijke consultatie over de nieuwe{" "}
            <span style={{color: "#2b5baa"}}>
            tarieven{" "}
            </span>
            door  {" "} 
            <span style={{color: "#2b5baa"}}>
            Kiwa{" "}
            </span>
            blijkt dat de prijs van de tachograafkaart volgend jaar met{" "}
            <span style={{color: "#2b5baa"}}>
            6% zal gaan stijgen.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Keurings Instituut voor Waterleiding Artikelen (Kiwa) beheert verzoeken voor vergunningen en wettelijke bevoegdheden binnen de transportsector. Kiwa verleent{" "}
            <span style={{ color: "#9c29ff" }}>
            vergunningen en bevoegdheden{" "}
            </span>
            aan individuen en organisaties actief in de{" "}
            <span style={{ color: "#2b5baa" }}>
            luchtvaart, scheepvaart{" "}
            </span>
            (zee- en binnenvaart) en het{" "}
            <span style={{ color: "#2b5baa" }}>
            vervoer van zowel personen als goederen over de weg
            </span>
            . Kiwa is geen overheidsinstelling, maar onderhoudt een nauwe samenwerking met de overheid. Dit zorgt ervoor dat er spraken is van een{" "}
            <span style={{ color: "#9c29ff" }}>
            monopolie op het uitgeven van tachograafkaarten
            </span>
            , wat de marktwerking niet bevordert en daarmee de transportsector aan het kortste eind laat doen trekken. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            TLN uit al geruime tijd kritiek op de slechte service en voortdurende prijsstijgingen van Kiwa. {" "}
            <span style={{ color: "#2b5baa" }}>
            Dit jaar heeft zich een prijsstijging van maar liefst 10% voorgedaan {" "}
            </span>
            Aanvankelijk waren er plannen om de prijzen{" "} 
            <span style={{ color: "#9c29ff" }}>
            volgend jaar{" "}
            </span>
            met 20% te verhogen, met als doel de inflatie te compenseren. Recentelijk ontvangen berichten suggereren echter dat de{" "}
            <span style={{ color: "#9c29ff" }}>
            prijsstijging waarschijnlijk eerder rond de 6%{" "}
            </span>
            zal liggen, wat, hoe pijnlijk het ook is, mogelijk als een meevaller kan worden beschouwd in vergelijking met de eerder verwachte 20%. Dit betekent dat de prijs van tachograafkaart in 2024 ongeveer rond de{" "}
            <span style={{ color: "#2b5baa" }}>
            €110{" "}
            </span>
             zal gaan liggen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Transportondernemers dienen{" "}
            <span style={{ color: "#2b5baa" }}>
            elke 5 jaar{" "}
            </span>
            een nieuwe tachograafkaart aan te schaffen. Gezien de voortdurende inflatie, kunnen de prijzen 
            van Kiwa-producten naar verwachting verder stijgen. Het is verstandig om de prijsstijging nauwlettend 
            te volgen, om dit waar mogelijk in te prijzen in het tarief van uw dienstverlening. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle32;
