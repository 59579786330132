import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle28 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Mogelijk uitstel van Maut-uitbreiding naar 1 januari 2024 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage28.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                12-10-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Onlangs is aangekondigd dat de{" "} 
            <span style={{color: "#2b5baa"}}>
            Duitse Maut zal worden uitgebreid{" "}
            </span>
            met een extra tol, namelijk de{" "}
            <span style={{color: "#2b5baa"}}>
            CO2-tolheffing
            </span>
            . Het doel van deze tol is om het gebruik van{" "}
            <span style={{color: "#9c29ff"}}>
            milieuvriendelijkere vrachtwagens te bevorderen{" "}
            </span>
            en de{" "}
            <span style={{color: "#9c29ff"}}>
            opbrengsten hiervan te investeren in de transportsector.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Eerder heeft de Duitse overheid het wetsvoorstel aangenomen met de {" "}
            <span style={{ color: "#2b5baa" }}>
            intentie om de CO2-tolheffing op 1 december 2023 in te voeren
            </span>
            . Recent nieuws suggereert echter dat deze datum{" "}
            <span style={{ color: "#9c29ff" }}>
            mogelijk wordt uitgesteld naar 1 januari 2024 vanwege kritiek van buitenaf
            </span>
            . De{" "}
            <span style={{ color: "#2b5baa" }}>
            BGL
            </span>
            , een Duitse vereniging die de belangen van de middelgrote transportsector behartigt, is het onder andere {" "}
            <span style={{ color: "#2b5baa" }}>
            niet eens met de voorgestelde invoerdatum
            </span>
            . Ze argumenteerden dat transportbedrijven vaak contractueel gebonden zijn tot 1 januari 2024, waardoor ze de 
            verhoogde kosten van de Duitse Maut nog niet aan hun klanten hebben kunnen doorberekenen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de komende dagen zal duidelijk worden of de Duitse regering erin slaagt de wet tijdig 
            goed te keuren. Als dit niet lukt, kan een verkorte procedure in de Federale Raad nodig 
            zijn om de invoering op 1 december 2023 te realiseren. Als beide scenario's niet haalbaar 
            zijn, lijkt het waarschijnlijk dat de uitbreiding van de Duitse Maut met de CO2-tolheffing 
            pas op 1 januari 2024 van kracht wordt. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle28;
