import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle48 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Duitse Maut verplicht vanaf 3,5 ton 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage48.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                28-03-2024
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf{" "}
            <span style={{color: "#2b5baa"}}>
            1 juli 2024{" "}
            </span>
            wordt in Duitsland de tolplicht ingevoerd voor voertuigen met een technisch toelaatbare maximummassa van meer dan {" "}
            <span style={{color: "#9c29ff"}}>
            3,5 ton
            </span>
            . Deze tol is van toepassing op voertuigen die specifiek bedoeld zijn voor{" "}
            <span style={{color: "#9c29ff"}}>
            goederenvervoer{" "}
            </span>
            of voor dat doel worden gebruikt. 
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Tot op heden gold in Duitsland de verplichting om tol te betalen voor voertuigen met een gewicht van{" "}
            <span style={{ color: "#2b5baa" }}>
            meer dan 7,5 ton
            </span>
            . Dit betekende dat vrachtwagens met een totaalgewicht van 7,5 ton of minder vrijgesteld waren van tolheffing.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
        De Maut-tarieven vanaf 3,5 ton starten bij {" "}
        <span style={{color: "#9c29ff"}}>
        11,4 cent{" "}
        </span>
        per kilometer voor de{" "}
        <span style={{color: "#9c29ff"}}>
        schoonste Euro 6 klasse.
        </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             Transporteurs zullen binnenkort rekening moeten houden met{" "}
              <span style={{ color: "#2b5baa" }}>
              hogere operationele kosten{" "}
            </span>
            bij het rijden op Duitse snelwegen en federale wegen. Het is belangrijk dat transportbedrijven zich bewust zijn 
            van deze verandering om zo hun financiële planning en routeoptimalisatie hierop aan te passen en hiermee de impact op 
            bedrijfsresultaten te minimaliseren. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
              Wilt u berekenen hoeveel Maut u moet gaan betalen vanaf Juli 2024? Klik dan  <a href="https://www.toll-collect.de/nl/toll_collect/bezahlen/maut_tarife/p1745_mauttarife_07_2024.html "> hier</a>.
              </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle48;
