import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle55 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Introductie vrachtwagenheffing in Nederland 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage55.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                29-08-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In{" "}
            <span style={{color: "#2b5baa"}}>
            2026{" "}
            </span>
            introduceert{" "}
            <span style={{color: "#2b5baa"}}>
            Nederland{" "}
            </span>
            een{" "}
            <span style={{color: "#2b5baa"}}>
            vrachtwagenheffing
            </span>
            , met de 'Wet vrachtwagenheffing' als juridische basis. De details van deze regelgeving zijn verder uitgewerkt in een Besluit en een Ministeriële regeling. Het parlement heeft deze wet op{" "}
            <span style={{color: "#9c29ff"}}>
            12 juli 2022{" "}
            </span>
            aangenomen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf het moment dat in 2026 vrachtwagenheffing in Nederland van start gaat, moet{" "}
            <span style={{color: "#2b5baa"}}>
            elke vrachtwagen{" "}
            </span>
            die in Nederland wil rijden uitgerust zijn met werkende{" "}
            <span style={{color: "#2b5baa"}}>
            boordapparatuur{" "}
            </span>
            (ook wel OBU genoemd). Met deze apparatuur worden de gereden kilometers op het heffing plichtige netwerk Geregistreerd. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De vrachtwagenheffing geldt voor alle{" "}
            <span style={{color: "#9c29ff"}}>
            binnenlandse en buitenlandse{" "}
            </span>
            vrachtwagens met een gewicht van{" "}
            <span style={{color: "#9c29ff"}}>
            3,5 ton of meer
            </span>
            . Het gemiddelde tarief{" "}
            <span style={{color: "#2b5baa"}}>
            per kilometer{" "}
            </span>
            is{" "}
            <span style={{color: "#2b5baa"}}>
            16,7 cent{" "}
            </span>
            (prijspeil 2023). Het tarief is lager naarmate de vrachtwagen minder uitstoot en lichter is. Meer informatie over de tarieven vindt u <a href="https://www.vrachtwagenheffing.nl/veelgestelde-vragen"> hier</a>.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            Met de invoering van de vrachtwagenheffing zal de{" "}  
            <span style={{color: "#2b5baa"}}>
            motorrijtuigenbelasting{" "}
            </span>
            aanzienlijk worden{" "}
            <span style={{color: "#2b5baa"}}>
            verlaagd{" "}
            </span>
            in Nederland. Binnenlandse en buitenlandse vrachtwagens zullen dan gelijk betalen voor het gebruik van de weg.
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De RDW heeft de opdracht voor de levering van waarnemingsapparatuur voor de vrachtwagenheffing toegekend aan{" "}
            <span style={{color: "#2b5baa"}}>
            VITRONIC
            </span>
            . VITRONIC zal apparatuur leveren en installeren waarmee RDW kan controleren of vrachtauto’s aan de verplichtingen van de heffing voldoen. Bovendien zal VITRONIC gedurende ten minste {" "}
            <span style={{color: "#2b5baa"}}>
            10 jaar{" "}
            </span>
            alle bijbehorende servicediensten verzorgen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In onze buurlanden{" "}
            <span style={{color: "#9c29ff"}}>
            België en Duitsland{" "}
            </span>
            geldt al een vrachtwagenheffing per gereden kilometer. Ook Nederlandse vrachtwagens betalen daar voor het gebruik van de (snel)wegen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle55;
