import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle14 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Drukte verwacht bij tankstations in Nederland
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage14.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                15-06-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Eigenaren van tankstations in Nederland verwachten {" "}
            <span style={{ color: "#2b5baa" }}>
            grote drukte{" "}
              </span>
            tijdens de laatste dagen van Juni. Dit zal de laatste kans zijn 
            voor chauffeurs om te tanken tegen de huidige tarieven, voordat de accijns 
            op benzine en diesel op {" "}
            <span style={{ color: "#2b5baa" }}>
            1 Juli{" "}
              </span>
            weer omhoog gaat.
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In een eerder artikel op onze website werd duidelijk dat vanaf 1 Juli 
            2023 het accijnstarief in Nederland aanzienlijk zal {" "}
            <span style={{ color: "#9c29ff" }}>
            stijgen
            </span>
            .  Uit de berekening van dit artikel bleek dat de prijsstijging van de diesel op{" "}
            <span style={{ color: "#9c29ff" }}>
            +/- 10 cent per liter{" "}
            </span>
            zal uitkomen.
            </Typography>
           

            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Tankstations zorgen ervoor dat de brandstofpompen goed gevuld zijn, 
            zodat ze de koopjesjagers kunnen bedienen, aldus Belangenvereniging 
            Tankstations (BETA), die onafhankelijke station eigenaren vertegenwoordigt. 
            De voorzitter van de organisatie, Ewout Klok, verwacht dat de stijging van 
            de accijns op 1 Juli zal leiden tot aanzienlijk hogere prijzen. Klok, zelf 
            eigenaar van een tankstation, denkt {" "}
            <span style={{ color: "#2b5baa" }}>
            niet{" "}
              </span>
            dat tankstations eigenaren hun{" "}
            <span style={{ color: "#2b5baa" }}>
            prijzen geleidelijk zullen verhogen{" "}
              </span>
            vóór die datum.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De vereniging voor onafhankelijke tankstation eigenaren zegt 
            bang te zijn dat de stijging van de accijns ertoe zal leiden dat 
            tankstation eigenaren in Nederland veel klanten zullen verliezen. 
            "Vooral in de  {" "}
            <span style={{ color: "#2b5baa" }}>
            grensstreek{" "}
              </span>
            hebben ondernemers het zwaar. Op dit moment hadden we geluk dat we ongeveer op 
            hetzelfde prijsniveau zaten als België en Duitsland. Maar nu prijzen we onszelf 
            weer volledig uit de markt. Het is belachelijk dat de accijns zo veel verschilt 
            tussen landen," aldus Klok. Deze toename in prijs zou een aanleiding kunnen 
            zijn om uw{" "}
            <span style={{ color: "#2b5baa" }}>
            tankgedrag{" "}
              </span>
            naar naburige landen te{" "}
            <span style={{ color: "#2b5baa" }}>
            heroverwegen
              </span>
            .
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle14;
