import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle9 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Minimum- en maximum BTW-tarieven in de EU
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage9.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                04.05.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              De EU heeft minimale en maximale BTW-tarieven vastgesteld die door
              alle lidstaten moeten worden gerespecteerd.{" "}
              <span style={{ color: "#2b5baa" }}>
                Lidstaten hebben dus de vrije keuze in het bepalen van hoogte
                van de BTW-tarieven
              </span>
              , mits de grenzen worden aangehouden. Het stelt elke lidstaat in
              staat om zijn eigen inkomsten te genereren en het beleid af te
              stemmen op de eigen behoeften.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Er bestaan aanzienlijke verschillen tussen de lidstaten.{" "}
              <span style={{ color: "#9c29ff" }}>
                Het huidige minimumtarief voor de BTW binnen de EU is 15%{" "}
              </span>
              (hoog tarief), wat betekent dat geen enkele lidstaat een
              BTW-tarief van minder dan 15% mag toepassen op goederen of
              diensten die niet zijn vrijgesteld van BTW.{" "}
              <span style={{ color: "#9c29ff" }}>
                Het huidige maximale tarief voor de BTW binnen de EU is 27%
              </span>
              , wat betekent dat geen enkele lidstaat een BTW-tarief van meer
              dan 27% mag toepassen. Een verschil van 12%! Het minimum wordt
              niet meer gehanteerd, want Luxemburg heeft het laagste tarief van
              17% (voorheen 15%). De koploper is Hongarije met 27%. Op zich
              heeft u als BTW plichtig rechtspersoon niet direct last van hoge
              BTW tarieven, immers
              <span style={{ color: "#2b5baa" }}>
                {" "}
                u kunt BTW neutraal werken. Het resulteert alleen wel in een
                hoger liquiditeitsbeslag
              </span>
              , omdat u de BTW moet voorfinancieren (tijd tussen betalen van de
              BTW en het uiteindelijk terug ontvangen).
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Het is belangrijk op te merken dat deze minimale en maximale
              tarieven niet verplicht zijn voor alle goederen en diensten. Er
              zijn uitzonderingen en vrijstellingen voor bepaalde goederen en
              diensten die afwijken van de standaardtarieven en in de categorie
              laag-tarief vallen (bijv. 9% voor etenswaren in Nederland).
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle9;
