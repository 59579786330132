import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle3 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Toekomst van de Belgische dieselolie accijnzen vergoedingen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage4.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                30.03.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Wat is de toekomst van de vergoedingen op de Belgische dieselolie
              accijns?
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              In de vorige nieuwsflitsen heeft u kunnen lezen wat de basis is
              van de vergoedingen die lidstaten toekennen en hoe dit
              bijvoorbeeld in België berekend wordt. Maar misschien nog wel
              belangrijker,
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                hoe lang gaat deze regeling nog door?
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Overheden zijn grillig in hun besluiten en je kan er als consument
              of bedrijf op een langere tijd meestal niet van op aan. Toch moet
              u beleid maken voor de toekomst en is het dus van belang om een
              horizon te hebben. Zo ook op het gebied van de dieselolie
              compensatie regelingen, aangezien dat een grote impact heeft op de
              kosten voor de brandstof. Die maken immers een
              <span style={{ color: "#9c29ff" }}> substantieel deel </span>uit
              <span style={{ color: "#9c29ff" }}>
                {" "}
                van de totale kostprijs{" "}
              </span>
              van uw dienstverlening.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              In dat kader kunnen wij u melden dat de belgische overheid wel al
              een doorkijkje heeft gegeven van wat hun plannen zijn. Dit door
              maximum vergoedingen vast te stellen voor de komende (3) jaren.
              Zie de maximum vergoedingen (compensatie) hieronder: <br />
              <br />
              Voor<span style={{ color: "#2B5BAA" }}> 2024</span>: 20,40665 cent
              per liter
              <br />
              Voor
              <span style={{ color: "#2B5BAA" }}> 2025</span>: 20,30665 cent per
              liter <br />
              Voor<span style={{ color: "#2B5BAA" }}> 2026</span>: 20,20665 cent
              per liter
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Zoals u ziet wordt de maximum vergoeding in de loop der jaren
              minder, maar dat is
              <span style={{ color: "#9c29ff" }}>
                {" "}
                bijna verwaarloosbaar (0,1 cent)
              </span>
              . D.w.z. per volle tank van 1000 liter scheelt dat 1 Euro, nml u
              krijgt dan geen EUR 205, maar EUR 204 vergoed. Ik denk dat u daar
              ook geen slapeloze nachten van krijgt, toch?
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle3;
