import React from "react";
import { Grid, Typography, Box, Divider, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link } from "react-router-dom";
import CalculateButton from "./CalculateButton";
import { Hidden } from "@mui/material";
// import VATProduct from "../../assets/icons/VATProduct";

const PricingColumn = (props) => {
  const rows = props.info;

  console.table(props);

  const handleOnClick = (event, title) => {
    // alert(title);
  };

  return (
    <React.Fragment>
      {rows.map((rowInfo) => (
        <Grid
          container
          lg={3}
          md={6}
          xs={12}
          direction="row"
          justifyContent="center"
        >
          {rowInfo.isNew ? (
            <Box
              container
              item
              sx={{
                maxWidth: "340px",
                width: "100%",
                // border: "1px solid grey",
                padding: "10px",
                boxShadow: rowInfo.active ? "" : 0,
                mb: 0,
                ml: 1,
                mr: 1,
                height: "58px",
                position: "relative",
                background: "linear-gradient(to right, #9b29ff, #c85eee)",
                borderRadius: "15px 15px 0px 0px",
              }}
            >
              <Typography
                sx={{
                  color: "#FFFFFF",
                  textAlign: "center",
                  fontSize: "21px",
                  fontFamily: "Century Gothic",
                  fontWeight: "900",
                  mt: 2,
                }}
              >
                Nieuw!
              </Typography>
            </Box>
          ) : (
            ""
          )}
          <Box
            container
            item
            sx={{
              maxWidth: "340px",
              width: "100%",
              // border: "1px solid grey",
              padding: "10px",
              boxShadow: rowInfo.active ? 6 : 3,
              mt: 0,
              "@media (min-width:600px)": {
                mt: rowInfo.isNew ? "" : "78px",
              },
              mb: 2,
              ml: 1,
              mr: 1,
              height: "850px",
              position: "relative",
            }}
          >
            <div>
              <Typography
                sx={{
                  mt: 3,
                  color: rowInfo.isExcise ? "#1c1c1c" : "#2B5BAA",
                  textAlign: "center",
                  fontSize: "29px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Bold",
                }}
              >
                {rowInfo.title}
              </Typography>
              <Typography
                sx={{
                  mb: 4,
                  color: "#6b6a6a",
                  textAlign: "center",
                  fontSize: "21px",
                  fontFamily: "HelveticaNeue-Light",
                  fontWeight: "light",
                }}
              >
                {rowInfo.subTitle}
              </Typography>
              <Divider sx={{ m: 2, mb: 5 }} />
              {rowInfo.mainItems.map((items) => (
                <Grid container md={12} direction="row" sx={{ mb: 1 }}>
                  <Grid
                    container
                    xs={1}
                    sx={{
                      mt: 2,
                    }}
                    justifyContent="center"
                  >
                    <span
                      justifyContent="center"
                      sx={{
                        m: 0,
                        ml: 1,
                      }}
                    >
                      {items.icon}
                    </span>
                  </Grid>
                  <Grid xs={10}>
                    <Typography
                      sx={{
                        m: 0,
                        mt: 1,
                        ml: 1,
                        fontSize: "20px",
                        color: rowInfo.isExcise ? "#1c1c1c" : "#2B5BAA",
                        fontFamily: "HelveticaNeue-Light",
                        fontWeight: "light",
                      }}
                    >
                      {items.item}
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Hidden only="xs">
                      <Tooltip title={items.toolTip} arrow>
                        <InfoOutlinedIcon
                          sx={{
                            m: 0,
                            mt: 2,
                            ml: 1,
                            fontSize: "20px",
                            color: rowInfo.isExcise ? "#1c1c1c" : "#2B5BAA",
                            fontFamily: "HelveticaNeue-Light",
                            fontWeight: "light",
                          }}
                        />
                      </Tooltip>
                    </Hidden>
                  </Grid>
                </Grid>
              ))}
              <br />
              {rowInfo.items.map((items) => (
                <Grid container md={12} direction="row" sx={{ mb: 1 }}>
                  <Grid
                    container
                    xs={1}
                    sx={{
                      mt: 2,
                    }}
                    justifyContent="center"
                  >
                    <span
                      sx={{
                        m: 0,
                      }}
                    >
                      {items.icon}
                    </span>
                  </Grid>
                  <Grid xs={10}>
                    <Typography
                      sx={{
                        m: 0,
                        mt: 1,
                        ml: 1,
                        fontSize: "20px",
                        color: "#6b6a6a",
                        fontFamily: "HelveticaNeue-Light",
                        fontWeight: "light",
                      }}
                    >
                      {items.item}
                    </Typography>
                  </Grid>
                  <Grid xs={1}>
                    <Hidden only="xs">
                      <Tooltip title={items.toolTip} arrow>
                        <InfoOutlinedIcon
                          sx={{
                            m: 0,
                            mt: 2,
                            ml: 1,
                            fontSize: "20px",
                            color: "#6b6a6a",
                            fontFamily: "HelveticaNeue-Light",
                            fontWeight: "light",
                          }}
                        />
                      </Tooltip>
                    </Hidden>
                  </Grid>
                </Grid>
              ))}
            </div>
            {rowInfo.active ? (
              ""
            ) : (
              <Typography
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  bottom: 10,
                  left: 10,
                  color: "#2B5BAA",
                  fontFamily: "HelveticaNeue-Light",
                  fontWeight: "light",
                  textDecoration: "underline",
                }}
                onClick={(e) => handleOnClick(e, rowInfo.title)}
              >
                <Link to={rowInfo.navUrl}>Meer informatie</Link>
              </Typography>
            )}
          </Box>
        </Grid>
      ))}
      <CalculateButton />
    </React.Fragment>
  );
};

export default PricingColumn;
