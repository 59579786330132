import React from "react";
import NewsArticle23 from "../elements/NewsArticle23";
import NewsNav from "../elements/NewsNav";

const NewsDetails23 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle23 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails23;