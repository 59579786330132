import React from "react";
import NewsArticle4 from "../elements/NewsArticle4";
import NewsNav from "../elements/NewsNav";

const NewsDetails4 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle4 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails4;
