import React from "react";
import NewsArticle50 from "../elements/NewsArticle50";
import NewsNav from "../elements/NewsNav";

const NewsDetails50 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle50 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails50;