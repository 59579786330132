import React from "react";
import NewsArticle35 from "../elements/NewsArticle35";
import NewsNav from "../elements/NewsNav";

const NewsDetails35 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle35 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails35;