import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle22 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Bespaar tot €131.900 per aanschaf voertuig met 'AanZET'
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage22.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                17-08-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            In het artikel van vorige week hebben wij u ingelicht over de EIA-, KIA-, MIA- en VAMIL-regeling. 
            Alle regelingen die als doel hebben om de aanschaf van energiebesparende bedrijfsmiddelen financieel 
            aantrekkelijker te maken. In dit artikel hebben we het over de Aanschafsubsidie{" "}
            <span style={{color: "#2b5baa"}}>
            Zero-Emissie Trucks (AanZET){" "}
            </span>
            regeling. Een regeling waarmee u tot wel{" "} 
            <span style={{color: "#9c29ff"}}>
            €131.900 per toekomstige aanschaf van voertuig kunt besparen. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De AanZET regeling is bedoeld om transportondernemingen die een{" "}
            <span style={{ color: "#9c29ff" }}>
            volledig emissieloze (uitstootvrije) vrachtauto {" "}
              </span>
            in voertuigscategorie N2 (vanaf het gewicht van 4.250 kg) of N3 willen {" "}
            <span style={{ color: "#9c29ff" }}>
            aanschaffen, tegemoet te komen.{" "}
              </span>
            <span style={{color: "#2b5baa"}}>
            De hoogte van de subsidie is afhankelijk van de grootte van uw onderneming en de voertuigcategorie
            </span>
            . Over het algemeen geldt hoe kleiner de onderneming- en hoe zwaarder het voertuig des te meer subsidie.
            Het Mkb definieert drie klassengroottes van ondernemingen:
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li> Kleine onderneming: </li>
                    </td>
                    <td>  
                      minder dan 50 man personeel, hoogstens €10 miljoen omzet per jaar en/of een jaarbalans kleiner of gelijk aan €10 miljoen.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <li> Middelgrootte onderneming: </li>
                    </td>
                    <td> 
                      minder dan 250 man personeel, hoogstens €50 miljoen omzet per jaar en/of een jaarbalans kleiner of gelijk aan €50 miljoen.
                    </td>
                  </tr>
                  <tr>
                    <td>
                     <li> Grote onderneming:  </li>
                    </td>
                    <td>
                      meer dan 250 man personeel, meer dan €50 miljoen omzet per jaar en/of een jaarbalans groter dan €50 miljoen.
                    </td>
                  </tr>
                    </ul>
             </tbody>
            </table>
            De voertuigcategorieën bestaan uit: N3 bakwagenchassis tot en met 18.000 kg, N3 bakwagenchassis vanaf 
            18.000 kg en N3 trekker, waarbij de N3 trekker de meeste subsidie ontvangt en de N3 bakwagenchassis tot en met 
            18.000 kg de minste subsidie ontvangt. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In 2023 bedroeg de subsidie tussen de {" "}
            <span style={{ color: "#9c29ff" }}>
            €17.800 en €131.900{" "}
              </span>
            per aangeschaf van voertuig. Om in aanmerking 
            te komen voor de AanZET regeling dient uw aankoop aan de volgende voorwaarden te voldoen: 
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li> De vrachtwagen moet nieuw zijn </li>
                        <li> De vrachtwagen is volledig emissieloos. Brandstofcode E en W komen in aanmerking   </li>
                        <li> De vrachtwagen rijdt alleen met een elektromotor </li>
                        <li> De vrachtwagen valt onder de voertuigclassificaties N2 met een gewicht gelijk aan of groter dan 4.250 kg of N3  </li>
                        <li> Het batterijpakket van de vrachtwagen mag geen lood bevatten </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In tegenstelling tot de EIA-, KIA-, MIA- en VAMIL-regeling waarbij je binnen 3 maanden na je aankoop, 
            dit moet melden bij het RVO, moet je voor de AanZET subsidie de aankoop van tevoren melden om in 
            aanmerking te kunnen komen. Dit doe je door een {" "}
            <span style={{ color: "#9c29ff" }}>
            koop- of financial-leaseovereenkomst zonder onherroepelijke verplichtingen{" "}
              </span>
            af te sluiten. Dit houdt in dat u het aankoop contract nog kunt ontbinden wanneer u geen subsidie krijgt. Heeft u dus 
            recentelijk een voertuig aangeschaft wat voldoet aan de AanZET voorwaarden en wilt u de subsidie nu pas aanvragen, dan 
            vist u helaas achter het net.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Wanneer de AanZET subsidie aan u toegekend wordt, dient u{" "}
            <span style={{color: "#2b5baa"}}>
            binnen een jaar het voertuig te registreren{" "}
            </span>
            en dient u het voertuig{" "} 
            <span style={{color: "#2b5baa"}}>
            minstens 4 jaar in bezit te houden/leasen
            </span>
            . Voldoet u niet aan deze voorwaarden dan is de kans groot dat u later de subsidie weer terug zal moeten betalen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            In 2023 bedroeg het totaalbudget van AanZET €30 miljoen. Een paar dagen na de opening van de aanvraag was er voor €120 miljoen aan subsidie 
            aangevraagd wat resulteerde in een loting waardoor{" "}
            <span style={{ color: "#2b5baa" }}>
            3 op de 4 aanvragen afgewezen{" "}
              </span>
            werden. De komende jaren gaat het{" "}
              <span style={{ color: "#9c29ff" }}>
            budget voor de AanZET regeling omhoog{" "}
              </span>
            met de opkomst van Trucktol 2027 en de voorfinanciering hiervan. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            <span style={{ color: "#2b5baa" }}>
            De eerstvolgende mogelijkheid{" "}
              </span>
            om de AanZET subsidie aan te vragen is{" "}
            <span style={{ color: "#2b5baa" }}>
            9 januari 2024 om 9:00
              </span>
            . Het portaal zal openblijven zolang het budget reikt of tot en met 27 december 2024.  
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Inderdaad, verandering kan vaak uitdagend zijn. Het is echter onvermijdelijk dat we vroeg of laat moeten 
            overstappen naar alternatieve energiebronnen. Wanneer u deze stap zelf maakt, is het belangrijk om{" "}
            <span style={{color: "#2b5baa"}}>regelingen, aftrekposten, afschrijvingen en subsidies{" "}</span>
            te kennen waarvan u kunt profiteren. De combinatie van regelingen die u kiest, hangt af van het type investeringen 
            dat u doet. Bijvoorbeeld, voor de aanschaf van laadpunten op eigen terrein kunt u gebruik maken van de 
            MIA/VAMIL regeling, maar niet van de EIA. Om altijd op de hoogte te blijven van de meest actuele informatie, 
            raden wij u aan om de website van het RVO regelmatig te raadplegen. Zo kunt u optimaal gebruik maken 
            van de beschikbare financiële voordelen bij uw duurzame investeringen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle22;
