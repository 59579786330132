import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle40 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Tsjechië introduceert CO2 toeslag op tol 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage40.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                25-01-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf {" "}
            <span style={{color: "#9c29ff"}}>
            1 maart 2024{" "}
            </span>
            zal het elektronische tolsysteem in Tsjechië een nieuw heffingscomponent introduceren voor zware voertuigen, de hoogte van de{" "}
            <span style={{color: "#2b5baa"}}>
            CO2-uitstoot
            </span>
            . De nieuwe tarieven zullen worden gebaseerd op de volgende vier componenten:{" "}
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li> Huidige toltarief </li>
                        <li> Heffing voor geluidsoverlast   </li>
                        <li> Heffing voor luchtvervuiling (volgens de EURO-norm) </li>
                        <li> Heffing voor CO2-uitstoot <span style={{color: "#2b5baa"}}>(Nieuw) </span> </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Volgens de EU-wetgeving zijn er{" "}
            <span style={{ color: "#9c29ff" }}>
            vijf emissieklassen{" "}
            </span>
            waarbij de 1e emissieklasse het hoogste tarief zal gaan betalen. De 5e emissieklasse is gereserveerd voor voertuigen zonder emissie.            
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De meeste voertuigen die{" "}
            <span style={{ color: "#2b5baa" }}>
            voor 1 juli 2019{" "}
            </span>
            zijn geregistreerd worden automatisch ingedeeld in de{" "}
            <span style={{ color: "#2b5baa" }}>
            1e emissieklasse{" "}
            </span>
            en komen niet in aanmerking voor een hogere emissieklasse waar de tarieven lager zijn. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Transportbedrijven die voertuigen bezitten die{" "}
            <span style={{ color: "#9c29ff" }}>
            na 1 juli 2019{" "}
            </span>
            zijn geregistreerd, wordt geadviseerd om de documentatie van de fabrikant te controleren en de <a href="https://www.mytocz.eu/kalkulace-co2"> emissieklasse-zoeker</a> te gebruiken
            om te achterhalen of hun voertuig opnieuw kan worden toegewezen aan een hogere emissieklasse met een lager toltarief.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Gebruikers hoeven de emissieklasse van voertuigen die na 1 juli 2019 zijn geregistreerd niet te controleren. Ze worden{" "}
            <span style={{ color: "#2b5baa" }}>
            niet bestraft{" "}
            </span>
            voor het gebruik van een voertuig dat in aanmerking komt voor een{" "}
            <span style={{ color: "#2b5baa" }}>
            hogere CO2-emissieklasse{" "}
            </span>
            terwijl deze in de 1e emissieklasse blijft (hoger tarief). Ze kunnen hun voertuig laten inspecteren en op elk moment in de toekomst een {" "}
            <span style={{ color: "#2b5baa" }}>
            aanvraag indienen voor een hogere CO2-emissieklasse
            </span>
            . Er is geen deadline voor het indienen van een elektronische aanvraag. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Niet alle voertuigen die na 1 juli 2019 zijn geregistreerd voldoen aan de eisen die zijn verbonden 
            aan hogere CO2-emissieklassen (2e tot 5e). Dit hangt ervan af of ze voldoen aan de gespecificeerde parameters 
            in de richtlijnen die gecontroleerd kunnen worden met de <a href="https://www.mytocz.eu/kalkulace-co2"> emissieklasse-zoeker</a>. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <span style={{ color: "#9c29ff" }}>
             De specifieke tarieven{" "}
            </span> 
            die aan de verschillende emissieklassen worden gekoppeld{" "}
             <span style={{ color: "#9c29ff" }}>
             zijn nog niet openbaar gemaakt
             </span>
             . Het is raadzaam om nu al de <a href="https://www.mytocz.eu/kalkulace-co2"> emissieklasse-zoeker</a> in te vullen met de gegevens van uw voertuigen. 
             Op die manier kunt u vooraf bepalen onder welke emissieklasse uw voertuig valt en voorkomt u onaangename verrassingen vanaf 1 maart 2024. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle40;
