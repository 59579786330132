import React from "react";
import NewsArticle25 from "../elements/NewsArticle25";
import NewsNav from "../elements/NewsNav";

const NewsDetails25 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle25 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails25;