import {
  Container,
  Typography,
  Box,
  Stack,
  // alpha,
  TextField,
} from "@mui/material";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import Image from "mui-image";
import Rondje1 from "../../assets/Rondje 1.png";
import Rondje2 from "../../assets/Rondje 2.png";
import Rondje3 from "../../assets/Rondje 3.png";
import Rondje4 from "../../assets/Rondje 4.png";
import Rondje5 from "../../assets/Rondje 5.png";
import NLVlag from "../../assets/NL vlag.png";
import ATVlag from "../../assets/AT vlag.png";
import BEVlag from "../../assets/BE vlag.png";
import FRVlag from "../../assets/FR vlag.png";
import LUVlag from "../../assets/LU vlag.png";
import DEVlag from "../../assets/DE vlag.png";
import ESVlag from "../../assets/ES vlag.png";
import ITVlag from "../../assets/IT vlag.png";
import PTVlag from "../../assets/PT vlag.png";
import DKVlag from "../../assets/DK vlag.png";
import FIVlag from "../../assets/FI vlag.png";
import SEVlag from "../../assets/SE vlag.png";
import IEVlag from "../../assets/IE vlag.png";
import UKVlag from "../../assets/UK vlag.png";
import CZVlag from "../../assets/CZ vlag.png";
import SIVlag from "../../assets/SI vlag.png";
import CHVlag from "../../assets/CH vlag.png";
import HRVlag from "../../assets/HR vlag.png";
import ELVlag from "../../assets/EL vlag.png";
import HUVlag from "../../assets/HU vlag.png";
import PLVlag from "../../assets/PL vlag.png";
import ROVlag from "../../assets/RO vlag.png";
import LTVlag from "../../assets/LT vlag.png";
import LVVlag from "../../assets/LV vlag.png";
import SKVlag from "../../assets/SK vlag.png";
import BGVlag from "../../assets/BG vlag.png";
import EEVlag from "../../assets/EE vlag.png";

// import { ReactComponent as Stap1 } from "../assets/Rondje 1.svg";
import Button from "@mui/material/Button/Button";
// import { ForkLeft } from "@mui/icons-material";
// import { display, margin } from "@mui/system";
import { green } from "@mui/material/colors";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import { height } from "@mui/system";

// const handleTypeButtonClick = (e) => {
//   console.table(e);
// };

const lists = [
  { id: 1, title: "Delta Petrol", subTitle: "Normal refund" },
  { id: 2, title: "Delta Hybrid", subTitle: "Flexible refund" },
  { id: 3, title: "Delta Electric", subTitle: "Fast refund" },
];
const exciseAllowButtonlists = [
  { id: 1, title: "Ja" },
  { id: 2, title: "Nee" },
];
const flagLists = [
  { id: 1, countryCode: "NL", flag: NLVlag },
  { id: 2, countryCode: "BE", flag: BEVlag },
  { id: 3, countryCode: "FR", flag: FRVlag },
  { id: 4, countryCode: "AT", flag: ATVlag },
  { id: 5, countryCode: "LU", flag: LUVlag },
  { id: 6, countryCode: "DE", flag: DEVlag },
  { id: 7, countryCode: "ES", flag: ESVlag },
  { id: 8, countryCode: "IT", flag: ITVlag },
  { id: 9, countryCode: "PT", flag: PTVlag },
  { id: 10, countryCode: "DK", flag: DKVlag },
  { id: 12, countryCode: "FI", flag: FIVlag },
  { id: 13, countryCode: "SE", flag: SEVlag },
  { id: 14, countryCode: "IE", flag: IEVlag },
  { id: 15, countryCode: "UK", flag: UKVlag },
  { id: 16, countryCode: "CZ", flag: CZVlag },
  { id: 17, countryCode: "SI", flag: SIVlag },
  { id: 18, countryCode: "CH", flag: CHVlag },
  { id: 19, countryCode: "HR", flag: HRVlag },
  { id: 20, countryCode: "EL", flag: ELVlag },
  { id: 21, countryCode: "HU", flag: HUVlag },
  { id: 22, countryCode: "PL", flag: PLVlag },
  { id: 23, countryCode: "RO", flag: ROVlag },
  { id: 24, countryCode: "LT", flag: LTVlag },
  { id: 25, countryCode: "LV", flag: LVVlag },
  { id: 26, countryCode: "SK", flag: SKVlag },
  { id: 27, countryCode: "BG", flag: BGVlag },
  { id: 28, countryCode: "EE", flag: EEVlag },
];

const PriceCalculation = () => {
  const [selected, setSelected] = React.useState(0);
  const [selectedExcise, setSelectedExcise] = React.useState(0);
  const [selectedFlag, setSelectedFlag] = React.useState([]);
  const [step2Disabled, setStep2Disabled] = React.useState(1);
  const [allowedFlagIndexes, setAllowedFlagIndexes] = React.useState([]);
  useEffect(() => {
    handleFlags(0);
  }, []);
  // let selectedFlag = [];

  const handleColor = (row) => {
    setSelected(row.id);
    handleFlags(row.id);
    setStep2Disabled(false);
  };

  const handleAllowExciseClick = (row) => {
    setSelectedExcise(row.id);
  };

  const handleFlagClick = (row) => {
    // handle selection
    if (selectedFlag.includes(row)) {
      console.log("true");
      setSelectedFlag(selectedFlag.filter((flag) => flag !== row));
    } else {
      console.log("false");
      setSelectedFlag((selectedFlag) => [...selectedFlag, row]);
    }

    // Show TextFields step 3
    handleTextFieldsStep3(row);

    console.log(row.countryCode);
    console.table(selectedFlag);
  };

  const handleTextFieldsStep3 = (row) => {};

  const handleFlags = (id) => {
    console.log(id);
    switch (id) {
      case 1:
        console.log("Case 1");
        setAllowedFlagIndexes([
          "NL",
          "BE",
          "FR",
          "AT",
          "LU",
          "DE",
          "ES",
          "IT",
          "PT",
          "DK",
          "FI",
          "SE",
          "IE",
          "UK",
          "CZ",
          "SI",
          "CH",
          "HR",
          "EL",
          "HU",
          "PL",
          "RO",
          "LT",
          "LV",
          "SK",
          "BG",
          "EE",
        ]);
        break;
      case 2:
        console.log("Case 2");
        setAllowedFlagIndexes([
          "NL",
          "BE",
          "FR",
          "AT",
          "LU",
          "DE",
          "ES",
          "IT",
          "PT",
          "DK",
          "FI",
          "SE",
          "IE",
          "UK",
          "CZ",
          "SI",
          "CH",
          "HR",
          "EL",
          "HU",
          "PL",
          "RO",
          "LT",
          "LV",
          "SK",
          "BG",
          "EE",
        ]);
        break;
      case 3:
        console.log("Case 3");
        setAllowedFlagIndexes([
          "NL",
          "BE",
          "FR",
          "AT",
          "LU",
          "DE",
          "ES",
          "IT",
          "PT",
          "DK",
          "FI",
          "SE",
          "IE",
          "UK",
          "CZ",
        ]);
        break;

      case 0:
        setAllowedFlagIndexes([
          "NL",
          "BE",
          "FR",
          "AT",
          "LU",
          "DE",
          "ES",
          "IT",
          "PT",
          "DK",
          "FI",
          "SE",
          "IE",
          "UK",
          "CZ",
          "SI",
          "CH",
          "HR",
          "EL",
          "HU",
          "PL",
          "RO",
          "LT",
          "LV",
          "SK",
          "BG",
          "EE",
        ]);
        break;
      default:
        break;
    }
  };

  // console.table(allowedFlagIndexes);

  const [countryLiters, setCountryLiters] = React.useState([]);
  const [allowExcise, setAllowExcise] = React.useState(false);

  const handleOnchangeLiters = (e) => {
    const {
      target: { name, value },
    } = e;

    // setCountryLiters[{}];

    // let tempCL = countryLiters.filter(
    //   (countryLiter) => countryLiter.countryCode === name
    // );

    if (
      countryLiters.filter((countryLiter) => countryLiter.countryCode === name)
        .length > 0
    ) {
      console.log("true");
      setCountryLiters(
        countryLiters.map((countryLiter) =>
          countryLiter.countryCode === name
            ? {
                ...countryLiter,
                liters: value,
              }
            : { ...countryLiter }
        )
      );
      // setSelectedFlag(selectedFlag.filter((flag) => flag !== row));
    } else {
      // setCountryLiters([{ countryCode: "NL", liters: 544 }]);
      setCountryLiters((countryLiters) => [
        ...countryLiters,
        { countryCode: name, liters: value },
      ]);
      console.log("false");

      // setSelectedFlag((selectedFlag) => [...selectedFlag, row]);
    }

    var tempBE = countryLiters.filter(
      (countryLiter) => countryLiter.countryCode === "BE"
    );
    if (tempBE.length > 0 || name === "BE") {
      setAllowExcise(false);
      if (name === "BE" && value >= 200) {
        setAllowExcise(true);
      }
      if (tempBE[0].liters >= 200) {
        setAllowExcise(true);
      }
      console.log(allowExcise);
    }

    // if (
    //   countryLiters.filter(
    //     (countryLiter) => countryLiter.countryCode === "BE"
    //   ) &&
    //   countryLiters.filter((countryLiter) => countryLiter.countryCode === "BE")
    //     .liters === "200"
    // ) {
    //   //enable Excise button
    //   console.log("Yes Excise");
    // } else {
    //   console.log("No Excise");
    // }
    // console.log(
    //   countryLiters.filter((countryLiter) => countryLiter.countryCode === "BE")
    //     .liters
    // );
    console.table(countryLiters);
    console.log(name);
    console.log(value);
  };

  const [countryTol, setCountryTol] = React.useState([]);

  const handleOnchangeTol = (e) => {
    const {
      target: { name, value },
    } = e;

    // setCountryLiters[{}];

    // let tempCL = countryTol.filter((ctl) => ctl.countryCode === name);

    if (countryTol.filter((ctl) => ctl.countryCode === name).length > 0) {
      console.log("true");
      setCountryTol(
        countryTol.map((ctl) =>
          ctl.countryCode === name
            ? {
                ...ctl,
                vat: value,
              }
            : { ...ctl }
        )
      );
      // setSelectedFlag(selectedFlag.filter((flag) => flag !== row));
    } else {
      // setCountryLiters([{ countryCode: "NL", liters: 544 }]);
      setCountryTol((ctl) => [...ctl, { countryCode: name, vat: value }]);
      console.log("false");

      // setSelectedFlag((selectedFlag) => [...selectedFlag, row]);
    }

    console.table(countryTol);
    console.log(name);
    console.log(value);
  };
  // const [exciseAllowButton, setCountryTol] = React.useState([]);
  // const handleExciseYesNo = (allow) => {
  //   alert(allow);
  // };

  const handleCalculateNow = () => {
    alert("What to do here?");
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" sx={{ mt: 5 }}>
        <Typography
          sx={{
            mt: 5,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: "71px",
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
          }}
        >
          Bereken uw prijs
        </Typography>
        <Typography
          mb={5}
          sx={{
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: "21px",
            fontFamily: "Century Gothic",
            fontWeight: "Regular",
          }}
        >
          Let op! We kunnen alleen per B.V. opereren.
        </Typography>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid
            display="flex"
            direction="column"
            item
            md={2.4}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Image img src={Rondje1} alt="cur" height={44} width={44} />
            <Typography
              sx={{
                color: "#2B5BAA",
                fontSize: "17px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
                marginTop: "15px",
              }}
            >
              Kies uw pakket
            </Typography>
            <Typography
              height="25px"
              sx={{
                color: "#2B5BAA",
                fontSize: "12px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
              }}
            >
              u kunt altijd later switchen
            </Typography>
            <Stack direction="column">
              {/* {lists.map((list) => ( */}

              {lists.map((list) => (
                <Button
                  key={list.id}
                  onClick={() => handleColor(list)}
                  sx={{
                    my: 2,
                    // color: "primary",
                    display: "block",
                    fontSize: "20px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                    textTransform: "none",
                    width: "261px",
                    height: "49",
                    border: "1px solid #2B5BAA",
                    borderRadius: "9px",
                    backgroundColor:
                      list.id === selected ? "#2B5BAA" : "#FFFFFF",
                    color: list.id === selected ? "#FFFFFF" : "#2B5BAA",
                    ":hover": {
                      bgcolor: "#2B5BAA", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      //   color: "#2B5BAA",

                      fontSize: "21px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    {list.title}
                  </Typography>
                  <Typography
                    sx={{
                      //   color: "#2B5BAA",

                      fontSize: "12px",
                      fontFamily: "HelveticaNeue-Light",
                      fontWeight: "Light",
                    }}
                  >
                    {list.subTitle}
                  </Typography>
                </Button>
              ))}
            </Stack>
          </Grid>
          {/* Step2 */}
          <Grid
            display="flex"
            direction="column"
            item
            md={2.4}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Image img src={Rondje2} alt="cur" height={44} width={44} />
            <Typography
              sx={{
                color: "#2B5BAA",
                fontSize: "17px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
                marginTop: "15px",
              }}
            >
              In welke landen tankt u?
            </Typography>
            <Typography
              height="35px"
              sx={{
                color: "#2B5BAA",
                fontSize: "12px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
              }}
            >
              (landen buiten uw MSE land)
            </Typography>
            <Stack direction="column">
              <Box xs={{ display: "flex", alignItems: "left" }}>
                {flagLists.map((flagList) =>
                  allowedFlagIndexes.includes(flagList.countryCode) ? (
                    <Button
                      onClick={() => handleFlagClick(flagList)}
                      disabled={step2Disabled ? true : false}
                      sx={{
                        my: 2,
                        m: 0.5,
                        variant: "contained",
                        fontSize: "20px",
                        fontFamily: "Century Gothic",
                        fontWeight: "Bold",
                        textTransform: "none",
                        width: "80px",
                        height: "38px",
                        border: "1px solid #2B5BAA",
                        padding: 0,
                        borderRadius: "19px",
                        opacity: step2Disabled ? 0.4 : 1,
                        filter: step2Disabled
                          ? "alpha((opacity = 40))"
                          : "none",
                        backgroundColor: step2Disabled
                          ? "#000"
                          : selectedFlag.includes(flagList)
                          ? "#2B5BAA"
                          : "#FFFFFF",
                        color: step2Disabled
                          ? "#FFF"
                          : selectedFlag.includes(flagList)
                          ? "#FFFFFF"
                          : "#2B5BAA",
                        ":hover": {
                          bgcolor: "#2B5BAA", // theme.palette.primary.main
                          color: "white",
                        },
                        justifyContent: "left",
                      }}
                    >
                      <div style={{ borderRadius: "50%" }}>
                        <Image
                          sx={{
                            minHeight: 0,
                            minWidth: 0,
                            padding: 0,
                          }}
                          img
                          src={flagList.flag}
                          alt="cur"
                          height={36}
                          width={36}
                        />
                      </div>
                      <Typography
                        sx={{
                          color: "inherit",
                          fontSize: "17px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Regular",
                        }}
                      >
                        &nbsp; {flagList.countryCode}
                      </Typography>
                    </Button>
                  ) : (
                    ""
                  )
                )}
              </Box>
            </Stack>
          </Grid>
          {/* Step3 */}
          <Grid
            display="flex"
            direction="column"
            item
            md={2.4}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Image img src={Rondje3} alt="cur" height={44} width={44} />
            <Typography
              height="65px"
              sx={{
                color: "#2B5BAA",
                fontSize: "17px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
                textAlign: "center",
                marginTop: "15px",
              }}
            >
              Hoeveel liter tankt u per land op jaarbasis?
            </Typography>
            {/* <Typography
              sx={{
                color: "#2B5BAA",
                fontSize: "12px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
              }}
            >
              (landen buiten uw MSE land)
            </Typography> */}
            {selectedFlag.length <= 0 ? (
              <TextField
                type="tel"
                id="input-with-icon-textfield"
                name=""
                // label="TextField"
                variant="outlined"
                disabled={true}
                // onChange={handleOnchangeTol}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      direction="row"
                      height="38"
                      width="240px"
                      sx={{ padding: "0", verticalAlign: "middle" }}
                    >
                      <Image
                        img
                        src={NLVlag}
                        alt="cur"
                        height={38}
                        width={38}
                      />
                      <Typography
                        sx={{
                          color: "#bdbdbd",
                          fontSize: "17px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Regular",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        Liters:
                      </Typography>
                    </Box>
                  ),
                  sx: {
                    padding: "0",
                    fontSize: "11",
                    alignItems: "flex-start",
                  },
                }}
                sx={{
                  mb: 1,
                  "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "7px",
                  },
                  "& .css-1i53u6i-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "19px",
                  },
                  ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "8px",
                    color: "#2B5BAA",
                    fontSize: "17px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Regular",
                  },
                  fontSize: "17px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Regular",
                }}
                // placeholder="Enter image caption..."
              />
            ) : null}
            {selectedFlag.map((flag) => (
              <TextField
                type="tel"
                id="input-with-icon-textfield"
                name={flag.countryCode}
                // label="TextField"
                variant="outlined"
                onChange={handleOnchangeLiters}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      direction="row"
                      height="38"
                      width="150px"
                      sx={{ padding: "0", verticalAlign: "middle" }}
                    >
                      <Image
                        img
                        src={flag.flag}
                        alt="cur"
                        height={38}
                        width={38}
                      />
                      <Typography
                        sx={{
                          color: "#2B5BAA",
                          fontSize: "17px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Regular",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        Liters:
                      </Typography>
                    </Box>
                  ),
                  sx: {
                    padding: "0",
                    fontSize: "11",
                    alignItems: "flex-start",
                  },
                }}
                sx={{
                  mb: 1,
                  "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "7px",
                  },
                  "& .css-1i53u6i-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "19px",
                  },
                  ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "8px",
                    color: "#2B5BAA",
                    fontSize: "17px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Regular",
                  },
                  fontSize: "17px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Regular",
                  borderColor: green,
                }}
                // placeholder="Enter image caption..."
              />
            ))}
          </Grid>
          {/* Step4 */}
          <Grid
            display="flex"
            direction="column"
            item
            md={2.4}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Image img src={Rondje4} alt="cur" height={44} width={44} />
            <Typography
              height="65px"
              sx={{
                color: "#2B5BAA",
                fontSize: "17px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
                marginTop: "15px",
              }}
            >
              Hoeveel BTW aan tol betaald u per land op jaarbasis?
            </Typography>
            {/* <Typography
              sx={{
                color: "#2B5BAA",
                fontSize: "12px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
              }}
            >
              (landen buiten uw MSE land)
            </Typography> */}
            {selectedFlag.length <= 0 ? (
              <TextField
                type="tel"
                id="input-with-icon-textfield"
                name=""
                // label="TextField"
                variant="outlined"
                disabled={true}
                // onChange={handleOnchangeTol}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      direction="row"
                      height="38"
                      width="240px"
                      sx={{ padding: "0", verticalAlign: "middle" }}
                    >
                      <Image
                        img
                        src={NLVlag}
                        alt="cur"
                        height={38}
                        width={38}
                      />
                      <Typography
                        sx={{
                          color: "#bdbdbd",
                          fontSize: "17px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Regular",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        BTW (tol):
                      </Typography>
                    </Box>
                  ),
                  sx: {
                    padding: "0",
                    fontSize: "11",
                    alignItems: "flex-start",
                  },
                }}
                sx={{
                  mb: 1,
                  "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "7px",
                  },
                  "& .css-1i53u6i-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "19px",
                  },
                  ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "8px",
                    color: "#2B5BAA",
                    fontSize: "17px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Regular",
                  },
                  fontSize: "17px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Regular",
                }}
                // placeholder="Enter image caption..."
              />
            ) : null}
            {selectedFlag.map((flag) => (
              <TextField
                type="tel"
                id="input-with-icon-textfield"
                name={flag.countryCode}
                // label="TextField"
                variant="outlined"
                onChange={handleOnchangeTol}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      direction="row"
                      height="38"
                      width="240px"
                      sx={{ padding: "0", verticalAlign: "middle" }}
                    >
                      <Image
                        img
                        src={flag.flag}
                        alt="cur"
                        height={38}
                        width={38}
                      />
                      <Typography
                        sx={{
                          color: "#2B5BAA",
                          fontSize: "17px",
                          fontFamily: "Century Gothic",
                          fontWeight: "Regular",
                          marginTop: "7px",
                          marginLeft: "5px",
                        }}
                      >
                        BTW (tol):
                      </Typography>
                    </Box>
                  ),
                  sx: {
                    padding: "0",
                    fontSize: "11",
                    alignItems: "flex-start",
                  },
                }}
                sx={{
                  mb: 1,
                  "& .css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "7px",
                  },
                  "& .css-1i53u6i-MuiInputBase-root-MuiOutlinedInput-root": {
                    borderRadius: "19px",
                  },
                  ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "0px",
                    paddingTop: "8px",
                    color: "#2B5BAA",
                    fontSize: "17px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Regular",
                  },
                  fontSize: "17px",
                  fontFamily: "Century Gothic",
                  fontWeight: "Regular",
                }}
                // placeholder="Enter image caption..."
              />
            ))}
          </Grid>
          {/* Step5 */}
          <Grid
            display="flex"
            direction="column"
            item
            md={2.4}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Image img src={Rondje5} alt="cur" height={44} width={44} />
            <Typography
              height="65px"
              sx={{
                color: "#2B5BAA",
                fontSize: "17px",
                fontFamily: "Century Gothic",
                fontWeight: "Regular",
                marginTop: "15px",
              }}
            >
              Bent u geintereseerd in diesel-
              <br />
              olie accijnzen terugvoordering?
            </Typography>
            <Stack direction="column">
              {/* {lists.map((list) => ( */}

              {exciseAllowButtonlists.map((list) => (
                <Button
                  key={list.id}
                  onClick={() => handleAllowExciseClick(list)}
                  sx={{
                    my: 2,
                    mt: 0,
                    // color: "primary",
                    display: "block",
                    fontSize: "20px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                    textTransform: "none",
                    width: "261px",
                    height: "49",
                    border: "1px solid #2B5BAA",
                    borderRadius: "9px",
                    backgroundColor:
                      list.id === selectedExcise ? "#2B5BAA" : "#FFFFFF",
                    color: list.id === selectedExcise ? "#FFFFFF" : "#2B5BAA",
                    ":hover": {
                      bgcolor: "#2B5BAA", // theme.palette.primary.main
                      color: "white",
                    },
                  }}
                  disabled={allowExcise ? false : true}
                >
                  <Typography
                    sx={{
                      //   color: "#2B5BAA",

                      fontSize: "21px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    {list.title}
                  </Typography>
                </Button>
              ))}
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mt: 20 }}>
          <Grid
            display="flex"
            direction="column"
            item
            md={12}
            xs={12}
            sx={{
              alignItems: "center",
            }}
          >
            <Button
              display="flex"
              onClick={handleCalculateNow}
              // variant="Outlined"
              sx={{
                // my: 0,
                backgroundColor: "#C85EEE",
                "&:hover": {
                  backgroundColor: "#9d49bb",
                },
                color: "#FFFFFF",
                width: 304,
                height: 86,
                borderRadius: 35,
                fontSize: "29px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Nu Berekenen
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default PriceCalculation;
