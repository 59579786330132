import { Grid, Stack, Typography, Container, Box } from "@mui/material";
import Image from "mui-image";
import React from "react";
// import background1 from "../../assets/delltapetrol.jpg";
import liveInzicht from "../../assets/Liveinzicht.png";
import Automatisering from "../../assets/Automatisering.png";
import Zekerheid from "../../assets/Zekerheid.png";
import Zorgenloos from "../../assets/Zorgenloos.png";
import Abonnement from "../../assets/Abonnement.png";
import DuidelijkeAflettering from "../../assets/DuidelijkeAflettering.png";
// import PreFinanceIcon from "../../assets/icons/PreFinanceIcon.png";
import FlexiblePreFinanceIcon2xIcon from "../../assets/icons/FlexiblePreFinanceIcon.png";
// import FinancingIcon from "../../assets/icons/FinancingIcon.png";
import Binnen10Werkdagen2xIcon from "../../assets/icons/Binnen10Werkdagen.png";
import PreFinanceFrequency2xIcon from "../../assets/icons/PreFinanceFrequencyIcon@2x.png";
import InvoicingOverview2xIcon from "../../assets/icons/InvoicingOverview2xIcon.png";
import WithinOneWorkingDay2xIcon from "../../assets/icons/WithinOneWorkingDay2xIcon.png";
import TransparentBookKeeping2xIcon from "../../assets/icons/TransparentBookKeeping2xIcon.png";

const InfoCards = (props) => {
  return (
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: "#FAFCFF",
      }}
    >
      <Container maxWidth="xl">
        <Grid container sx={{ pt: 10, pb: 10, backgroundColor: "#FAFCFF" }}>
          {props.deltaPetrolActive ? (
            <>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={liveInzicht}
                      alt="cur"
                      height={25}
                      width={41}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Live inzicht
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U kunt 24/7 de actieve status van uw vordering volgen via het
                  klantenportaal
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Automatisering}
                      alt="cur"
                      height={29}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Automatisering
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Al uw facturen worden automatisch geïntegreerd in ons systeem
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zekerheid}
                      alt="cur"
                      height={33}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zekerheid
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal
                  ontvangen
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>{" "}
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zorgenloos}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zorgenloos
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Wij zorgen ervoor dat de nodige opstart documenten snel en
                  zonder zorgen compleet gemaakt worden
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Abonnement}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Abonnement
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Kosten op abonnements- basis zodat u elke maand weet waar u
                  aan toe bent
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={DuidelijkeAflettering}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Duidelijke aflettering
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U krijgt het volledige BTW bedrag teruggestort op uw rekening.
                  Zo blijft uw boekhouding altijd op orde
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>
            </>
          ) : null}

          {props.deltaHybridActive ? (
            <>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={liveInzicht}
                      alt="cur"
                      height={25}
                      width={41}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Live inzicht
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U kunt 24/7 de actieve status van uw vordering volgen via het
                  klantenportaal
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Automatisering}
                      alt="cur"
                      height={29}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Automatisering
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Al uw facturen worden automatisch geïntegreerd in ons systeem
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zekerheid}
                      alt="cur"
                      height={33}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zekerheid
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal
                  ontvangen
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>{" "}
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zorgenloos}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zorgenloos
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Wij zorgen ervoor dat de nodige opstart documenten snel en
                  zonder zorgen compleet gemaakt worden
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Abonnement}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Abonnement + fee
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Kosten op abonnements- basis voor een stabiele cashflow + een
                  fee voor eventueel voorfinanciering
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={TransparentBookKeeping2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Heldere boekhouding
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U krijgt in het klantenportaal een duidelijk overzicht van de
                  voorgefinancierde bedragen en de resterende kredietruimte
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50, height: "100%" }}
                    justifyContent="center"
                    alignItems="start"
                    // backgroundColor="yellow"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={FlexiblePreFinanceIcon2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Flexibel voorfinancieren
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  De basis is normal refund, via het klantenportaal kunt u
                  echter voorfinanciering aanvragen voor het gewenste bedrag
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={WithinOneWorkingDay2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Binnen 1 werkdag
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Binnen 1 werkdag na aanvraag maken wij het door u gewenste
                  bedrag over op uw rekening
                </Typography>
              </Grid>
              <Grid item lg={3} md={0}></Grid>
              <Grid item lg={1.5} md={0}></Grid>
            </>
          ) : null}

          {props.deltaElectricActive ? (
            <>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={liveInzicht}
                      alt="cur"
                      height={25}
                      width={41}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Live inzicht
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U kunt 24/7 de actieve status van uw vordering volgen via het
                  klantenportaal
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: { xs: 2, md: 2, lg: 3 } }}
                      img
                      src={Automatisering}
                      alt="cur"
                      height={29}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Automatisering
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Al uw facturen worden automatisch geïntegreerd in ons systeem
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zekerheid}
                      alt="cur"
                      height={33}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zekerheid
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal
                  ontvangen
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>{" "}
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zorgenloos}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zorgenloos
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Wij zorgen ervoor dat de nodige opstart documenten snel en
                  zonder zorgen compleet gemaakt worden
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Abonnement}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Abonnement + fee
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Kosten op abonnements- basis voor een stabiele cashflow + een
                  fee voor voorfinanciering
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={InvoicingOverview2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Facturatie overzicht
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U krijgt bij onze elektronische commissie factuur altijd een
                  overzicht van de voorgefinancierde facturen
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={FlexiblePreFinanceIcon2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Vast voorfinancieren
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  De basis is altijd voorfinanciering, zo houdt u altijd uw
                  liquiditeit op orde
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Binnen10Werkdagen2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Binnen 10 werkdagen
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Binnen 10 werkdagen maken wij het voor te financieren bedrag
                  over op uw rekening
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={PreFinanceFrequency2xIcon}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Financieringsfrequentie
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Keuze uit direct, maandelijks of per kwartaal, afhankelijk van
                  uw behoefte
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>
            </>
          ) : null}

          {props.deltaDieselActive ? (
            <>
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={liveInzicht}
                      alt="cur"
                      height={25}
                      width={41}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Live inzicht
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  U kunt 24/7 de actieve status van uw vordering volgen via het
                  klantenportaal
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Automatisering}
                      alt="cur"
                      height={29}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Automatisering
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Al uw facturen worden automatisch geïntegreerd in ons systeem
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zekerheid}
                      alt="cur"
                      height={33}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zekerheid
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Altijd ervan verzekerd dat u optimaal van de accijns
                  compensatie regelingen gebruik maakt
                </Typography>
              </Grid>
              <Grid item lg={1.5} md={0}></Grid>{" "}
              <Grid item lg={1.5} md={0}></Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Zorgenloos}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Zorgenloos
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Wij zorgen ervoor dat de nodige opstart documenten snel en
                  zonder zorgen compleet gemaakt worden
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} sm={6} xs={12}>
                <Stack direction="row">
                  <Box
                    display="flex"
                    sx={{ width: 50 }}
                    justifyContent="center"
                  >
                    <Image
                      sx={{ mt: 3 }}
                      img
                      src={Abonnement}
                      alt="cur"
                      height={28}
                      width={28}
                    />
                  </Box>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#2B5BAA",
                      textAlign: "left",
                      fontSize: { xs: "23px", md: "26px", xl: "29px" },
                      lineHeight: { xs: "26.8px", md: "31.8px", xl: "34.8px" },
                      letterSpacing: "0px",
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                    }}
                  >
                    Abonnement
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    mt: 1,
                    ml: 6.4,
                    mr: 9,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "20px", xl: "21px" },
                    lineHeight: { xs: "32px", md: "35px", xl: "36px" },
                    letterSpacing: "-0.27px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Kosten op abonnements- basis zodat u elke maand weet waar u
                  aan toe bent
                </Typography>
              </Grid>
              <Grid item mt={5} lg={3} md={0}></Grid>
              <Grid item lg={1.5} md={0}></Grid>
            </>
          ) : null}
        </Grid>
      </Container>
    </Container>
  );
};

export default InfoCards;
