// import { Container } from "@mui/material";
import React from "react";
import CoreActivities from "../elements/CoreActivities";
import CoreValues from "../elements/CoreValues";
import ThirtyPlusYearsOfExperience from "../elements/ThirtyPlusYearsOfExperience";
import UniqueInOurBranche from "../elements/UniqueInOurBranche";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const OverDelta = () => {
  return (
    <>
      <CoreActivities />
      <ThirtyPlusYearsOfExperience />
      <UniqueInOurBranche />
      <CoreValues />
    </>
  );
};

export default OverDelta;
