import React from "react";
import BannerPricingDeltaHybrid from "../elements/BannerPricingDeltaHybrid";
import InfoCards from "../elements/InfoCards";
import MostAskedQuestions from "../elements/MostAskedQuestions";
import PricingTable from "../elements/PricingTable";
import ProductNav from "../elements/ProductNav";
// import QuestionsDeltaHybrid from "../elements/QuestionsDeltaHybrid";
import Werkwijze from "../elements/Werkwijze";
import Banner02 from "../pages/Banner02";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const DeltaHybrid = () => {
  const questions = [
    {
      question: "Waarom zou ik Delta Hybrid kiezen ipv. Petrol?",
      answer:
        "U betaalt niets extra voor Delta Hybrid, maar profiteert in voorkomende gevallen wel van de voorfinanciering optie.",
    },
    {
      question: "Hoe vraag ik bij Delta Hybrid voorfinanciering aan?",
      answer:
        "Zodra er een mutaties in uw vorderingen plaatsvindt, dan lichten wij u in via het kanaal naar uw keuze. In het bericht kunt u aangeven dat u en in welke mate u voorgefinancierd wilt worden.",
    },
    {
      question: "Hoe snel heb ik bij Delta Hybrid voorfinanciering?",
      answer:
        "Na het verzoek tot voorfinancieren, maken wij het gewenste bedrag binnen 1 werkdag over. Toekenning van voorfinanciering is onderhevig aan verificatie door Delta.",
    },
    {
      question: "Waarom een tarifering op abonnementsbasis?",
      answer:
        "Met een abonnement weet u waar u elke maand aan toe bent en leidt tot stabiele cashflow. Een abonnement is ook iets voordeliger geprijsd ten opzichte van de traditionele no-cure-no-pay methode. Let op, bij Delta Hybrid komt er bij keuze naar voorfinanciering een toegevoegde fee over het gewenste bedrag in de vorm van een percentage. Vraag naar onze tarieven.",
    },
    {
      question: "Wat is de looptijd van een abonnement?",
      answer: "Dat varieert van 1 tot 5 jaar.",
    },
  ];

  return (
    <>
      <ProductNav deltaHybridActive />
      <Banner02 />
      <InfoCards deltaHybridActive />
      <Werkwijze deltaHybridActive />
      <BannerPricingDeltaHybrid />
      <MostAskedQuestions Questions={questions} />
      <PricingTable deltaHybridActive />
    </>
  );
};

export default DeltaHybrid;
