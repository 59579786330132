import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle6 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Estland verhoogt BTW tarief naar 22%
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage6.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                13.04.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              BTW is een indirecte belasting die uiteindelijk door de consument
              (particulier) wordt betaald. Een algemene verbruiksbelasting die
              wordt geheven op de toegevoegde waarde van goederen en diensten.
              Bedrijven voegen voortdurend waarde toe aan een product in de
              bedrijfskolom, wat resulteert in een waardevermeerdering (B2B).
              Hoewel de laatste schakel (bij consumenten de detailhandel) de BTW
              afstaat aan de staat,
              <span style={{ color: "#9c29ff" }}>
                {" "}
                kan de consument de BTW niet terugvorderen
              </span>
              . Dit genereert inkomsten voor de staat. En behoorlijk wat ook! De
              hoogte van de BTW varieert namelijk per land en soms zelfs per
              type product of dienst. Denk aan de huidige discussie over
              bijvoorbeeld het BTW tarief op groente en fruit. In de
              <span style={{ color: "#2B5BAA" }}> Europese Unie </span>varieert
              het
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                BTW-tarief tussen de 17% en 27%
              </span>
              , afhankelijk van het land en het soort product of dienst.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#9c29ff" }}>
                Estland heeft besloten het hoge BTW tarief aan te gaan passen{" "}
              </span>
              van (momenteel)
              <span style={{ color: "#2B5BAA" }}> 20% naar 22%</span>. 2% extra
              inkomsten voor de Estse staat, dat is aanzienlijk! Dit zal plaats
              gaan vinden op 1 januari 2024. We kunnen dus nog even “goedkoper”
              shoppen in Estland mochten we dat willen.
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#2B5BAA" }}>
                Heeft deze wijziging dan invloed op u na 1 januari 2024?{" "}
              </span>
              Ja en nee. Bedrijven werken BTW neutraal. De BTW die u ontvangt,
              draagt u af en de BTW die u betaalt, krijgt u terug. Duurder zal
              het voor u niet worden om te tanken in Estland. Waar u wel last
              van gaat krijgen is dat u een hoger BTW bedrag tijdelijk kwijt zal
              zijn, tot de Estlandse belastingdienst deze u terug betaalt.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle6;
