import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle24 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Financiële onzekerheden en risico's door constant muterende regelgevingen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage24.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                07-09-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om elektrisch rijden aantrekkelijker te maken in de zakelijke wereld, heeft de overheid tal van maatregelen getroffen. 
            Zowel zakelijke als particuliere elektrische rijders kunnen rekenen op{" "} 
            <span style={{color: "#2b5baa"}}>
            subsidies en regelingen{" "}
            </span>
            om zo voordelen te creëren voor zowel{" "} 
            <span style={{color: "#9c29ff"}}>
            bedrijven als individuen
            </span>
            . Recentelijk zijn er echter{" "}
            <span style={{color: "#2b5baa"}}>
            mutaties{" "}
            </span>
            opgetreden in de regelgeving omtrent de subsidie voor{" "}
            <span style={{color: "#9c29ff"}}>
            elektrische voertuigen. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Op 31 augustus werd aangekondigd dat het subsidieplafond voor de{" "}
            <span style={{ color: "#2b5baa" }}>
            AanZET-subsidie{" "}
            </span>
            zou worden verhoogd van{" "}
            <span style={{ color: "#2b5baa" }}>
            30 miljoen euro{" "}
              </span>
            naar{" "}
            <span style={{ color: "#2b5baa" }}>
            57,4 miljoen euro
              </span>
            . Ook was er verwachting dat er extra financiering zou worden toegekend aan de{" "}
            <span style={{color: "#9c29ff"}}>
            bouwsector{" "}
            </span>
            om verduurzaming te bevorderen. Het subsidieplafond voor de subsidieregeling{" "}
            <span style={{color: "#9c29ff"}}>
            Schoon en Emissieloos Bouwmaterieel (SSEB){" "}
            </span>
             zou stijgen van{" "}
            <span style={{color: "#9c29ff"}}>
            36 miljoen euro{" "}
            </span>
            naar{" "}
            <span style={{color: "#9c29ff"}}>
            42 miljoen euro
            </span>
            . Echter, recente berichten hebben laten zien dat deze begrotingswijzigingen worden{" "}
            <span style={{color: "#9c29ff"}}>
            teruggedraaid
            </span>
            . Staatssecretaris Vivianne Heijnen meldt in de Staatscourant dat de verhoging van het AanZET-budget van 30 miljoen naar 57,4 miljoen euro "abusievelijk gepubliceerd" is. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Naast AanZET en SSEB is er recentelijk ook een besluit gemomen dat negatieve gevolgen heeft voor de zakelijke elektrische bestelauto's. De{" "}
            <span style={{ color: "#2b5baa" }}>
            subsidie{" "}
              </span>
            voor uitstootvrije bestelauto's zal{" "} 
            <span style={{ color: "#2b5baa" }}>
            na 2024 worden stopgezet
              </span>
            . Volgens staatssecretaris Vivianne Heijnen is het voortzetten van de Subsidieregeling Emissieloze BedrijfsAuto's (SEBA) na 2025{" "}
            <span style={{ color: "#2b5baa" }}>
            "niet langer realistisch"{" "}
              </span>
            en bovendien overbodig gezien de al{" "}
            <span style={{ color: "#9c29ff" }}>
            verwachte sterke stijging van de aanschafkosten{" "}
              </span>
            van fossiele bestelauto's in dat jaar, wat het verschil al zal maken. 
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het is van groot belang voor ondernemers om{" "}
            <span style={{ color: "#2b5baa" }}>
            weloverwogen beslissingen{" "}
              </span>
            te kunnen nemen. De overheid probeert de ondernemer een zetje in de richting duurzame aankopen te geven d.m.v.{" "}
            <span style={{ color: "#9c29ff" }}>
            regelingen en subsidies
              </span>
            . Zo zou de elektrische rijder in de toekomst structureel minder trucktol per gereden kilometer betalen volgens de overheid. 
            De investeringskeuze zou hierdoor gemakkelijk gemaakt kunnen worden maar zoals uit dit artikel blijkt komt {" "}
            <span style={{ color: "#2b5baa" }}>
            de overheid hun beloftes niet {" "}
            </span>
            altijd netjes {" "}
            <span style={{ color: "#2b5baa" }}>
            na
            </span>
            . Het is verstandig om voorafgaand aan een besluit goed te onderzoeken welke{" "}
            <span style={{ color: "#9c29ff" }}>
            onzekerheden{" "}
              </span>
            er zijn met betrekking tot de relevante voorschriften en om beloften met een kritische blik te benaderen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle24;
