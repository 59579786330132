import React from "react";
import ContactForm from "../elements/ContactForm";
import HomeChooseService from "../elements/HomeChooseService";
import HomePageSellingPoints from "../elements/HomePageSellingPoints";
// import ResponsiveAppBar from "../ResponsiveAppBar";
import BannerHome from "./BannerHome";

const Homepage = () => {
  return (
    <>
      <BannerHome />
      <HomeChooseService />
      <HomePageSellingPoints />
      <ContactForm />
    </>
  );
};

export default Homepage;
