import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle26 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            De CO2-tolheffing binnenkort van kracht 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage26.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                21-09-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Per{" "} 
            <span style={{color: "#2b5baa"}}>
            1 december 2023{" "}
            </span>
            wordt verwacht dat een {" "}
            <span style={{color: "#2b5baa"}}>
            extra tolheffing{" "}
            </span>
            wordt ingevoerd{" "} 
            <span style={{color: "#2b5baa"}}>
            in Duitsland
            </span>
            . Vanaf die datum wordt een heffing tot{" "}
            <span style={{color: "#9c29ff"}}>
            200 euro per ton uitgestoten CO2{" "}
            </span>
            geïmplementeerd. Andere lidstaten van de Europese Unie zullen op een later tijdstip soortgelijke maatregelen overwegen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Per december 2023 geldt een nieuwe beoordelingsmethode voor vrachtwagens met een {" "}
            <span style={{ color: "#2b5baa" }}>
            gewicht boven de 7,5 ton
            </span>
            , de CO2-klasse. De voertuigen dienen{" "} 
            <span style={{ color: "#9c29ff"}}>
            voor 1 december {" "}
            </span>
            geclassificeerd te worden voor hun CO2-klasse bij hun tolexploitanten, doet u dit niet dan worden uw voertuigen{" "}
            <span style={{ color: "#2b5baa" }}>
            automatisch ingedeeld in de hoogste CO2-klasse
            </span>
            . Dit geldt overigens enkel voor voertuigen die{" "} 
            <span style={{ color: "#9c29ff"}}>
            na 30 juni 2019{" "}
            </span>
            voor het eerst zijn geregistreerd en onderhevig zijn aan tol. Voertuigen die{" "}
            <span style={{ color: "#2b5baa" }}>
            vóór 1 juli 2019{" "}
            </span>
            voor het eerst geregistreerd zijn en onderhevig zijn aan tol worden per definitie{" "} 
            <span style={{ color: "#2b5baa"}}>
            geclassificeerd onder de hoogste CO2-klasse
            </span>
            . De verwachting is dat de toltarieven met de invoering van de klimaattoeslag aanzienlijk zullen stijgen, naar schatting tussen de{" "}
            <span style={{ color: "#9c29ff"}}>
            40 en 80 procent
            </span>
            , afhankelijk van de CO2-klasse van het voertuig. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Duitsland loopt voorop met de invoering van CO2-tolheffingen, en het is nog{" "}
            <span style={{ color: "#2b5baa" }}>
            onduidelijk{" "}
              </span>
            wanneer{" "} 
            <span style={{ color: "#2b5baa" }}>
            andere lidstaten{" "}
              </span>
            de bindende EU-richtlijn in hun nationale wetgeving zullen implementeren. Het is raadzaam voor transporteurs om de ontwikkelingen nauwlettend te volgen, 
            aangezien de grenswaarden voor classificatie{" "}
            <span style={{ color: "#9c29ff" }}>
            jaarlijks zullen worden herzien{" "}
              </span>
            om de overkoepelende klimaatdoelstelling te ondersteunen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Duitse regering verwacht dat de CO2-tolheffing tussen{" "}
            <span style={{ color: "#2b5baa" }}>
            2024 en 2027 {" "}
              </span>
            extra inkomsten van {" "}
            <span style={{ color: "#9c29ff" }}>
            26 tot 36 miljard euro {" "}
              </span>
              zal genereren. De regering heeft toegezegd dat deze inkomsten zullen worden besteed aan de  {" "}
            <span style={{ color: "#2b5baa" }}>
            verbetering van de federale snelweginfrastructuur en mobiliteitsmaatregelen
            </span>
            , met bijzondere aandacht voor{" "}
            <span style={{ color: "#2b5baa" }}>
            federale spoorwegen.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>
            Emissievrije vrachtwagens{" "}
              </span>
            (elektrische en op waterstof) zullen gedurende een beperkte periode vrijgesteld blijven van CO2-tolheffing. Pas{" "}
            <span style={{ color: "#9c29ff" }}>
            vanaf 1 januari 2026 {" "}
              </span>
              zullen zij een{" "}
              <span style={{ color: "#2b5baa" }}>
            "gedeeltelijk tarief" {" "}
              </span>
            betalen, met een vermindering van{" "}
            <span style={{ color: "#2b5baa" }}>
            75 procent{" "}
              </span>
            voor de infrastructuurkosten, naast de tolcomponenten voor luchtvervuiling en geluidsoverlast.  
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle26;
