import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle54 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Tolheffing binnenkort in Denemarken 

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage54.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                15-08-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vanaf{" "}
            <span style={{color: "#2b5baa"}}>
            1 januari 2025{" "}
            </span>
            voert{" "}
            <span style={{color: "#9c29ff"}}>
            Denemarken{" "}
            </span>
            een kilometerheffing in gebaseerd op de gereden afstand en de CO2-uitstoot. De nieuwe heffing vervangt het huidige{" "}
            <span style={{color: "#9c29ff"}}>
            Eurovignet-systeem
            </span>
            , waarbij weggebruikers betalen voor het gebruik van de wegen op basis van een vaste periode. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Met de overstap naar een kilometerheffing wil Denemarken een eerlijkere verdeling van de kosten realiseren die voortkomen uit weggebruik, zoals{" "}
            <span style={{color: "#2b5baa"}}>
            infrastructuurslijtage, verkeersongelukken, geluidsoverlast, luchtvervuiling en verkeerscongestie. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#9c29ff"}}>
            In eerste instantie {" "}
            </span>
            zal in Denemarken de kilometerheffing alleen van toepassing zijn op vrachtwagens van{" "}
            <span style={{color: "#9c29ff"}}>
            12 ton en zwaarder.{" "}
            </span>
            <span style={{color: "#2b5baa"}}>
            Vanaf 1 januari 2027{" "}
            </span>
            wordt de heffing uitgebreid naar vrachtwagens van{" "}
            <span style={{color: "#2b5baa"}}>
            3,5 ton en zwaarder
            </span>
            . Bovendien zal de heffing in 2028 worden uitgebreid naar het volledige Deense wegennet, wat neerkomt op ongeveer 75.000 kilometer. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            De hoogte van het bedrag is afhankelijk van de CO2-uitstoot. Voor{" "}  
            <span style={{color: "#9c29ff"}}>
            zero-emissietrucks{" "}
            </span>
            is de heffing{" "}
            <span style={{color: "#9c29ff"}}>
            vrijwel nihil
            </span>
            , terwijl voor{" "}
            <span style={{color: "#2b5baa"}}>
            dieseltrucks{" "}
            </span>
             het tarief varieert van {" "}
            <span style={{color: "#2b5baa"}}>
            1 tot 18 eurocent{" "}
            </span>
            per kilometer. In{" "}
            <span style={{color: "#9c29ff"}}>
            lage emissiezones{" "}
            </span>
            kan het tarief oplopen tot{" "}
            <span style={{color: "#9c29ff"}}>
            27 eurocent {" "}
            </span>
            per kilometer. 
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Denen verwachten dat tegen{" "}
            <span style={{color: "#9c29ff"}}>
            2030
            </span>
            , wanneer de tol op alle wegen is ingevoerd en geldt voor{" "}
            <span style={{color: "#9c29ff"}}>
            alle voertuigen boven de 3,5 ton
            </span>
            , vrachtwagens gemiddeld{" "}
            <span style={{color: "#9c29ff"}}>
            16 eurocent{" "}
            </span>
            per kilometer zullen betalen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor meer informatie over het nieuwe Deense tolheffing systeem, bekijk de uitleg video <a href="https://vimeo.com/992666962"> hier</a>.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle54;
