import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle27 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Uitbreiding van tolwegennetwerk in België 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage27.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                28-09-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Vlaamse regering heeft aangekondigd dat ze van plan is om het{" "} 
            <span style={{color: "#2b5baa"}}>
            tolwegennet in 2024 aanzienlijk uit te breiden
            </span>
            . Dit initiatief wordt gemotiveerd door verschillende doelstellingen, zoals het stimuleren van een{" "}
            <span style={{color: "#9c29ff"}}>
            verschuiving in het transportgedrag{" "}
            </span>
            en het{" "}
            <span style={{color: "#9c29ff"}}>
            ontmoedigen van sluipverkeer.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Al voor de zomervakantie had de Vlaamse regering aangekondigd dat ze haar Energie- 
            en Klimaatplan zou herzien. Een onderdeel van deze herziening betrof het plan om het 
            bestaande tolwegennetwerk in Vlaanderen uit te breiden voor vrachtwagens. Het idee om 
            tol te heffen op {" "}
            <span style={{ color: "#2b5baa" }}>
            personenwagens blijft echter volstrekt onbespreekbaar{" "}
            </span>
            in de politieke arena. Ondertussen worden de geplande maatregelen verder uitgewerkt en 
            wordt de praktische implementatie ervan voorbereid. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Een opvallend aspect van deze uitbreiding is de beslissing om het bestaande tolwegennet in{" "}
            <span style={{ color: "#9c29ff" }}>
            Vlaanderen{" "}
              </span>
            uit te breiden met maar liefst{" "} 
            <span style={{ color: "#9c29ff" }}>
            686 kilometer
              </span>
            , wat neerkomt op een toename van bijna 30%. Deze 686 kilometer aan tolheffing is op te delen in drie onderdelen:
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ol>
                 <tr>
                    <td>
                        <li>Vanaf{" "} 
                            <span style={{ color: "#2b5baa" }}> 
                            2024 {" "}
                            </span>
                            zal er kilometerheffing worden geïnd op wegen in de havengebieden van{" "}
                            <span style={{ color: "#2b5baa" }}> 
                            Antwerpen, Zeebrugge, Gent en Oostende
                            </span>
                            , wat in totaal{" "}
                            <span style={{ color: "#9c29ff" }}> 
                            111 kilometer{" "}
                            </span>
                            aan wegen omvat. Hiervan worden enkele belangrijke hoofdwegen nu{" "}
                            <span style={{ color: "#9c29ff" }}> 
                            omgezet in tolwegen
                            </span>
                            , waaronder de N49 tussen Zelzate en de aansluiting met de A11. Dit is een opvallende verandering, 
                            aangezien bij de lancering van de kilometerheffing in 2016 de havengebieden en de N49 waren 
                            vrijgesteld om de concurrentiepositie van de Vlaamse havens te beschermen. Dit argument lijkt 
                            nu niet langer van toepassing te zijn. 
                        </li>
                        <br></br>
                        <li>   
                            Bovenop de 111 kilometer in de havengebieden worden nog eens{" "}
                            <span style={{ color: "#9c29ff" }}> 
                            208 kilometer{" "}
                            </span>
                            aan hoofdwegen aan het tolwegennet toegevoegd. Deze uitbreiding van tolwegen zal bijdragen aan de gewenste{" "}
                            <span style={{ color: "#2b5baa" }}> 
                            modal shift
                            </span>
                            , waarbij goederenvervoer meer wordt verplaatst van de weg naar het water en het spoor.  
                        </li>
                        <br></br>
                        <li> 
                            Daarnaast zal Vlaanderen nog eens{" "}
                            <span style={{ color: "#9c29ff" }}> 
                            367 kilometer{" "}
                            </span>
                            aan wegen toevoegen met tolheffing om{" "}
                            <span style={{ color: "#2b5baa" }}> 
                            sluipverkeer tegen te gaan
                            </span>
                            . Met name in{" "}
                            <span style={{ color: "#2b5baa" }}> 
                            Limburg
                            </span>
                            , waar nu tol moet worden betaald op bepaalde belangrijke verkeersroutes, lijkt het moeilijk te rechtvaardigen 
                            dat er sprake is van sluipverkeer als er geen alternatieve routes beschikbaar zijn.
                        </li>
                    </td>
                 </tr>
                    </ol>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Nederlandse transportondernemers zullen straks geconfronteerd worden met{" "}
            <span style={{ color: "#9c29ff" }}>
            toenemende kosten{" "}
              </span>
            aangezien ze per 2024 tol moeten betalen op een uitgebreider netwerk van wegen{" "}
            <span style={{ color: "#9c29ff" }}>
            in Vlaanderen
              </span>
            . Het wordt het waard om de {" "}
            <span style={{ color: "#2b5baa" }}>
            routeplanning en logistieke strategieën te herzien{" "}
            </span>
            om rekening te houden met de nieuwe tolwegen en tarieven in Vlaanderen. Dit kan 
            leiden tot veranderingen in de gekozen routes en mogelijk langere reistijden.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle27;
