import {
  // Box,
  Container,
  // Divider,
  Grid,
  Typography,
  // Stack,
  // Typography,
} from "@mui/material";
// import Image from "mui-image";
import React, { useState } from "react";
import PricingColumn from "./PricingColumn";

// import liveInzicht from "../../assets/Liveinzicht.png";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import VATProduct from "../../assets/icons/VATProduct";
// import Voorfinanciering from "../../assets/icons/Voorfinanciering";
// import VATProduct from "../../assets/icons/VATProduct";
// import LiveVorderingenInzicht from "../../assets/icons/LiveVorderingenInzicht";
// import Automatisering from "../../assets/icons/Automatisering";
import VATProductIcon from "../../assets/icons/VATProductIcon.png";
import PreFinanceIcon from "../../assets/icons/PreFinanceIcon.png";
import LiveProgressInsight from "../../assets/icons/LiveProgressInsight.png";
import AutomationIcon from "../../assets/icons/AutomationIcon.png";
import CertaintyOfRefundIcon from "../../assets/icons/CertaintyOfRefundIcon.png";
import EnjoyCareFreeIcon from "../../assets/icons/EnjoyCareFreeIcon.png";
import SubscriptionIcon from "../../assets/icons/SubscriptionIcon.png";
import ClearInvoiceMatchingIcon from "../../assets/icons/ClearInvoiceMatchingIcon.png";
import FinancingIcon from "../../assets/icons/FinancingIcon.png";
import FinancingFrequencyIcon from "../../assets/icons/FinancingFrequencyIcon.png";
import ExciseProductIcon from "../../assets/icons/ExciseProductIcon.png";
import ExcisePreFinanceIcon from "../../assets/icons/ExcisePreFinanceIcon.png";
import InvoicingOverviewIcon from "../../assets/icons/InvoicingOverviewIcon.png";
import FinancingWithinOneWorkinDayIcon from "../../assets/icons/FinancingWithinOneWorkinDayIcon.png";
import TransparentBookKeepingIcon from "../../assets/icons/TransparentBookKeepingIcon.png";

import Image from "mui-image";

const PricingTable = (props) => {
  const [pricingTable] = useState([
    {
      title: "Delta petrol",
      subTitle: "Normal refund",
      navUrl: "/DeltaPetrol",
      active: props.deltaPetrolActive,
      newProduct: false,
      isExcise: false,
      isNew: false,
      mainItems: [
        {
          item: "BTW product",
          toolTip: "Dit product heeft betrekking op uw BTW teruggaaf",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={VATProductIcon}
              height={16}
              width={24}
            />
          ),
        },
        {
          item: "Zonder voorfinanciering",
          toolTip:
            "De basis is altijd normal refund, heeft u toch liever af en toe- of permanent voorfinanciering? Delta Hybrid of Delta Electric is de juiste keuze voor u!",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={PreFinanceIcon}
              height={18}
              width={18}
            />
          ),
        },
      ],
      items: [
        {
          item: "Live vorderingen inzicht",
          toolTip:
            "U kunt 24/7 de actieve status van uw vordering volgen via het klantenportaal",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={LiveProgressInsight}
              height={16}
              width={26}
            />
          ),
        },
        {
          item: "Automatisering",
          toolTip:
            "Al uw facturen worden automatisch geïntegreerd in ons systeem",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={AutomationIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Zekerheid op terugvordering",
          toolTip:
            "Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal ontvangen",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={CertaintyOfRefundIcon}
              height={22}
              width={19}
            />
          ),
        },
        {
          item: "Zorgenloos opstarten",
          toolTip:
            "Wij zorgen ervoor dat de nodige opstart documenten snel en zonder zorgen compleet gemaakt worden",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={EnjoyCareFreeIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Abonnement",
          toolTip:
            "Kosten op abonnements- basis zodat u elke maand weet waar u aan toe bent",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={SubscriptionIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Duidelijk afletteren",
          toolTip:
            "U krijgt het volledige btw bedrag teruggestort op uw rekening. Zo blijft uw boekhouding altijd op orde",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={ClearInvoiceMatchingIcon}
              height={19}
              width={19}
            />
          ),
        },
      ],
    },
    {
      title: "Delta Hybrid",
      subTitle: "Flexible refund",
      navUrl: "/DeltaHybrid",
      active: props.deltaHybridActive,
      newProduct: true,
      isExcise: false,
      isNew: true,
      mainItems: [
        {
          item: "BTW product",
          toolTip: "Dit product heeft betrekking op uw BTW teruggaaf",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={VATProductIcon}
              height={16}
              width={24}
            />
          ),
        },
        {
          item: "Af en toe voorfinanciering",
          toolTip:
            "De basis is normal refund, via het klantenportaal kunt u echter voorfinanciering aanvragen voor het gewenste bedrag",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={PreFinanceIcon}
              height={18}
              width={18}
            />
          ),
        },
      ],
      items: [
        {
          item: "Live vorderingen inzicht",
          toolTip:
            "U kunt 24/7 de actieve status van uw vordering volgen via het klantenportaal",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={LiveProgressInsight}
              height={16}
              width={26}
            />
          ),
        },
        {
          item: "Automatisering",
          toolTip:
            "Al uw facturen worden automatisch geïntegreerd in ons systeem",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={AutomationIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Zekerheid op terugvordering",
          toolTip:
            "Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal ontvangen",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={CertaintyOfRefundIcon}
              height={22}
              width={19}
            />
          ),
        },
        {
          item: "Zorgenloos opstarten",
          toolTip:
            "Wij zorgen ervoor dat de nodige opstart documenten snel en zonder zorgen compleet gemaakt worden",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={EnjoyCareFreeIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Abonnement + fee",
          toolTip:
            "Kosten op abonnements- basis voor een stabiele cashflow + een fee voor eventueel voorfinanciering",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={SubscriptionIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Heldere boekhouding",
          toolTip:
            "U krijgt in het klantenportaal een duidelijk overzicht van de voorgefinancierde bedragen en de resterende kredietruimte",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={TransparentBookKeepingIcon}
              height={20}
              width={20}
            />
          ),
        },
        {
          item: "Financiering binnen 1 werkdag na aanvraag",
          toolTip:
            "Binnen 1 werkdag na aanvraag maken wij het door u gewenste bedrag over op uw rekening",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={FinancingWithinOneWorkinDayIcon}
              height={19}
              width={21}
            />
          ),
        },
      ],
    },
    {
      title: "Delta Electric",
      subTitle: "Fast refund",
      navUrl: "/DeltaElectric",
      active: props.deltaElectricActive,
      newProduct: false,
      isExcise: false,
      isNew: false,
      mainItems: [
        {
          item: "BTW product",
          toolTip: "Dit product heeft betrekking op uw BTW teruggaaf",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={VATProductIcon}
              height={16}
              width={24}
            />
          ),
        },
        {
          item: "Vast voorfinanciering",
          toolTip:
            "De basis is altijd voorfinanciering, zo houdt u altijd uw liquiditeit op orde",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={PreFinanceIcon}
              height={18}
              width={18}
            />
          ),
        },
      ],
      items: [
        {
          item: "Live vorderingen inzicht",
          toolTip:
            "U kunt 24/7 de actieve status van uw vordering volgen via het klantenportaal",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={LiveProgressInsight}
              height={16}
              width={26}
            />
          ),
        },
        {
          item: "Automatisering",
          toolTip:
            "Al uw facturen worden automatisch geïntegreerd in ons systeem",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={AutomationIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Zekerheid op terugvordering",
          toolTip:
            "Altijd ervan verzekerd dat u uw betaalde BTW 100% terug zal ontvangen",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={CertaintyOfRefundIcon}
              height={22}
              width={19}
            />
          ),
        },
        {
          item: "Zorgenloos opstarten",
          toolTip:
            "Wij zorgen ervoor dat de nodige opstart documenten snel en zonder zorgen compleet gemaakt worden",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={EnjoyCareFreeIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Abonnement + fee",
          toolTip:
            "Kosten op abonnements- basis voor een stabiele cashflow + een fee voor voorfinanciering",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={SubscriptionIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Facturatie overzicht",
          toolTip:
            "U krijgt bij onze elektronische commissie factuur altijd een overzicht van de voorgefinancierde facturen",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={InvoicingOverviewIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Financiering binnen 10 werkdagen",
          toolTip:
            "Binnen 10 werkdagen maken wij het voor te financieren bedrag over op uw rekening",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={FinancingIcon}
              height={19}
              width={21}
            />
          ),
        },
        {
          item: "Keuze uit direct-, maandelijks- of per kwartaal voorfinanciering",
          toolTip:
            "Keuze uit direct, maandelijks of per kwartaal, afhankelijk van uw behoefte",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={FinancingFrequencyIcon}
              height={19}
              width={19}
            />
          ),
        },
      ],
    },
    {
      title: "Delta Diesel",
      subTitle: "Excise duties",
      navUrl: "/DeltaDiesel",
      active: props.deltaDieselActive,
      newProduct: false,
      isExcise: true,
      isNew: false,
      mainItems: [
        {
          item: "Accijns product",
          toolTip: "Dit product heeft betrekking op uw accijns teruggaaf",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={ExciseProductIcon}
              height={16}
              width={24}
            />
          ),
        },
        {
          item: "Af en toe voorfinanciering",
          toolTip:
            "Op belgische dieselaccijnzen is er de mogelijkheid van voorfinanciering. Vraag naar onze tarieven.",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={ExcisePreFinanceIcon}
              height={18}
              width={18}
            />
          ),
        },
      ],
      items: [
        {
          item: "Live vorderingen inzicht",
          toolTip:
            "U kunt 24/7 de actieve status van uw vordering volgen via het klantenportaal",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={LiveProgressInsight}
              height={16}
              width={26}
            />
          ),
        },
        {
          item: "Automatisering",
          toolTip:
            "Al uw facturen worden automatisch geïntegreerd in ons systeem",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={AutomationIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Zekerheid op terugvordering",
          toolTip:
            "Altijd ervan verzekerd dat u optimaal van de accijns compensatie regelingen gebruik maakt",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={CertaintyOfRefundIcon}
              height={22}
              width={19}
            />
          ),
        },
        {
          item: "Zorgenloos opstarten",
          toolTip:
            "Wij zorgen ervoor dat de nodige opstart documenten snel en zonder zorgen compleet gemaakt worden",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={EnjoyCareFreeIcon}
              height={19}
              width={19}
            />
          ),
        },
        {
          item: "Abonnement",
          toolTip:
            "Kosten op abonnements- basis zodat u elke maand weet waar u aan toe bent",
          icon: (
            <Image
              sx={{ mt: 0 }}
              img
              src={SubscriptionIcon}
              height={19}
              width={19}
            />
          ),
        },
      ],
    },
  ]);

  return (
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: "#FAFCFF",
      }}
    >
      {/* <Automatisering color="#6b6a6a" /> */}
      <Container maxWidth="xl">
        <Typography
          sx={{
            mt: 8,
            pt: 8,
            mb: 1,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "40px", md: "55px", xl: "71px" },
            lineHeight: { xs: "55px", md: "70px", xl: "85px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
          }}
        >
          Vergelijk onze diensten
        </Typography>
        <Typography
          mb={0}
          sx={{
            mt: 0,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "16px", md: "21px", xl: "21px" },
            lineHeight: { xs: "20px", md: "25.2px", xl: "25.2px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Regular",
          }}
        >
          Delta's verschillen in diensten in één oogopslag.
        </Typography>
        <Grid
          container
          sx={{ pt: 10, pb: 10, backgroundColor: "#FAFCFF" }}
          mr="10px"
          md={12}
          xs={12}
        >
          <PricingColumn info={pricingTable} />
        </Grid>
      </Container>
    </Container>
  );
};

export default PricingTable;
