import React from "react";
import NewsArticle6 from "../elements/NewsArticle6";
import NewsNav from "../elements/NewsNav";

const NewsDetails4 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle6 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails4;
