import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Image from "mui-image";
import React, { useRef } from "react";
// import { Link } from "react-router-dom";
import FlexiblePreFinance from "../../assets/HangingPhone.png";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const form = useRef();
  const [succesLabelVisible, setSuccesLabelVisible] = React.useState(false);
  const [errorLabelVisible, setErrorLabelVisible] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const sendEmail = (e) => {
    setSubmitButtonDisabled(true);
    // e.preventDefault();
    // var hasError;
    emailjs
      .sendForm(
        "service_pcmrdxj",
        "template_yzhhh3p",
        form.current,
        "_yi0_0d3ScPyf_1Dl"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(succesLabelVisible);
          setSuccesLabelVisible(true);
          console.log(succesLabelVisible);
        },
        (error) => {
          console.log(error.text);
          setErrorLabelVisible(true);
        }
      );
  };
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{
          pt: 0,
          pb: 5,
          // backgroundColor: "#FAFCFF",
          minHeight: "200px",
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pt: { xs: 0, md: 0 },
                  pr: { xs: 0, md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 0, pt: 0 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "350px",
                    alignItems: "center",
                  }}
                  img
                  src={FlexiblePreFinance}
                  alt="cur"
                  // height={402}
                  // width={793}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <form ref={form} onSubmit={sendEmail}>
                <TextField
                  name="from_name"
                  variant="outlined"
                  sx={{
                    m: "auto",
                    mb: 1,
                    mt: { xs: 0, md: 5 },
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Voornaam + Achternaam"
                ></TextField>
                <TextField
                  name="company_name"
                  sx={{
                    m: "auto",
                    mb: 1,
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Uw bedrijfsnaam"
                ></TextField>
                <TextField
                  name="country"
                  sx={{
                    m: "auto",
                    mb: 1,
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Land"
                ></TextField>
                <TextField
                  name="phone"
                  type="tel"
                  sx={{
                    m: "auto",
                    mb: 1,
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Telefoonnummer"
                ></TextField>
                <TextField
                  name="email"
                  type="email"
                  sx={{
                    m: "auto",
                    mb: 1,
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Email"
                ></TextField>
                <TextField
                  name="message"
                  multiline
                  rows={4}
                  sx={{
                    m: "auto",
                    mb: 0,
                    maxWidth: "100%",
                    width: "100%",
                    boxShadow: 4,
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  placeholder="Uw bericht"
                ></TextField>
                <Typography
                  sx={{
                    mt: 3,
                    color: succesLabelVisible
                      ? "green"
                      : errorLabelVisible
                      ? ""
                      : null,
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontFamily: "HelveticaNeue-Light",
                    textAlign: "center",
                  }}
                >
                  {succesLabelVisible
                    ? "Uw bericht is succesvol verzonden. U krijgt z.s.m. van ons bericht."
                    : errorLabelVisible
                    ? "Het is niet gelukt om uw bericht te versturen. Probeert u het later nog eens of neem direct telefonisch contact met ons op."
                    : null}
                </Typography>
                <Box
                  container
                  display="flex"
                  direction="column"
                  justifyContent="center"
                >
                  <Button
                    onClick={sendEmail}
                    // variant="Outlined"
                    sx={{
                      textTransform: "unset",
                      // my: 0,
                      mt: 4,
                      backgroundColor: "#C85EEE",
                      "&:hover": {
                        backgroundColor: "#9d49bb",
                      },
                      color: "#FFFFFF",
                      width: 250,
                      height: 67,
                      borderRadius: 35,
                      fontSize: "1.3rem",
                      "@media (min-width:600px)": {
                        fontSize: "1.7rem",
                        mt: 4,
                        width: 304,
                        height: 86,
                      },
                      fontFamily: "Century Gothic",
                      fontWeight: "Bold",
                      background: "linear-gradient(to right, #9b29ff, #c85eee)",
                    }}
                    disabled={submitButtonDisabled ? true : false}
                  >
                    Verstuur
                  </Button>
                </Box>
              </form>
            </Grid>
            {/* <Grid item xs={0} lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 0, pt: 0 }}
                  style={{
                    ObjectFit: "cover",
                    maxWidth: "350px",
                    alignItems: "center",
                  }}
                  img
                  src={FlexiblePreFinance}
                  alt="cur"
                  // height={402}
                  // width={793}
                />
              </Box>
            </Grid> */}
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default ContactForm;
