import React from "react";
import NewsArticle39 from "../elements/NewsArticle39";
import NewsNav from "../elements/NewsNav";

const NewsDetails39 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle39 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails39;