import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle18 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            De veranderingen van het Britse BTW-teruggaaf proces 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage18.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                13-07-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Sinds de{" "}
            <span style={{ color: "#2b5baa" }}>
            Brexit {" "}
              </span>
            hebben transportbedrijven die zakendoen in het Verenigd Koninkrijk te maken met
            {" "}
            <span style={{ color: "#2b5baa" }}>
            meer uitdagingen{" "}
              </span>
            op het gebied van BTW-terugvordering.{" "}
            <span style={{ color: "#9c29ff" }}>
            Het proces is veranderd{" "}
              </span>
            na het verlaten van het VK uit de EU en daarmee de{" "}
            <span style={{ color: "#9c29ff" }}>
            toegankelijkheid verminderd
              </span>
            . In dit artikel verkennen we de complexiteit van het terugvorderingsproces, de moeilijkheden bij het 
            verkrijgen van toegang en de potentiële nadelen voor transportbedrijven die Engelse BTW willen terugvorderen. 
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Na de Brexit moeten EU-landen hun Engelse BTW-teruggaaf indienen via een{" "}
            <span style={{ color: "#2b5baa" }}>
            specifiek Engels portaal
              </span>
            . Om als onderneming toegang te krijgen tot dit belastingportaal moet er via de Engelse belastingdienst een{" "}
              <span style={{ color: "#9c29ff" }}>
            aanvraag{" "}
              </span>
            gedaan worden{" "}
            <span style={{ color: "#9c29ff" }}>
            tot registratie.
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor transportbedrijven die Engelse BTW willen terugvorderen, is het belangrijk om bekend te zijn met de procedurele verschillen bij het indienen van een BTW-teruggaaf op basis van de{" "}
            <span style={{ color: "#2b5baa" }}>
            13e richtlijn
              </span>
            , ook wel de {" "}
            <span style={{ color: "#2b5baa" }}>
            '13th Directive'{" "}
              </span>
            genoemd. In tegenstelling tot de 8e richtlijn bestrijkt een aanvraag op basis van de 13e richtlijn een specifieke{" "}
            <span style={{ color: "#2b5baa" }}>
            periode van 12 maanden
              </span>
            . Het Engelse tijdsbestek loopt vrij asymmetrisch, namenlijk van{" "}
            <span style={{ color: "#9c29ff" }}>
            1 juli tot en met 30 juni{" "}
              </span>
            van het daaropvolgende jaar.{" "} 
            <span style={{ color: "#2b5baa" }}>
            De deadline{" "}
              </span>
            voor het indienen van dit teruggaaf verzoek{" "}
            <span style={{ color: "#2b5baa" }}>
            is op 31 december
              </span>
            , een half jaar na het einde van het aangiftejaar. Iets om rekening mee te houden. Bedrijven die nog Engelse BTW hebben liggen van 1 januari t/m 30 
            juni 2022 vissen hiermee achter het net. Het 2e halfjaar 2022 en 1e halfjaar 2023 is tot 31 december 2023 te declareren. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Ook de{" "}
            <span style={{ color: "#9c29ff" }}>
            drempelwaarde{" "}
              </span>
            wijkt af. De drempelwaarde is het minimumbedrag waar het BTW-teruggaaf verzoek aan moet voldoen. Zodra het bedrag zich{" "}
            <span style={{ color: "#9c29ff" }}>
            onder het minimumbedrag{" "}
              </span>
            bevindt,{" "} 
              <span style={{ color: "#9c29ff" }}>
            wordt het teruggaaf verzoek afgewezen
              </span>
            . Voorheen gold een uniforme drempelwaarde voor alle EU-landen, maar deze drempels kunnen per land dus verschillen 
            (zie eerder artikel van Delta).{" "}
            <span style={{ color: "#2b5baa" }}>
            De drempelwaarde die Engeland hanteert is 130 Britse Pond per kwartaal  (± €150) en 16 Britse Pond per jaar (±€20).
              </span>
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Hoewel het terugvorderen van Engelse BTW vanuit EU-landen na de Brexit uitdagend is, biedt het begrip van de complexiteit, 
            het naleven van de procedures en het inschakelen van de{" "}
            <span style={{ color: "#9c29ff" }}>
            juiste expertise{" "}
              </span>
            transportbedrijven de mogelijkheid om met succes BTW terug te vorderen en de{" "}
            <span style={{ color: "#9c29ff" }}>
            financiële impact te minimaliseren
              </span>
            . Maar vooral zou het zonde zijn om de BTW te laten liggen wegens het niet kennen van de regels of het niet respecteren van deadlines. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle18;
