import React from "react";
import LandingConfirmationContent from "../elements/LandingConfirmation";

const LandingConfirmation = () => {
  return (
    <>
      {/* <NewsNav top /> */}
      <LandingConfirmationContent />
    </>
  );
};

export default LandingConfirmation;
