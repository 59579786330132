import { Container, Typography } from "@mui/material";
import React from "react";
import FAQElement from "./FAQElement";

const FAQs = () => {
  const [questions1] = React.useState([
    {
      title: "Delta's producten",
      questions: [
        {
          title: "Wat is het meest gekozen product?",
          description:
            "Ca. 50% van onze klanten kiezen voor voorfinanciering en 50% voor normale terugvordering. De hybride vorm is nieuw en biedt de facultatieve mogelijkheid tot voorfinanciering, zonder dat het iets extra kost.",
        },
        {
          title:
            "Waarom biedt Delta als enige BTW agent flexibel voorfinancieren aan?",
          description:
            "Wij ontdekten dat diverse bedrijven 'af en toe' liquiditeitbehoefte hadden. Hier is het product 'Delta Hybrid' voor ontwikkeld. Dit product is in de basis kostenefficiënt en levert, indien nodig, de gewenste terugbetaalsnelheid.",
        },
        {
          title:
            "Is voorfinanciering bij Delta te vergelijken met Nett-Invoicing?",
          description:
            "Meestal is het sneller. Wij zorgen dat u al eerder uw BTW terug heeft, dan dat u de BTW aan uw leverancier moet betalen.",
        },
        {
          title:
            "Zijn er voordelen van voorfinanciering bij Delta boven Nett-Invoicing?",
          description:
            "Ja. Doordat wij alle facturen van alle leveranciers gelijk behandelen, is er geen onderscheid in tarief en daarmee erg voordelig en snel!",
        },
        {
          title: "Kan ik later nog switchen van dienst/product?",
          description: "Ja, dat is geen probleem.",
        },
        {
          title: "Wat is de looptijd van een abonnement?",
          description: "Dat varieert van 1 tot 5 jaar.",
        },
        {
          title: "Hoe vraag ik bij Delta Hybrid voorfinanciering aan?",
          description:
            "Zodra er een mutatie in uw vorderingen plaatsvindt, dan lichten wij u in via het kanaal naar uw keuze. In het bericht kunt u aangeven dat u en in welke mate u voorgefinancierd wilt worden.",
        },
        {
          title: "Hoe snel heb ik bij Delta Hybrid voorfinanciering?",
          description:
            "Na het verzoek tot voorfinancieren, maken wij het gewenste bedrag binnen 1 werkdag over. Toekenning van voorfinanciering is onderhevig aan verificatie door Delta.",
        },
        {
          title:
            "Waarom is het slimmer om voor Delta Hybrid te kiezen ipv. Delta Petrol?",
          description:
            "U betaalt niets extra voor Delta Hybrid, maar profiteert in voorkomende gevallen wel van de voorfinanciering optie.",
        },
        {
          title: "Waarom zou ik voor Delta Electric kiezen ipv. Delta Hybrid?",
          description:
            "Indien uw bedrijf groeit, dan is de behoefte aan liquiditeit over het algemeen groter. Daarbij past een permanente en constante geldstroom, waardoor Delta Electric beter bij u past. Als u af en toe behoefte heeft om de liquiditeit op orde te brengen, dan zou Delta Hybrid wellicht de beste keuze zijn.",
        },
        {
          title: "Waarom zou ik voor Delta Petrol te kiezen ipv. Delta Hybrid?",
          description:
            "Als u soms kiest voor voorfinanciering, dan vergt binnenkomst van het bedrag bij u een extra handeling in de boekhouding en verstoort dit de vordering positie. Bij Delta Petrol blijft uw boekhouding altijd goed afgeletterd.",
        },
        {
          title: "Vallen Delta's producten te combineren met elkaar?",
          description:
            "U dient te kiezen uit de 3 BTW producten (Delta Petrol, Delta Hybrid of Delta Electric). In veel gevallen wordt dit gecombineerd met de dieselolie accijnsteruggaaf (Delta Diesel).",
        },
        {
          title: "Is er voorfinanciering mogelijk over diesel accijnzen? ",
          description:
            "Op belgische dieselaccijnzen is er de mogelijkheid van voorfinanciering. Vraag naar onze tarieven.",
        },
      ],
    },
  ]);
  const [questions2] = React.useState([
    {
      title: "Tarieven",
      questions: [
        {
          title: "Waarom een tarifering op abonnementsbasis?",
          description:
            "Met een abonnement weet u waar u elke maand aan toe bent wat leidt tot stabiele cashflow. Een abonnement is ook iets voordeliger geprijsd ten opzichte van de traditionele no-cure-no-pay methode.",
        },
        {
          title: "Hoeveel betaal ik bij Delta Hybrid voor de voorfinanciering?",
          description:
            "Dit is een vast percentage die wij u enkel via contact kunnen verstrekken. Vraag naar onze tarieven.",
        },
        {
          title:
            "Wat is het tarief dat ik bij Delta Electric betaal voor voorfinanciering?",
          description:
            "Dit tarief in de vorm van een percentage kan variëren, afhankelijk van diverse factoren waaronder onder andere het land van teruggaaf. Vraag naar onze tarieven.",
        },
        {
          title:
            "Waarom is de hoogte van het abonnement tarief afhankelijk van de hoogte van de buitenlands betaalde BTW?",
          description:
            "Hoe meer vrachtwagens, hoe meer er getankt wordt, hoe groter de BTW bedragen, hoe meer werk er moet worden verricht. Dit leidt tot een ander tarief voor een groter wagenpark.",
        },
        {
          title: "Hoe kan ik profiteren van kortingen?",
          description:
            "Korting wordt gegeven naarmate de looptijd van een abonnement langer is. Vraag naar onze kortingen.",
        },
      ],
    },
  ]);
  const [questions3] = React.useState([
    {
      title: "Proces",
      questions: [
        {
          title: "Hoelang moet ik op mijn geld wachten?",
          description:
            "Wij kunnen uw facturen slechts 1 keer per kwartaal indienen. Het zal daarmee langer duren voor u de BTW die u aan het begin van het kwartaal hebt betaald terugkrijgt. Zodra de teruggaaf is gedaan, is het per lidstaat afhankelijk hoe lang u op uw geld moet wachten. Gemiddeld zal de BTW binnen ca. 6 maanden aan u worden terugbetaald. Echter kan dit proces tot teruggaaf versneld worden met Delta Hybrid of Delta Electric.",
        },
        {
          title: "Hoe zorg ik dat Delta mijn facturen krijgt?",
          description:
            "Wij streven naar een zo zorgeloos mogelijke BTW teruggaaf voor u. Daarbij hoort dat u ook geen omkijken heeft naar het aanleveren van facturen. Wij hebben hier diverse methoden voor en u kiest degene die het best bij u past. Allen zijn gericht op 'zero-handling' voor u.",
        },
        {
          title:
            "Kunnen jullie facturen van zowel Shell als BP tegelijk terugvorderen?",
          description:
            "Ja. In een kwartaalaangifte worden alle facturen van de periode in 1 land ingediend, ongeacht de herkomst of aard van de BTW.",
        },
        {
          title: "Welke landen financieren jullie voor?",
          description:
            "Nederland, België, Frankrijk, Oostenrijk, Luxemburg, Duitsland, Spanje Italië, Portugal, Denemarken, Finland, Zweden, Ierland, Verenigd Koninkrijk, Tsjechië en Slovenië.",
        },
        {
          title:
            "Wat bedoelen jullie met het verhelderen van onze boekhouding?",
          description:
            "Wij streven ernaar om uw buitenlandse BTW boekhouding 'clean' te houden en bieden diverse tools om daartoe te komen. ",
        },
        {
          title:
            "Doen jullie ook aan BTW terugvordering en voorfinanciering over overig BTW zoals tol?",
          description:
            "Ja. Alle BTW die in aanmerking komt voor teruggave en in een voorfinancierbaar land is betaald, kan in principe worden voorgefinancierd. Alle facturen zijn onderhevig aan validatie door Delta.",
        },
        {
          title: "Kunnen jullie facturen van vorig jaar terugvorderen?",
          description:
            "De wettelijke deadline is 30 september voor de facturen van het jaar ervoor. Na de deadline kan het recht op teruggaaf komen te vervallen.",
        },
        {
          title: "Hoe krijg ik inzicht in mijn vorderingen?",
          description:
            "In ons klantenportaal krijgt u inzicht in alle vorderingen. U kunt daarbij op land-, aangifte- of zelfs factuurniveau alles volgen, inclusief de te verwachten tijd dat de aangiftes aan u worden uitbetaald.",
        },
        {
          title:
            "Welke documenten moet ik aanleveren om in aanmerking te komen met diesel accijnzen teruggaaf?",
          description:
            "Wij streven naar een zo zorgeloos mogelijke accijns teruggaaf voor u. Wij zorgen ervoor dat alle nodige documenten gereed gemaakt worden zodat u in aanmerking komt met accijns teruggaaf.",
        },
        {
          title:
            "Is er een deadline voor de beantwoording van de vragen die door het land van teruggaaf wordt gesteld?",
          description:
            "Binnen 1 maand dient er op een verzoek van extra informatie te worden gereageerd. Ben je buiten de deadline, dan verlies je het recht op teruggaaf.",
        },
        {
          title:
            "Worden de teruggaafverzoeken in het land van vestiging gedaan?",
          description:
            "De teruggaafverzoeken worden ingediend in het land van vestiging. Na een aantal basis controles worden de aangiftes doorgestuurd naar het land van teruggaaf en daar vindt verdere afhandeling plaats.",
        },
        {
          title:
            "Wat is de maximale termijn waarop de lidstaten moeten reageren op het teruggaafverzoek?",
          description:
            "4 maanden. Toch zijn er lidstaten die er langer over doen. ",
        },
        {
          title:
            "Kan er om extra informatie worden verzocht door het land van teruggaaf?",
          description: "Ja. Er komen geregeld vragen over teruggaafverzoeken.",
        },
        {
          title: "In welke taal communiceert het land van teruggaaf?",
          description: "Het land van teruggaaf communiceert in de eigen taal.",
        },
        {
          title:
            "Waar vindt de afhandeling en goedkeuring van het teruggaafverzoek plaats?",
          description:
            "Uiteindelijk beslist het land van teruggaaf over het wel, niet of gedeeltelijke toekenning (beschikking).",
        },
      ],
    },
  ]);
  const [questions4] = React.useState([
    {
      title: "Onboarding",
      questions: [
        {
          title: "Hoelang duurt het overstappen van BTW-agent?",
          description:
            "U kunt in principe ieder kwartaal opzeggen. De opzegtermijnen met uw huidige agent is meestal 1 maand. Wij treden in contact met uw huidige provider, teneinde zeker te zijn dat we alle facturen (wie doet wat) ingediend krijgen.",
        },
        {
          title:
            "Zijn er servicekosten gebonden aan het overstappen van BTW-agent?",
          description:
            "Nee. Wij zijn blij met u als klant en vinden dat we er alles aan moeten doen u soepel en tevreden te helpen. Daar hoort geen initiële overstap fee bij!",
        },
        {
          title: "Moet ik mijn huidige btw agent opzeggen?",
          description:
            "Indien u besluit met Delta in zee te gaan, dan dient u het lopende kwartaal met uw huidige provider uit te dienen. Wij kunnen desgewenst opzegging voor u bewerkstelligen.",
        },
        {
          title: "Wat moet ik ondertekenen?",
          description:
            "Delta wil het u zo makkelijk mogelijk maken. We zullen alleen een aantal documenten en machtigingen moeten laten ondertekenen. We weten dat u hierop niet zit te wachten en begeleiden u dan ook optimaal, zodat een vlekkeloze overgang gegarandeerd is.",
        },
      ],
    },
  ]);
  const [questions5] = React.useState([
    {
      title: "Delta",
      questions: [
        {
          title: "Wat is de toegevoegde waarde van een onafhankelijke partij?",
          description:
            "Delta is onafhankelijk en kan daarom alle facturen gelijk en tegen hetzelfde tarief behandelen.",
        },
      ],
    },
  ]);

  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ m: 0, p: 0, backgroundColor: "#FFFFFF" }}
      >
        <Container maxWidth="xl">
          <Typography
            sx={{
              mt: 5,
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "2.8rem",
              "@media (min-width:600px)": {
                mt: 5,
                fontSize: "4.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
            }}
          >
            FAQ
          </Typography>
          <Typography
            mb={7}
            sx={{
              color: "#2B5BAA",
              textAlign: "center",
              fontSize: "1rem",
              "@media (min-width:600px)": {
                mt: 0,
                fontSize: "1.4rem",
              },
              fontFamily: "Century Gothic",
              fontWeight: "Regular",
            }}
          >
            Antwoorden op de meest gestelde vragen.
          </Typography>
          <FAQElement questionInfo={questions1} />
          <FAQElement questionInfo={questions2} />
          <FAQElement questionInfo={questions3} />
          <FAQElement questionInfo={questions4} />
          <FAQElement questionInfo={questions5} />
          {/* <FAQElement questionInfo={questions6} /> */}
        </Container>
      </Container>
    </>
  );
};

export default FAQs;
