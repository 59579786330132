import React from "react";
import NewsArticle30 from "../elements/NewsArticle30";
import NewsNav from "../elements/NewsNav";

const NewsDetails30 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle30 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails30;