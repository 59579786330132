import React from "react";
import NewsBanner from "../elements/NewsBanner";
import NewsGrid from "../elements/NewsGrid";

const News = () => {
  return (
    <>
      <NewsBanner />
      <NewsGrid />
    </>
  );
};

export default News;
