import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle30 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Mogelijke oplossing voor liquiditeitskrapte in de transportsector 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage30.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                26-10-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De transportsector staat voor een{" "} 
            <span style={{color: "#2b5baa"}}>
            Financiële tweestrijd
            </span>
            , waarbij de prijzen enerzijds onder druk staan en investeringen in de toekomst dagelijks onder de aandacht gebracht worden. Deze complexe situatie heeft gevolgen voor de{" "}
            <span style={{color: "#9c29ff"}}>
            Liquiditeit van de transporteur.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de transportsector heerst{" "}
            <span style={{ color: "#2b5baa" }}>
            voortdurende prijsdruk
            </span>
            . Sterke concurrentie zorgt ervoor dat transportbedrijven hun tarieven laag moeten houden om klanten aan te trekken en te behouden. Aan de andere kant{" "}
            <span style={{ color: "#9c29ff" }}>
            stijgen de kosten{" "}
            </span>
            gestaag en in sommige gevallen zelfs exponentieel. Transportbedrijven worden{" "}
            <span style={{ color: "#2b5baa" }}>
            gedwongen{" "}
            </span>
            vroeg of laat{" "}
            <span style={{ color: "#2b5baa" }}>
            te investeren{" "}
            </span>
            in groene ontwikkelingen, waaronder milieuvriendelijkere voertuigen. Bovendien worden de kosten van wegenbelasting, brandstof en{" "}
            <span style={{ color: "#2b5baa" }}>
            naleving van nieuwe voorschriften
            </span>
            , zoals de tachograaf 2, aanzienlijk hoger. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De combinatie van de prijzendruk en de urgentie op investeringen zorgt voor liquiditeitsproblemen bij transportbedrijven. Daar bovenop komt dat{" "}
            <span style={{ color: "#9c29ff" }}>
            banken terughoudend{" "}
            </span>
            zijn in het verstrekken van leningen voor dergelijke investeringen.  
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Één van de mogelijke oplossingen voor deze liquiditeitskrapte is het inschakelen van een BTW- & accijns intermediair, zoals Delta. Deze dienstverleners bieden{" "}
            <span style={{ color: "#2b5baa" }}>
            voorfinanciering op de BTW- en accijns{" "}
            </span>
            teruggaaf die transportbedrijven anders zouden moeten afwachten. Bij Delta kan u hiervan geprofiteren d.m.v. structurele voorfinanciering genaamd{" "}
            <span style={{ color: "#2b5baa" }}>
            <a href="https://deltarefundsolutions.com/DeltaElectric">Delta Electric</a>
            </span>
            , of voorfinanciering op afroepbare quotatie voor een deel of het geheel van de BTW-/accijnssom genaamd{" "} 
            <span style={{ color: "#2b5baa" }}>
            <a href="https://deltarefundsolutions.com/DeltaHybrid">Delta Hybrid</a>
            </span>
            . Dit verbetert de cashflow en versterkt de financiële positie, waardoor er weer ruimte ontstaat voor investeren en het opvangen van financiële uitdagingen. 
            Benieuwd naar uw mogelijkheden,{" "}
            <span style={{ color: "#9c29ff" }}>
            <a href="https://deltarefundsolutions.com/Contact">neem contact met ons op</a>{" "}
            </span>
            en wij doen u een aantrekkelijk aanbod. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle30;
