import React from "react";
import NewsArticle28 from "../elements/NewsArticle28";
import NewsNav from "../elements/NewsNav";

const NewsDetails28 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle28 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails28;