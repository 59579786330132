import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const CalculateButton = (props) => {
  const navigate = useNavigate();
  const handleCalculateNow = () => {
    navigate("/Contact");
  };

  return (
    <React.Fragment>
      <Grid container display="flex" flexDirection="column">
        <Box
          display="flex"
          container
          // maxWidth={((xs = 300), (md = 350))}
          sx={{
            maxWidth: props.maxWidth ? props.maxWidth : { xs: 350, md: "100%" },
            width: "100%",
          }}
          justifyContent="center"
          alignItems="center"
          // backgroundColor="pink"
        >
          <Button
            onClick={handleCalculateNow}
            // variant="Outlined"

            sx={{
              textTransform: "unset",
              // my: 0,
              mt: 7,
              backgroundColor: "#C85EEE",
              "&:hover": {
                backgroundColor: "#9d49bb",
              },
              color: "#FFFFFF",
              width: { xs: 300, md: 350 },
              height: 86,
              borderRadius: 35,
              fontSize: "29px",
              fontFamily: "Century Gothic",
              fontWeight: "Bold",
              background: "linear-gradient(to right, #9b29ff, #c85eee)",
              lineHeight: "34.8px",
              letterSpacing: "-0.35px",
            }}
          >
            Tarief aanvragen
          </Button>
        </Box>
        <Box
          mt={1}
          display="flex"
          container
          sx={{
            maxWidth: props.maxWidth ? props.maxWidth : { xs: 350, md: "100%" },
            width: "100%",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: "#6b6a6a",
              fontFamily: "HelveticaNeue-Light",
              fontSize: "20px",
              fontWeight: "light",
              textAlign: "center",
              lineHeight: "24px",
              letterSpacing: "-0.3",
            }}
          >
            Neem contact met ons op
          </Typography>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default CalculateButton;
