import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle11 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >             
                Verhoging van dieselolieaccijns in Litouwen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage11.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                17.05.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Na de goedkeuring van het "groene" brandstofaccijnzenpakket door het 
              Litouwse Parlement (Seimas) vorige week, zal{" "}
              <span style={{ color: "#2b5baa" }}>
              vanaf 2025 het accijnstarief in Litouwen voor diesel gelijk zijn 
              aan dat van benzine
              </span>
              . Vanaf 2026 zal het zelfs hoger zijn dan het accijnstarief voor benzine.{" "}
              <span style={{ color: "#9c29ff" }}>
              Op deze manier hopen de autoriteiten de keuze voor minder vervuilende 
              brandstoffen te stimuleren 
              </span>
              .
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Volgens berekeningen van het Litouwse Ministerie van Milieu zal 
              bij goedkeuring van het “groene” brandstofaccijnzenpakket een liter 
              diesel bij tankstations{" "}
              <span style={{ color: "#2b5baa" }}>
              jaarlijks met 4-6 cent duurder worden{" "}
              </span>
              als gevolg van deze maatregel. Volgens diverse nieuwsbronnen zal 
              er daarbovenop{" "}
              <span style={{ color: "#9c29ff" }}>
              nog een regeling van accijnstarieven vanaf 2025{" "}
              </span>
              worden toegevoegd met betrekking tot de vermindering van CO2-uitstoot. 
              Naar schatting is deze stijging{" "}
              <span style={{ color: "#9c29ff" }}>
              nog eens 3 cent per liter
              </span>
              .
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>
            Volgens deze berekening zal de dieselprijs in Litouwen in 2025 al met 10 
            cent gestegen zijn, wat een prijsstijging impliceert van ruim 7%
            </span>
            . De gemiddelde dieselprijs in Litouwen zal volgens de huidige cijfers in 
            2025 ongeveer gelijk zijn aan die van Hongarije en buurland Estland. Het 
            zal in Polen daarentegen gemiddeld gezien aantrekkelijker worden om te tanken, 
            omdat de dieselprijzen van Polen en Litouwen momenteel vergelijkbaar zijn, 
            maar na de verhoging van 10 cent niet meer. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#9c29ff" }}>
            Het tankgedrag van Litouwse transportondernemingen{" "}
            </span>
            zou met deze maatregelen wel eens drastisch kunnen veranderen door een{" "}
            <span style={{ color: "#9c29ff" }}>
            verschuiving van het “thuisland” naar een ander land, bijvoorbeeld Polen
            </span>
            . Dit geldt waarschijnlijk ook voor transportondernemingen uit Estland en 
            Letland. Ook die zouden wel eens kunnen gaan uitwijken naar Polen of andere 
            meer westerse landen. Afhankelijk van het traject zijn landen als Luxemburg 
            en België (wegens de dieselolieaccijns compensatie) mogelijk ook meer 
            interessante “tanklanden”. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Litouwen erkent overigens dat ze een doorvoerland zijn en dat dit gevolgen zal 
            hebben voor het tankgedrag van de inwoners van Litouwen zelf en de omliggende 
            landen. Met deze verandering zullen vooral bedrijven maar ook consumenten 
            hoogstwaarschijnlijk geneigd zijn om de tank te vullen in omliggende landen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle11;
