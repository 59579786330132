import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle46 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            EU kondigt nieuwe Euro-7 emissienormen aan 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage46.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                07-03-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Europese wetgevers hebben overeenstemming bereikt over de komende Euro 7-normen. De Euro 7-normen zullen de huidige Euro 6-normen{" "}
            <span style={{color: "#2b5baa"}}>
            vervangen en strengere limieten{" "}
            </span>
            stellen aan de uitstoot van {" "}
            <span style={{color: "#2b5baa"}}>
            fijnstof en stikstofoxiden {" "}
            </span>
             voor auto's, bestelwagens en vrachtwagens.
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#9c29ff" }}>
            Vanaf 2028 {" "}
            </span>
            (voor vrachtwagens) zullen fabrikanten moeten voldoen aan nieuwe normen bij de productie van nieuwe voertuigen. 
            De introductie van de Euro-7 norm maakt een grote stap in de voortdurende 
            verduurzaming van de transportsector:
<table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                            De limiet voor stikstofoxiden (NOx) wordt verlaagd naar {" "}
                            <span style={{color: "#2b5baa"}}>
                            200 mg/kWh
                            </span>
                            , terwijl de huidige limiet momenteel op 400 mg/kWh ligt
                        </li>
                        <li>   
                        
            Accu's van elektrische voertuigen moeten{" "}
            <span style={{color: "#2b5baa"}}>
            na vijf jaar{" "}
            </span>
            nog minimaal{" "}
            <span style={{color: "#2b5baa"}}>
            80%{" "}
            </span>
            van hun oorspronkelijke capaciteit behouden en na{" "}
            <span style={{color: "#9c29ff"}}>
            acht jaar{" "}
            </span>
            minstens{" "}
            <span style={{color: "#9c29ff"}}>
            72%
            </span>
                        </li>
                        <li> 
                        Limieten aan de slijtage van banden en de vervuiling veroorzaakt door remdeeltjes. Hier moeten nog concrete besluiten over gemaakt worden 
                        </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
        Samen met de CO2-standaarden gaan de nieuwe emissienormen de {" "}
        <span style={{color: "#9c29ff"}}>
        nodige investeringen eisen van fabrikanten 
        </span>
        . Het zal de fabrikanten forceren om te investeren in het fabricatie proces van de vrachtwagens. Dit zal 
        gevolgen kunnen hebben op de {" "}
        <span style={{color: "#9c29ff"}}>
        aanschafprijs.
        </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Voor Nederlandse transportbedrijven betekent de overeenkomst over de Euro 7-normen een aanzienlijke verandering in de regelgeving voor hun{" "}
              <span style={{ color: "#2b5baa" }}>
              vloot van voertuigen
            </span>
            . Deze veranderingen zullen transportbedrijven aanzetten om zich aan te passen aan de nieuwe normen en mogelijk te investeren in 
            nieuwe voertuigen. Sinds de regelgeving medio 2028 in zullen gaan, geeft dit transportbedrijven de tijd om de nodige aanpassingen 
            in te plannen en door te voeren.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle46;
