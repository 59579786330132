import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle56 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
           Problemen met Øresund brug BTW teruggaaf

            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage56.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                12-09-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Als uw bedrijf actief is in het wegvervoer naar Scandinavië, heeft u of een collega waarschijnlijk al eens gebruikgemaakt van de{" "}
            <span style={{color: "#2b5baa"}}>
            Øresund brug
            </span>
            . Deze iconische verbinding tussen{" "}
            <span style={{color: "#9c29ff"}}>
            Denemarken{" "}
            </span>
            (Kopenhagen) en{" "}
            <span style={{color: "#9c29ff"}}>
            Zweden{" "}
            </span>
            (Malmö) heeft de grensovergang halverwege de brug liggen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De brug is{" "}
            <span style={{color: "#2b5baa"}}>
            eigendom van zowel het Deense staatsbedrijf{" "}
            </span>
            (A/S Øresund){" "}
            <span style={{color: "#2b5baa"}}>
            en een Zweedse overheidsorganisatie {" "}
            </span>
            (Svenska Kraftnät). Om over de brug te passeren wordt{" "}
            <span style={{color: "#9c29ff"}}>
            tol geheven
            </span>
            . De twee organisaties hebben afgesproken de tol inkomsten te gebruiken om de voorinvesteringen van de bouw en het onderhoud van de brug te dekken.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De tol die u betaalt over deze brug is met {" "}
            <span style={{color: "#9c29ff"}}>
            BTW belast
            </span>
            , hetgeen impliceert dat u de BTW als BTW plichtig bedrijf {" "}
            <span style={{color: "#9c29ff"}}>
            terug kan vorderen
            </span>
            . In beginsel is het niet altijd helder of de betaalde BTW de Deense of de Zweedse is. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
              >
            De organisaties hebben echter onderling afgesproken dat de {" "}  
            <span style={{color: "#2b5baa"}}>
            tolheffing op Zweeds grondgebied{" "}
            </span>
            plaatsvindt en dus ook met{" "}
            <span style={{color: "#2b5baa"}}>
            Zweedse BTW belast {" "}
            </span>
            dient te worden. Sommige{" "}
            <span style={{color: "#9c29ff"}}>
            tolmaatschappijen zijn hier niet altijd goed over ingelicht
            </span>
            , waardoor de tol van de Øresund brug op de Deense factuur met Deense BTW belast wordt. Mocht u deze Deense BTW vervolgens indienen voor BTW-teruggaaf, dan wordt het{" "}
            <span style={{color: "#2b5baa"}}>
            verzoek afgewezen. 
            </span>
              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Er zijn nog steeds enkele betaalpas maatschappijen die de BTW {" "}
            <span style={{color: "#9c29ff"}}>
            onjuist op de facturen vermelden
            </span>
            . Er is wel een oplossing voor dit probleem, maar die praktische {" "}
            <span style={{color: "#2b5baa"}}>
            oplossing is ingewikkelder dan u wellicht denkt
            </span>
            . Mocht u tegen dit probleem aanlopen, dan helpen wij u graag. Neem in dat geval <a href="https://deltarefundsolutions.com/Contact"> contact</a> met ons op. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle56;
