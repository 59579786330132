import React from "react";
import NewsArticle38 from "../elements/NewsArticle38";
import NewsNav from "../elements/NewsNav";

const NewsDetails38 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle38 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails38;