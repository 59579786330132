import { Grid, Container, Typography, Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
// import NormalRefund from "../pages/NormalRefund";

const ProductNav = (props) => {
  const handleOnClick = (e, productChosen) => {
    // alert(productChosen);
  };

  if (props.nrActive) {
    alert(props.nrActive);
  }
  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
        <Grid container md={12} xs={12} justifyContent="center">
          <Grid
            display="flex"
            item
            md={3}
            xs={6}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              height: 30,
              cursor: props.deltaPetrolActive ? "" : "pointer",
              mt: 1,
              borderRight: "solid rgba(0, 0, 0, 0.12) 1px",
              color: props.deltaPetrolActive
                ? "#2B5BAA"
                : "rgba(0, 0, 0, 0.12)",
              fontFamily: "HelveticaNeue-Light",
              // backgroundColor: "pink",
              a: {
                color: props.deltaPetrolActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
              "a:visited": {
                color: props.deltaPetrolActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            <Typography
              onClick={(e) => handleOnClick(e, "Normal refund")}
              textAlign="center"
              sx={{
                fontSize: { xs: "20px", md: "22px", xl: "22px" },
                lineHeight: { xs: "22px", md: "24px", xl: "240px" },
                letterSpacing: 0,
              }}
            >
              {props.deltaPetrolActive ? (
                "Normal refund"
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    cursor: props.deltaPetrolActive ? "default" : "pointer",
                  }}
                  to="/DeltaPetrol"
                >
                  Normal refund
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            sx={{
              cursor: props.deltaHybridActive ? "" : "pointer",
              mt: 1,
              borderRight: "solid rgba(0, 0, 0, 0.12) 0px",
              "@media (min-width:600px)": {
                borderRight: "solid rgba(0, 0, 0, 0.12) 1px",
              },
              color: props.deltaHybridActive
                ? "#2B5BAA"
                : "rgba(0, 0, 0, 0.12)",
              fontFamily: "HelveticaNeue-Light",
              fontSize: "0.8rem",
              fontWeight: "light",
              textAlign: "center",
              a: {
                color: props.deltaHybridActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
              "a:visited": {
                color: props.deltaHybridActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            <Typography
              onClick={(e) => handleOnClick(e, "Excise duties")}
              textAlign="center"
              sx={{
                fontSize: { xs: "20px", md: "22px", xl: "22px" },
                lineHeight: { xs: "22px", md: "24px", xl: "24px" },
                letterSpacing: 0,
              }}
            >
              {props.deltaHybridActive ? (
                "Flexibel refund"
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  to="/DeltaHybrid"
                >
                  Flexibel refund
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            sx={{
              cursor: props.deltaElectricActive ? "" : "pointer",
              mt: 1,
              borderRight: "solid rgba(0, 0, 0, 0.12) 1px",
              color: props.deltaElectricActive
                ? "#2B5BAA"
                : "rgba(0, 0, 0, 0.12)",
              fontFamily: "HelveticaNeue-Light",
              fontSize: "0.8rem",
              fontWeight: "light",
              textAlign: "center",
              a: {
                color: props.deltaElectricActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
              "a:visited": {
                color: props.deltaElectricActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            <Typography
              onClick={(e) => handleOnClick(e, "Fast Refund duties")}
              textAlign="center"
              sx={{
                fontSize: { xs: "20px", md: "22px", xl: "22px" },
                lineHeight: { xs: "22px", md: "24px", xl: "24px" },
                letterSpacing: 0,
              }}
            >
              {props.deltaElectricActive ? (
                "Fast refund"
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  to="/DeltaElectric"
                >
                  Fast refund
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            md={3}
            xs={6}
            sx={{
              cursor: props.deltaDieselActive ? "" : "pointer",
              mt: 1,
              color: props.deltaDieselActive
                ? "#2B5BAA"
                : "rgba(0, 0, 0, 0.12)",
              fontFamily: "HelveticaNeue-Light",
              fontSize: "0.8rem",
              fontWeight: "light",
              textAlign: "center",
              a: {
                color: props.deltaDieselActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
              "a:visited": {
                color: props.deltaDieselActive
                  ? "#2B5BAA"
                  : "rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            <Typography
              onClick={(e) => handleOnClick(e, "Excise duties")}
              textAlign="center"
              sx={{
                fontSize: { xs: "20px", md: "22px", xl: "22px" },
                lineHeight: { xs: "22px", md: "24px", xl: "24px" },
                letterSpacing: 0,
              }}
            >
              {props.deltaDieselActive ? (
                "Excise duties"
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  to="/DeltaDiesel"
                >
                  Excise duties
                </Link>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ mt: 1 }} />
    </React.Fragment>
  );
};
export default ProductNav;
