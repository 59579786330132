import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle34 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Wel of geen BTW over tol?
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage34.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                23-11-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Op het gebied van tolnetwerken kunnen we{" "}
            <span style={{color: "#2b5baa"}}>
            twee typen onderscheiden
            </span>
            : tol gefaciliteerd door de{" "} 
            <span style={{color: "#2b5baa"}}>
            overheid{" "}
            </span>
            en tol gefaciliteerd door{" "}
            <span style={{color: "#2b5baa"}}>
            private beheerders
            </span>
            . Wanneer de{" "}
            <span style={{color: "#9c29ff"}}>
            overheid{" "}
            </span>
            de tol faciliteert, wordt er vrijwel {" "}
            <span style={{color: "#9c29ff"}}>
            nooit BTW{" "}
            </span>
            berekend over de kosten. Daarentegen wordt bij tol gefaciliteerd door{" "}
            <span style={{color: "#2b5baa"}}>
            private beheerders altijd BTW{" "}
            </span>
            in rekening gebracht. Kijkend naar enkele van onze buurlanden, waar Nederlandse internationale transportondernemingen vaak doorheen rijden:
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In{" "}
            <span style={{ color: "#9c29ff" }}>
            Duitsland{" "}
            </span>
            wordt de bekende{" "}
            <span style={{ color: "#9c29ff" }}>
            'PKW-Maut'{" "}
            </span>
            geheven, verzorgd door de Duitse overheid, waardoor er {" "}
            <span style={{ color: "#9c29ff" }}>
            geen BTW{" "}
            </span>
            over wordt berekend. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In{" "}
            <span style={{ color: "#2b5baa" }}>
            Frankrijk{" "}
            </span>
            wordt tol geheven onder de naam{" "}
            <span style={{ color: "#2b5baa" }}>
            'Péage'
            </span>
            , die vrijwel altijd wordt beheerd door private partijen en dus{" "}
            <span style={{ color: "#2b5baa" }}>
            wel BTW{" "}
            </span>
            over berekend is. Enkele van deze private beheerders zijn ASF, ASPRR, Sanef, Area, Vinci Autoroutes, Cofiroute en Escota. Elk van deze beheerders dekt een specifieke regio in Frankrijk. 
            Afhankelijk van de gekozen route komt men in aanraking met één of meerdere van deze private Péage-beheerders. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In{" "}
            <span style={{ color: "#9c29ff" }}>
            België{" "}
            </span>
            wordt tol geheven in drie verschillende regio's: Vlaanderen, Brussel en Wallonië. In de regio's{" "}
            <span style={{ color: "#2b5baa" }}>
            Vlaanderen en Brussel{" "}
            </span>
            wordt het tolnetwerk beheerd door de Belgische overheid, waarbij{" "}
            <span style={{ color: "#2b5baa" }}>
            geen BTW{" "}
            </span>
            over de tol wordt berekend. In de regio{" "}
            <span style={{ color: "#9c29ff" }}>
            Wallonië{" "}
            </span>
            wordt de tol verzorgd door de private beheerder Sofico, waarbij{" "}
            <span style={{ color: "#9c29ff" }}>
            wel BTW{" "}
            </span>
            over de kosten wordt berekend. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle34;
