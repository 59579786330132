import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle29 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
             Gecompliceerde fiscale entiteitsstructuren kunnen de teruggave van Belgische accijnzen in gevaar brengen  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage29.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                19-10-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Belgische douane biedt{" "} 
            <span style={{color: "#2b5baa"}}>
            internationale professionele vervoerders{" "}
            </span>
            met vrachtwagens van{" "}
            <span style={{color: "#2b5baa"}}>
            meer dan 7,5 ton gewicht{" "}
            </span>
            (F.2 op kenteken) de mogelijkheid om een deel van de betaalde accijnzen op getankte liter diesel in België terug te vorderen. Op dit moment bedraagt de vergoeding {" "}
            <span style={{color: "#9c29ff"}}>
            20,50665 cent per getankte liter diesel.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om een soepele teruggave te waarborgen, is het van essentieel belang dat alle variabele gegevens op het{" "}
            <span style={{ color: "#2b5baa" }}>
            PDIE-formulier{" "}
            </span>
            zo nauwkeurig mogelijk worden ingevuld. Bovendien zijn er enkele valkuilen die vermeden moeten worden, aangezien deze uiteindelijk tot problemen kunnen leiden, zoals{" "}
            <span style={{ color: "#9c29ff" }}>
            vertraagde- of afgewezen teruggaven.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa"}}>
            Nederland heeft een vrij unieke fiscale positie in Europa
            </span>
            , met name met betrekking tot{" "}
            <span style={{ color: "#2b5baa" }}>
            fiscale structuren en fiscale eenheden
            </span>
            . Dit kan voor buitenlandse autoriteiten problematisch zijn en leiden tot discussies. Het is van groot belang dat we deze problemen vermijden.{" "}
            <span style={{ color: "#9c29ff" }}>
            Delta onderhoudt nauw contact met de dienst Teruggave Professionele Diesel in Brussel en beschikt over methoden om de zaken soepel te laten verlopen. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Een andere mogelijke reden voor afkeuring is het vermelden van een{" "}
            <span style={{ color: "#2b5baa" }}>
            onjuist BTW-nummer op de facturen{" "}
            </span>
            door oliemaatschappijen, met name uw eigen BTW-nummer. Dit kan eveneens leiden tot afkeuring op den duur.{" "}
            <span style={{ color: "#9c29ff" }}>
            Delta heeft procedures om deze kwestie snel aan te pakken en ervoor te zorgen dat alles correct is
            </span>
            , zodat er geen reden tot afkeuring meer bestaat. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Bovendien zijn er andere details waar de Teruggavedienst op let, waarover Delta uitgebreide kennis heeft. Zodra alles volledig in orde is, wordt{" "}
            <span style={{ color: "#2b5baa" }}>
            een snellere en efficiëntere terugbetaling gegarandeerd{" "}
            </span>
            , waarbij ervoor wordt gezorgd dat de{" "}
            <span style={{ color: "#2b5baa" }}>
            volledige teruggaaf wordt gerealiseerd. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Delta verzekert een snelle en volledige teruggave door de juiste structuur te handhaven en overeenkomsten te 
            sluiten met klanten over de uiterste indieningsdatum. Wat betreft dat laatste, worden de termijnen in een{" "}
            <span style={{ color: "#9c29ff" }}>
            SLA (Service Level Agreement){" "}
            </span>
            vastgelegd, en deze zijn{" "}
            <span style={{ color: "#2b5baa" }}>
            doorgaans sneller dan men zou verwachten! 
            </span>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle29;
