import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle6 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Internationale ondernemingen lopen gemiddeld €14.000 aan BTW mis
              per jaar
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage7.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                20.04.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Uit een rapport van de Europese Commissie blijkt dat er in de
              EU-27 lidstaten een groot verschil bestaat tussen de verwachte
              BTW-opbrengsten en de daadwerkelijk ontvangen opbrengsten, ook wel
              bekend als<span style={{ color: "#2B5BAA" }}> de "BTW-gap"</span>.
              Dit bedroeg<span style={{ color: "#9c29ff" }}> in 2018 </span>maar
              liefst<span style={{ color: "#9c29ff" }}> € 140 miljard</span>,
              wat overeenkomt met
              <span style={{ color: "#9c29ff" }}>
                {" "}
                11,2% van de verwachte BTW-opbrengsten
              </span>
              . Het rapport identificeert verschillende oorzaken van de BTW-gap,
              waaronder het niet correct toepassen van BTW-teruggaaf door
              ondernemingen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Ongeveer
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                80% van de ondernemingen{" "}
              </span>
              die actief zijn in de EU
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                past BTW-teruggaaf niet correct toe
              </span>
              , waardoor ze geld laten liggen waar ze wel recht op hebben. De
              gemiddelde waarde van de niet-teruggevorderde BTW bedraagt
              ongeveer
              <span style={{ color: "#9c29ff" }}>
                {" "}
                € 14.000 per jaar per onderneming
              </span>
              . In het rapport wordt specifiek de transportsector benoemd als
              een van de sectoren waar de BTW-gap aanzienlijk hoog is. Dit omdat
              veel transportondernemingen niet bekend zijn met de alsmaar
              veranderende BTW-regels in de vele lidstaten waar zij actief zijn.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Is dit geheel aan de transportondernemer zelf te danken? Zeker
              niet. Naast dat transportondernemingen gebrek aan tijd en middelen
              hebben om zich te verdiepen in de
              <span style={{ color: "#2B5BAA" }}>
                {" "}
                alsmaar veranderende wet- en regelgevingen
              </span>
              , staan de overheden en autoriteiten nou niet bekend om hun
              duidelijke/heldere communicatie. Zo communiceren de overheden en
              autoriteiten vaak in hun eigen native taal, zijn de
              communicatiekanalen niet goed afgestemd op de internationale
              ondernemingen en kan je niet verwachten dat ze je een reminder
              geven over een wijziging in wet- of regelgeving. Houd je niet
              rekening met deze wijziging, pech, recht op BTW teruggaaf weg. Ook
              het niet halen van de deadlines wordt als een oorzaak genoemd. Is
              de verantwoordelijke medewerker ziek, net uit dienst, terwijl de
              deadline verstrijkt, dan riskeert de ondernemer het verlies van
              recht op teruggaaf. Een reden om dit soort zaken aan een
              specialist over te laten, een soort verzekeringspremie zullen we
              maar zeggen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Het rapport doet aanbevelingen om de BTW-gap in de transportsector
              te verminderen, zoals het verbeteren van de samenwerking tussen de
              lidstaten op het gebied van het verzamelen van gegevens en het
              delen van informatie over transportbedrijven die zich niet houden
              aan de BTW-regels. Ook wordt aanbevolen om meer controles uit te
              voeren op de naleving van de BTW-regels in de transportsector en
              om meer voorlichting te geven aan transportbedrijven over de
              BTW-regels in de lidstaten waar zij actief zijn.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Kortom, de BTW-gap vormt nog steeds een
              <span style={{ color: "#9c29ff" }}>
                {" "}
                aanzienlijk probleem in de EU-27 lidstaten{" "}
              </span>
              en er is meer samenwerking en coördinatie nodig tussen de
              lidstaten om dit probleem aan te pakken.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle6;
