import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import DeltaGreen from "../../assets/DeltaGreen.png";
import { Container } from "@mui/system";
import Image from "mui-image";
import { Link } from "react-router-dom";

const LandingConfirmation = () => {
  return (
    <Container maxWidth="100%" sx={{ m: 0, p: 0, backgroundColor: "#FAFCFF" }}>
      <Container maxWidth="xl">
        <Grid container alignItems="center">
          {" "}
          {/* Added alignItems to center vertically */}
          <Grid item lg={6} md={12}>
            <Box
              sx={{ display: "flex", p: { xs: 3, md: 6 }, mt: 10, mb: 10 }}
              justifyContent="center"
            >
              <Image
                style={{
                  alignSelf: "center",
                  ObjectFit: "cover",
                  maxWidth: "350px", // Adjusted this for a smaller image
                  maxHeight: "352px",
                }}
                src={DeltaGreen}
                alt="cur"
              />
            </Box>
          </Grid>
          <Grid item lg={6} md={12}>
            <Box sx={{ p: { xs: 3, md: 6 }, pr: { md: 0 } }}>
              <Typography
                sx={{
                  color: "#2B5BAA",
                  textAlign: "left",
                  fontSize: { xs: "40px", md: "71px" },
                  fontFamily: "Century Gothic",
                  fontWeight: "Bold",
                }}
              >
                Gelukt!
              </Typography>
              <Typography
                sx={{
                  mt: 3,
                  color: "#6b6a6a",
                  textAlign: "left",
                  fontSize: "1.8rem",
                  lineHeight: "2.4rem",
                  "@media (min-width:600px)": {
                    mt: 2,
                    fontSize: "2.2rem",
                    lineHeight: "2.8rem",
                  },
                  fontFamily: "HelveticaNeue-Light",
                }}
              >
                Uw verzoek tot contact is binnen.
                <br />
                Wij zullen spoedig contact met u<br />
                opnemen.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          backgroundColor: "#FAFCFF",
          borderTop: "1px solid #DBDBDB",
          // borderBottom: props.top ? "1px solid #DBDBDB" : null,
          height: "76px",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "20px", md: "25px", xl: "25px" },
              lineHeight: { xs: "20.2px", md: "25.2px", xl: "25.2px" },
              letterSpacing: "-0.33px",
              fontFamily: "HelveticaNeue-Light",
              a: {
                color: "#2b5baa",
              },
              "a:visited": {
                color: "#2b5baa",
              },
            }}
          >
            <Link to="/Home" style={{ textDecoration: "none" }}>
              &#60; Terug naar Home pagina
            </Link>
          </Typography>
        </Container>
      </Container>
    </Container>
  );
};

export default LandingConfirmation;
