// import { Typography } from "@mui/material";
// import { ContactEmergency, RampRight } from "@mui/icons-material";
// import Container from "@mui/material/Container";
import { Box, Typography, Grid, Paper } from "@mui/material";
// import Image from "mui-image";
import React from "react";
import background1 from "../../assets/HomePage.jpg";
// import liveInzicht from "../../assets/Live inzicht icoontje.png";
// import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";

// import Button from "@mui/material/Button";
import { Container } from "@mui/system";
// import { useNavigate } from "react-router-dom";
import CalculateButton from "../elements/CalculateButton";

const BannerHome = () => {
  // const navigate = useNavigate();
  // const handleCalculateNow = () => {
  //   navigate("/pricingCalculation");
  // };
  return (
    <Container
      maxWidth="100%"
      sx={{
        // color: "#fff",
        m: 0,
        p: 0,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${background1})`,
        backgroundPosition: "center",
        borderRadius: "0px",
        boxShadow: "none",
        height: "980px",
        width: "100%",
      }}
      // style={{ height: "auto", width: "100%" }}
    >
      <Container maxWidth="xl">
        <Paper
          // maxWidth="xl"
          // sx={{
          //   position: "relative",
          //   // color: "#fff",
          //   mt: 1,
          //   pb: 0,
          //   backgroundSize: "contain",
          //   backgroundRepeat: "no-repeat",
          //   backgroundPosition: "right bottom",
          //   backgroundImage: `url(${background1})`,
          //   borderRadius: "0px",
          //   boxShadow: "none",
          // }}
          sx={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          {/* Increase the priority of the hero background image */}
          {/* {<img style={{ display: "none" }} src={background1} alt="Text" />} */}
          <Box
            border="none"
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              // backgroundColor: "rgba(0,0,0,.3)",
            }}
          />
          <Grid container>
            <Grid item lg={6} sm={12}>
              <Box
                container
                flexDirection="column"
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: 3,
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "55px", xl: "71px" },
                    lineHeight: { xs: "55px", md: "70px", xl: "85px" },
                    letterSpacing: "0px",
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  Terugvordering van buitenlandse
                  <br />
                  btw & accijnzen
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: { xs: "20px", md: "25px", xl: "25px" },
                    lineHeight: { xs: "29px", md: "34px", xl: "34px" },
                    letterSpacing: "0px",
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <ul>
                    <li>30+ jaren ervaring</li>
                    <li>Flexibel voorfinancieren</li>
                    <li>Geautomatiseerd facturen aanleveren</li>
                    <li>Altijd kloppende boekhouding</li>
                    <li>Stabiele cashflow</li>
                    <li>Onafhankelijk</li>
                  </ul>
                </Typography>
                <CalculateButton maxWidth="290px" />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Container>
  );
};

export default BannerHome;
