import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle8 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              Dé factuurvereisten om een soepele buitenlandse BTW teruggaaf te
              bevorderen
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage8.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                26.04.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Als je als bedrijf buitenlandse BTW wilt terugvorderen, is het
              belangrijk om te weten aan welke factuurvereisten moet worden
              voldaan.{" "}
              <span style={{ color: "#2b5baa" }}>
                Hieronder vind je een overzicht van de belangrijkste
                factuurvereisten:
              </span>
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              1. Naam en adres van de leverancier of dienstverlener
              <br />
              2. BTW-identificatienummer van de leverancier of dienstverlener
              <br />
              3. BTW-identificatienummer van uw onderneming
              <br />
              4. Datum van uitreiking van de factuur
              <br />
              5. Factuurnummer
              <br />
              6. Datum waarop de levering of dienst heeft plaatsgevonden
              <br />
              7. Beschrijving van de geleverde goederen of diensten
              <br />
              8. Bedrag exclusief BTW en het toegepaste BTW-tarief
              <br />
              9. Bedrag aan BTW dat in rekening is gebracht
              <br />
              10. Totaalbedrag inclusief BTW
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#9c29ff" }}>
                Verder kunnen deze factuurvereisten verschillen per land
              </span>
              . Daarnaast is het van belang om de{" "}
              <span style={{ color: "#2b5baa" }}>
                facturen zorgvuldig te bewaren
              </span>
              , omdat de belastingdienst deze kan opvragen als bewijs voor de
              teruggevorderde BTW. Hier staat meestal een{" "}
              <span style={{ color: "#2b5baa" }}>termijn van 5 jaar</span> voor,
              maar is lidstaat afhankelijk.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Let op bij fiscale eenheid
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#9c29ff" }}>
                Een fiscale eenheid is een groep van samenwerkende ondernemingen{" "}
              </span>
              (veelal binnen 1 concern) die voor de omzetbelasting
              <span style={{ color: "#9c29ff" }}>
                {" "}
                als één belastingplichtige wordt beschouwd
              </span>
              , waardoor er maar één (binnenlandse) aangifte hoeft te worden
              gedaan. Erg makkelijk, maar dit
              <span style={{ color: "#2b5baa" }}>
                {" "}
                wordt niet altijd begrepen in het buitenland
              </span>
              . De buitenlandse belastingdiensten kennen eigenlijk alleen maar 1
              BTW identificatienummer bij 1 entiteit (rechtspersoon).
              <br />
              Let er dus a.u.b. op dat het juiste BTW-identificatienummer op de
              door u leverancier uitgereikte facturen staan en wel degene die
              bij de werkmaatschappij hoort. Wij raden aan afspraken hierover te
              maken met uw leveranciers, teneinde later hier geen problemen met
              de BTW teruggaaf mee te krijgen. Het vormen van een fiscale
              eenheid is dus in het kader van BTW teruggaaf echter niet altijd
              wenselijk of verdient in ieder geval uitleg bij de teruggaaf.
              Mocht u met deze problemen te doen hebben, wij van Delta kunnen u
              hierin begeleiden.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle8;
