import React from "react";
import NewsArticle53 from "../elements/NewsArticle53";
import NewsNav from "../elements/NewsNav";

const NewsDetails53 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle53 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails53;