import React from "react";
// import ResponsiveAppBar from "../ResponsiveAppBar";
import PriceCalculation from "./PriceCalculation";

const PricingCalculation = () => {
  return (
    <>
      <PriceCalculation />
    </>
  );
};

export default PricingCalculation;
