import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle10 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
              BTW op rekeningrijden?
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage10.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                11.05.23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              In Europa zijn verschillende landen bezig met het invoeren van 
              rekeningrijden, waaronder Nederland.{" "}
              <span style={{ color: "#9c29ff" }}>
              In Nederland ligt het streven naar de invoering in 2030
              </span>
              , maar er zijn recente berichten dat dit vervroegd gaat worden 
              met een aantal jaren. Dit vanwege een begrotingstekort door de 
              elektrificering van auto’s waardoor er minder inkomsten ontstaan 
              uit accijns over brandstoffen.{" "}
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              <span style={{ color: "#2b5baa" }}>
              In andere Europese landen is rekeningrijden al een ingevoerd 
              systeem. Zo heeft heel België al het OBU-systeem (On Board Unit)
              </span>
              , waarbij vrachtwagens met meer dan 3,5 ton een vergoeding 
              betalen over het gereden aantal kilometers.{" "}
              <span style={{ color: "#9c29ff" }}>
              Ook Zweden is een voorbeeld van een land dat rekeningrijden heeft 
              ingevoerd voor vrachtwagens die rond de steden Stockholm, Göteborg en Malmö 
              rijden, genaamd ‘Trängselskatt’
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het OBU-systeem is in opdracht van de Belgische overheid ontwikkeld 
            door de commerciële partij Satellic en wordt dus BTW in rekening 
            gebracht over het rekeningrijden. Trängselskatt is een opdracht van 
            de Zweedse overheid die de Zweedse Transport Administratie uitvoert. 
            De Zweedse Transport Administratie is een overheidsinstelling maar 
            vergis je niet, ook hierover wordt BTW geheven.{" "}
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het is belangrijk dat transportondernemingen zich bewust zijn van de 
            fiscale aspecten van rekeningrijden.{" "}
            <span style={{ color: "#2b5baa" }}>
            Kijkend naar het huidige Trängselskatt-systeem zie je dat ondanks dat 
            het door een overheidsinstelling uitgevoerd wordt, er BTW over geheven 
            wordt
              </span> 
              . In tegenstelling tot de meeste kosten voor tol en vignetten, die door een overheid 
              worden geïncasseerd, geen BTW bevatten.{" "}
            <span style={{ color: "#9c29ff" }}>
            Als transportonderneming heeft u recht op teruggaaf van deze buitenlandse 
            BTW
            </span>
            .
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Kortom, rekeningrijden wordt steeds vaker ingevoerd in Europa, met België 
            en Zweden als koplopers. Dat het rekeningrijden door een 
            overheidsinstelling gefaciliteerd wordt betekent niet dat het BTW 0% is 
            en heeft u als transportonderneming dus recht op teruggaaf van deze BTW.  
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle10;
