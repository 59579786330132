import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Image from "mui-image";
import React from "react";
import DPwerkwijze from "../../assets/DPwerkwijze.png";
import DHwerkwijze from "../../assets/DHwerkwijze.png";
import DEwerkwijze from "../../assets/DEwerkwijze.png";
import DDwerkwijze from "../../assets/DDwerkwijze.png";
import Rondje1 from "../../assets/Rondje 1.png";
import Rondje2 from "../../assets/Rondje 2.png";
import Rondje3 from "../../assets/Rondje 3.png";
import Rondje4 from "../../assets/Rondje 4.png";
import RondjeQuestionMark from "../../assets/RondjeQuestionMark.png";

const Werkwijze = (props) => {
  const DeltaPetrolList = [
    {
      icon: Rondje1,
      content: "Delta downloadt automatisch uw facturen",
    },
    {
      icon: Rondje2,
      content:
        "Delta doet verzoek tot teruggaaf bij de buitenlandse autoriteiten",
    },
    {
      icon: Rondje3,
      content: "De buitenlandse autoriteiten betalen uit aan Delta",
    },
    {
      icon: Rondje4,
      content: "Na controle betaalt Delta snel uit aan u",
    },
  ];
  const DeltaHybridList = [
    {
      icon: RondjeQuestionMark,
      content:
        "Uw oplopende krediet bij Delta kunt u elk moment uw kant op laten komen",
    },
    {
      icon: Rondje1,
      content: "Delta downloadt automatisch uw facturen",
    },
    {
      icon: Rondje2,
      content:
        "Delta doet verzoek tot teruggaaf bij de buitenlandse autoriteiten",
    },
    {
      icon: Rondje3,
      content: "De buitenlandse autoriteiten betalen uit aan Delta",
    },
    {
      icon: Rondje4,
      content: "Na controle betaalt Delta snel aan u uit",
    },
  ];

  const DeltaElectricList = [
    {
      icon: Rondje1,
      content: "Delta downloadt automatisch uw facturen",
    },
    {
      icon: Rondje2,
      content: "Na controle betaalt Delta snel uit aan u",
    },
  ];
  const DeltaDieselList = [
    {
      icon: Rondje1,
      content: "Delta downloadt automatisch uw facturen",
    },
    {
      icon: Rondje2,
      content: "Delta doet verzoek tot teruggaaf bij de buitenlandse douane",
    },
    {
      icon: Rondje3,
      content: "De buitenlandse douane betaalt uit aan Delta",
    },
    {
      icon: Rondje4,
      content: "Na controle betaalt Delta snel uit aan u",
    },
  ];

  // var itemList;
  return (
    <div>
      <Container maxWidth="xl" sx={{ mt: 5, mb: 8 }}>
        <Typography
          sx={{
            mt: 3,
            mb: 5,
            color: "#2B5BAA",
            textAlign: "center",
            fontSize: { xs: "40px", md: "55px", xl: "71px" },
            lineHeight: { xs: "55px", md: "70px", xl: "85px" },
            letterSpacing: "0px",
            fontFamily: "Century Gothic",
            fontWeight: "Bold",
          }}
        >
          Werkwijze
        </Typography>
        {props.deltaPetrolActive ? (
          <Image img src={DPwerkwijze} alt="cur" height="100%" width="100%" />
        ) : (
          ""
        )}
        {props.deltaHybridActive ? (
          <Image img src={DHwerkwijze} alt="cur" height="100%" width="100%" />
        ) : (
          ""
        )}
        {props.deltaElectricActive ? (
          <Image img src={DEwerkwijze} alt="cur" height="100%" width="100%" />
        ) : (
          ""
        )}
        {props.deltaDieselActive ? (
          <Image img src={DDwerkwijze} alt="cur" height="100%" width="100%" />
        ) : (
          ""
        )}

        <center>
          <List
            sx={{
              mt: 10,
              color: "#6b6a6a",
              fontSize: "40px",
              fontFamily: "HelveticaNeue-Light",
              fontWeight: "Light",
              maxWidth: "950px",
            }}
          >
            {props.deltaPetrolActive ? (
              <>
                {DeltaPetrolList.map((item) => (
                  <ListItem sx={{ p: 2, alignItems: "flex-start" }}>
                    <ListItemIcon>
                      <Image
                        img
                        src={item.icon}
                        alt="cur"
                        height={44}
                        width={46}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          // mt: 0,
                          ml: 2,
                          color: "#6b6a6a",
                          fontSize: { xs: "24px", md: "36px", xl: "40px" },
                          lineHeight: { xs: "22px", md: "34px", xl: "38px" },
                          letterSpacing: "0px",
                          fontFamily: "HelveticaNeue-Light",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}{" "}
              </>
            ) : (
              ""
            )}

            {props.deltaHybridActive ? (
              <>
                {DeltaHybridList.map((item) => (
                  <ListItem sx={{ p: 2, alignItems: "flex-start" }}>
                    <ListItemIcon>
                      <Image
                        img
                        src={item.icon}
                        alt="cur"
                        height={44}
                        width={45}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          // mt: 0,
                          ml: 2,
                          color: "#6b6a6a",
                          fontSize: { xs: "24px", md: "36px", xl: "40px" },
                          lineHeight: { xs: "22px", md: "34px", xl: "38px" },
                          letterSpacing: "0px",
                          fontFamily: "HelveticaNeue-Light",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}{" "}
              </>
            ) : (
              ""
            )}

            {props.deltaElectricActive ? (
              <>
                {DeltaElectricList.map((item) => (
                  <ListItem sx={{ p: 2, alignItems: "flex-start" }}>
                    <ListItemIcon>
                      <Image
                        img
                        src={item.icon}
                        alt="cur"
                        height={44}
                        width={45}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          // mt: 0,
                          ml: 2,
                          color: "#6b6a6a",
                          fontSize: { xs: "24px", md: "36px", xl: "40px" },
                          lineHeight: { xs: "22px", md: "34px", xl: "38px" },
                          letterSpacing: "0px",
                          fontFamily: "HelveticaNeue-Light",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}{" "}
              </>
            ) : (
              ""
            )}

            {props.deltaDieselActive ? (
              <>
                {DeltaDieselList.map((item) => (
                  <ListItem sx={{ p: 2, alignItems: "flex-start" }}>
                    <ListItemIcon>
                      <Image
                        img
                        src={item.icon}
                        alt="cur"
                        height={44}
                        width={45}
                      />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography
                        sx={{
                          // mt: 0,
                          ml: 2,
                          color: "#6b6a6a",
                          fontSize: { xs: "24px", md: "36px", xl: "40px" },
                          lineHeight: { xs: "22px", md: "34px", xl: "38px" },
                          letterSpacing: "0px",
                          fontFamily: "HelveticaNeue-Light",
                        }}
                      >
                        {item.content}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                ))}{" "}
              </>
            ) : (
              ""
            )}

            {/* <ListItem>
              <ListItemIcon>
                <Image img src={Rondje1} alt="cur" height={44} width={44} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    color: "#6b6a6a",
                    fontSize: "1.4rem",
                    "@media (min-width:600px)": {
                      fontSize: "2.4rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "light",
                    maxWidth: "855px",
                  }}
                >
                  Delta downloadt automatisch uw facturen
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Image img src={Rondje2} alt="cur" height={44} width={44} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    color: "#6b6a6a",
                    fontSize: "1.4rem",
                    "@media (min-width:600px)": {
                      fontSize: "2.4rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "Light",
                    maxWidth: "855px",
                  }}
                >
                  Delta doet verzoek tot teruggaaf bij de buitenlandse
                  autoriteiten
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Image img src={Rondje3} alt="cur" height={44} width={44} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    color: "#6b6a6a",
                    fontSize: "1.4rem",
                    "@media (min-width:600px)": {
                      fontSize: "2.4rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "Light",
                    maxWidth: "855px",
                  }}
                >
                  De buitenlandse autoriteiten betalen uit aan Delta
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Image img src={Rondje4} alt="cur" height={44} width={44} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    verticalAlign: "top",
                    color: "#6b6a6a",
                    fontSize: "1.4rem",
                    "@media (min-width:600px)": {
                      fontSize: "2.4rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                    fontWeight: "Light",
                    maxWidth: "855px",
                  }}
                >
                  Delta betaalt uit aan u
                </Typography>
              </ListItemText>
            </ListItem> */}
          </List>
        </center>
      </Container>
    </div>
  );
};

export default Werkwijze;
