import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle41 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Tariefstijging van tol in Frankrijk 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage41.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                01-02-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Franse tolwegexploitanten zullen naar verwachting op{" "}
            <span style={{color: "#2b5baa"}}>
            1 februari 2024{" "}
            </span>
            de prijzen verhogen. Voormalig minister van Transport, Clément Beaune, beloofde vorig jaar dat de 'péage' (tol) verhogingen in 2024{" "}
            <span style={{color: "#9c29ff"}}>
            "minder dan 3%"
            </span>
            . De nieuwe tarieven zullen worden gebaseerd op de volgende vier componenten:{" "}
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De werkelijke stijgingen zijn echter{" "}
            <span style={{ color: "#9c29ff" }}>
            afhankelijk van de route{" "}
            </span>
            en daarmee de exploitant, aangezien die individueel de verhoging hebben vastgesteld. Dat betekent dat de werkelijke 
            stijging zal variëren, afhankelijk van de routes, waarbij sommigen de tarieven met meer dan 3% laten stijgen.             
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <span style={{color: "#2b5baa"}}>
             De verwachte stijgingen{" "}
            </span> van de grote snelwegexploitant zijn als volgt:  {" "}
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li>
                        Vinci: 
                        </li>
                    </td>
                    <td>
                    <span style={{color: "#2b5baa"}}>2,70%</span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        Sanef:
                        </li>
                    </td>
                    <td>
                    <span style={{color: "#2b5baa"}}>2,79%</span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        SAPN: 
                        </li>
                    </td>
                    <td>
                    <span style={{color: "#2b5baa"}}>3,08%</span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        APPR: 
                        </li>
                    </td>
                    <td>
                    <span style={{color: "#2b5baa"}}>3,02%</span>
                    </td>
                 </tr>
                 <tr>
                    <td>
                        <li>
                        Area: 
                        </li>
                    </td>
                    <td>
                    <span style={{color: "#2b5baa"}}>3,04%</span>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De stijging van de tol van het{" "}
            <span style={{ color: "#2b5baa" }}>
            Millau-viaduct{" "}
            </span>
            ligt een stuk hoger dan de rest. Hiervoor zal een verhoging van{" "}
            <span style={{ color: "#2b5baa" }}>
            5,50% tot 5,83%{" "}
            </span>
            van kracht worden.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De stijging van tolkosten zal leiden tot een{" "}
            <span style={{ color: "#9c29ff" }}>
            hogere totale uitgave{" "}
            </span>
            voor bedrijven die actief zijn in Frankrijk, vooral voor diegenen die regelmatig gebruik maken 
            van tolwegen voor transportdoeleinden. Deze toenemende kosten zullen ook resulteren in een{" "}
            <span style={{ color: "#9c29ff" }}>
            hoger bedrag aan betaalde BTW{" "}
            </span>
            op deze uitgaven. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
              Bent u er niet van op de hoogte{" "}
              <span style={{ color: "#2b5baa" }}>
            welke exploitant welke tolweg{" "}
            </span>
            beheert? Zie de afbeelding hieronder.
            </Typography>
            <Typography>
              
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImageCarte_ASFA.png")}
                alt="news image"
                maxWidth="1119px"
               
              />
            </div>
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle41;
