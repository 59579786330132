import React from "react";
import NewsArticle12 from "../elements/NewsArticle12";
import NewsNav from "../elements/NewsNav";

const NewsDetails12 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle12 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails12;