import React from "react";
import NewsArticle9 from "../elements/NewsArticle9";
import NewsNav from "../elements/NewsNav";

const NewsDetails9 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle9 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails9;
