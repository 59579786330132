import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// import "./fonts/HelveticaNeue Light.ttf";
// import "./fonts/HelveticaNeue Thin.ttf";
// import CssBaseline from "@mui/material/CssBaseline";

// styles
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";

const theme = createTheme({
  palette: {
    primary: { main: "#2B5BAA" }, // Purple and green play nicely together.
    secondary: { main: "#FFF" }, // This is just green.A700 as hex.
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
