import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle15 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Risico's van foutief ingevulde BTW-teruggaaf verzoeken  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage15.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                22-06-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            Onbedoelde foutieve teruggaaf verzoeken ten behoeve van bijvoorbeeld BTW op brandstof en tol, kunnen {" "}
            <span style={{ color: "#2b5baa" }}>
            ernstige gevolgen{" "}
              </span>
            hebben. Fouten kunnen resulteren in
            {" "}
            <span style={{ color: "#9c29ff" }}>
            verhoogde en frequentere toezicht  en controles door belastingdiensten, 
            weigering tot terugbetalen van de correct ingediende bedragen 
            (ook voor toekomstige), extra financiële verplichtingen, reputatieschade 
            en zelfs boetes.{" "}
              </span>
            </Typography>

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Belastingautoriteiten zijn tegenwoordig zeer waakzaam als het gaat
             om onjuiste aangiftes en fraude. Wanneer er {" "}
            <span style={{ color: "#2b5baa" }}>
            onnauwkeurige informatie{" "}
            </span>
            wordt verstrekt, kunnen boetes worden opgelegd en in ernstige gevallen zelfs
             strafrechtelijke vervolging plaatsvinden. Dit kan leiden tot {" "}
            <span style={{ color: "#9c29ff" }}>
            opgelegde financiële sancties en juridische problemen{" "}
            </span>
            voor bedrijven.
            </Typography>
           

            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Een ander risico is de terugbetaling van {" "}
            <span style={{ color: "#2b5baa" }}>
            de ten onrechte ontvangen BTW
              </span>
              . Zelfs kan de buitenlandse belastingdienst middels inschakeling van de
               lokale belastingdienst reeds uitgekeerde bedragen op eerdere aangiftes terug gaan 
               vorderen, met alle consequenties van dien. Dit kan aanzienlijke{" "}
            <span style={{ color: "#9c29ff" }}>
            financiële consequenties{" "}
              </span>
             hebben, waarmee bedrijven financieel in de knel kunnen komen.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voorts kunnen foutieve aangiftes leiden tot {" "}
            <span style={{ color: "#2b5baa" }}>
            reputatieschade
              </span>
            . Het{" "}
            <span style={{ color: "#2b5baa" }}>
            vertrouwen van de belastingautoriteiten {" "}
              </span>
              kan worden geschaad, wat op de lange termijn negatieve gevolgen kan hebben voor de bedrijfsactiviteiten. {" "}

              </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >Tot slot kunnen bedrijven die foutieve aangiftes indienen te maken krijgen met {" "}
            <span style={{ color: "#2b5baa" }}>
            verhoogd toezicht en frequentere controles
              </span>
. Dit brengt extra administratieve lasten met zich mee en kan de normale bedrijfsvoering aardig verstoren.
 Daar zitten we allemaal niet op te wachten!

</Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >Een typefout is snel gemaakt, zoals een {" "}
            <span style={{ color: "#2b5baa" }}>
            decimaal of komma{" "}
            </span>
            op de verkeerde plaats. Dit kan resulteren in een te hoge of te lage BTW-teruggaaf. 
            Bij constatering hiervan door de belastingdienst zal het laatste getolereerd worden, 
            terwijl het eerste (te hoge indiening) afgestraft kan worden. Men kan immers moeilijk
             tot (kwade) opzet concluderen, maar de belastingdienst gaat er gemakshalve wel van uit. 

             </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
                Kortom, het is belangrijk om op te merken dat onopzettelijke fouten in de BTW-teruggaaf verzoeken, 
                hoewel ze niet opzettelijk zijn begaan, nog steeds gevolgen kunnen hebben. Daarom is het van 
            essentieel belang om zorgvuldig en nauwkeurig te zijn bij het indienen van belastingaangiften en, 
            indien nodig, professioneel advies in te winnen om fouten te voorkomen. 

            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle15;
