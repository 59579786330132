import React from "react";
import NewsArticle55 from "../elements/NewsArticle55";
import NewsNav from "../elements/NewsNav";

const NewsDetails55 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle55 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails55;