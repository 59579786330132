import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle21 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Regelingen, aftrekposten, afschrijvingen en subsidies bij 'groene transitie'  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage21.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                10-08-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >  
            De overgang naar een {" "}
            <span style={{ color: "#2b5baa" }}>
            CO2-neutrale samenleving{" "}
              </span>
            is onvermijdelijk. Hoewel de haalbaarheid van de Europese doelen ter discussie staat, is het duidelijk 
            dat de transportsector vroeg of laat ook aan verandering onderhevig zal zijn. Dit heeft geleid tot een overvloed aan
            {" "}
            <span style={{ color: "#9c29ff" }}>
            nieuwe wetten, regelgevingen en belastingen
              </span>
            , wat vaak resulteert in{" "}
            <span style={{ color: "#9c29ff" }}>
            extra administratief werk en financiële lasten
            </span>
            . Dit alles is bedoeld om de transitie naar duurzaamheid te bevorderen. Als u in de toekomst overweegt om te 
            investeren in verduurzaming, is het van cruciaal belang om op de hoogte te zijn van alle actuele regelingen waar 
            u financieel voordeel uit kunt halen. We noemen er een paar:
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>Energie-investeringsaftrek (EIA)</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Energie-investeringsaftrek (EIA) is een fiscale regeling die ondernemers direct financieel voordeel 
            biedt wanneer zij investeren in energiebesparend bedrijfsmiddelen en duurzame energie. Naast de normale 
            afschrijving op de investering, kunnen ondernemers een {" "}
            <span style={{ color: "#2b5baa" }}>
            extra bedrag aftrekken van hun belastbare winst
              </span>
            . Dit extra bedrag komt overeen met
            {" "}
            <span style={{ color: "#9c29ff" }}>
            45,5% van het investeringsbedrag {" "}
              </span>
            dat in aanmerking komt, niet misselijk dus! Om gebruik te maken van deze fiscale aftrekregeling, moet de investering voldoen 
            aan drie voorwaarden:
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li> Het bedrag aan energie-investeringen is minimaal €2.500 per bedrijfsmiddel </li>
                        <li> Het bedrijfsmiddel is niet eerder gebruikt  </li>
                        <li> Het bedrijfsmiddel staat op de energielijst van het RVO  </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <span style={{color: "#2b5baa"}}>Kleinschaligheidsinvesteringsaftrek (KIA)</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Kleinschaligheidsinvesteringsaftrek (KIA) is een{" "}
            <span style={{ color: "#2b5baa" }}>
            aftrekpost op uw winst
              </span>
            . Om in aanmerking te komen moet er recht bestaan op investeringsaftrek. Vrachtauto's 
            vallen onder andere onder de investeringsaftrek. Verder moet de investering minstens €2.601 zijn. 
            {" "}
            <span style={{ color: "#9c29ff" }}>
            Het bedrag{" "}
              </span>
            dat u als aftrekpost van uw winst mag halen{" "}
            <span style={{ color: "#9c29ff" }}>
            verschilt per hoogte van de investering en verandert jaarlijks
              </span>
            . De tabel van de Kleinschaligheidsinvesteringsaftrek (KIA) van 2023 ziet er als volgt uit:  
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                <tr>
                    <td>
                        <li> €2.601 t/m €63.716 </li>
                    </td>
                    <td>
                         28% van het investeringsbedrag
                    </td>
                </tr>
                <tr>
                    <td>
                        <li> €63.716 t/m €117.991  </li>
                    </td>
                    <td>
                         €17.841 
                    </td>
                </tr>
                <tr>
                    <td>
                        <li> €117.992 t/m €353.973  </li>
                    </td>
                    <td>
                         €17.841 min 7,56% van het investeringsbedrag boven €117.991  
                    </td>
                </tr>
                <tr>
                    <td>
                        <li> Boven de €353.973  </li>
                    </td>
                    <td>
                        0% 
                    </td>
                </tr>
                    </ul>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
           De Energie-investeringsaftrek (EIA) en Kleinschaligheidsinvesteringsaftrek (KIA) vallen 
           samen te combineren. Om niet achter het net te vissen dient u{" "}
           <span style={{color: "#2b5baa"}}>
           binnen 3 maanden na de investering, de investering te melden bij het RVO. 
           </span>
           </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{color: "#2b5baa"}}>Milieu-investeringsaftrek (MIA) & Willekeurige afschrijving milieu-investeringen (VAMIL)</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De Milieu-investeringsaftrek (MIA) biedt u de mogelijkheid om uw{" "}
            <span style={{ color: "#9c29ff" }}>
            fiscale winst te verlagen {" "}
              </span>
            door {" "}
            <span style={{ color: "#2b5baa" }}>
            tot 45% van het investeringsbedrag in mindering te brengen op de winst
              </span>
            . Het exacte aftrekpercentage hangt af van de milieu-effecten en de gangbaarheid van het bedrijfsmiddel.
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Met de Willekeurige afschrijving milieu-investeringen (VAMIL) kunt u een {" "}
            <span style={{ color: "#9c29ff" }}>
            investering op elk gewenst moment afschrijven
              </span>
            . De willekeurige afschrijving is{" "}
            <span style={{ color: "#2b5baa" }}>
            beperkt tot 75% van het investeringsbedrag
              </span>
            . Door versneld af te schrijven, vermindert u de fiscale winst en betaalt u minder belasting in dat jaar. Dit levert u een{" "}
              <span style={{ color: "#9c29ff" }}>
            liquiditeitsvoordeel{" "}
              </span>
            op.
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Om in aanmerking te komen met de MIA/VAMIL dient uw investering te voldoen aan de volgende 4 voorwaarden:  
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ul>
                 <tr>
                    <td>
                        <li> Het bedrijfsmiddel staat op de milieulijst van het RVO (deze verandert elk jaar) </li>
                        <li> Het bedrijfsmiddel is niet eerder gebruikt   </li>
                        <li> De investering moet betrekking hebben op aanschaf- en voortbrengingskosten van het bedrijfsmiddel </li>
                        <li> Het bedrag aan milieu-investeringen is minimaal €2.500 per bedrijfsmiddel  </li>
                    </td>
                 </tr>
                    </ul>
             </tbody>
            </table>
              </Typography>
            <Typography
              sx={{
                mt: 0,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De MIA en VAMIL vallen te combineren met elkaar en vallen te combineren met de KIA. MIA en VAMIL vallen 
            echter niet te combineren met de EIA. U dient dus te kiezen tussen de combinatie van{" "}
            <span style={{ color: "#2b5baa" }}>
            EIA en KIA{" "}
              </span>
            of {" "}
            <span style={{ color: "#9c29ff" }}>
            MIA, VAMIL en KIA
              </span>
            . Voor MIA en VAMIL geldt ook dat u{" "}
              <span style={{ color: "#2b5baa" }}>
            binnen 3 maanden na de investering, de investering meldt bij het RVO.
              </span>
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In het artikel van volgende week praten wij u bij over de Aanschafsubsidie Zero-Emissie Trucks (AanZET) regeling.
            Met deze regeling kunt u tot wel €131.900 per aanschaf van voertuig besparen.
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle21;
