import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle37 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Euro 7: Akkoord over strengere regels rondom uitstoot 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage37.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                21-12-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het Parlement en de Raad heeft een{" "}
            <span style={{color: "#2b5baa"}}>
            voorlopig akkoord{" "}
            </span>
            bereikt over nieuwe regels om de emissies van personenauto's, bestelwagens, bussen, vrachtwagens en aanhangwagens terug te dringen. 
            De verordening voor de typegoedkeuring en het markttoezicht op motorvoertuigen (
            <span style={{color: "#2b5baa"}}>
            Euro 7
            </span>
            ) heeft tot doel de transitie naar{" "}
            <span style={{color: "#9c29ff"}}>
            schone mobiliteit{" "}
            </span>
            te ondersteunen en de prijzen van particuliere- en commerciële voertuigen betaalbaar te houden voor burgers en bedrijven. Voertuigen zullen {" "}
            <span style={{color: "#9c29ff"}}>
            langer aan de nieuwe normen moeten voldoen
            </span>
            , zodat ze gedurende hun hele levensduur schoner blijven. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>Emissielimieten</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De emissielimieten blijven onveranderd voor personenauto's en bestelwagens, zoals vastgesteld in Euro 6. Echter, voor vrachtwagens en bussen worden  {" "}
            <span style={{ color: "#9c29ff" }}>
            strengere normen geïntroduceerd
            </span>
            . De bestaande {" "}
            <span style={{ color: "#2b5baa" }}>
            limiet voor stikstofoxiden{" "}
            </span>
            bij testen wordt verlaagd van 460 milligram per kilowattuur naar 200 milligram per kilowattuur. In de praktijk is een {" "}
            <span style={{ color: "#2b5baa" }}>
            tolerantie van 260 milligram toegestaan. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>Bandenslijtage</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Bovendien worden er{" "}
            <span style={{ color: "#9c29ff" }}>
            beperkingen opgesteld{" "}
            </span>
            en opgelegd met betrekking tot de slijtage van remmen en banden. Voor elektrische voertuigen 
            in het zware transport geldt een limiet van 5 milligram per kilometer, terwijl voor dieselvoertuigen deze limiet is vastgesteld op{" "}
            <span style={{ color: "#9c29ff" }}>
            11 miligram per kilometer.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>Verlengde periode van handhaving</span>
            </Typography>
            <Typography
              sx={{
                mt: 2,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voertuigen dienen in de toekomst gedurende een langere periode aan de gestelde normen te blijven voldoen. Onder Euro 6 (voor personenauto's) en{" "}
            <span style={{ color: "#2b5baa" }}>
            Euro 6{" "}
            </span>
            (voor trucks en bussen) geldt een minimale{" "}
            <span style={{ color: "#2b5baa" }}>
            norm van 100.000 kilometer
            </span>
            . Voor{" "}
            <span style={{ color: "#9c29ff" }}>
            Euro 7{" "}
            </span>
            worden de normen gehandhaafd tot{" "}
            <span style={{ color: "#9c29ff" }}>
            200.000 voertuigkilometers
            </span>
            . Specifieke details met betrekking tot de Euro 7-norm zullen in latere wetgeving worden vastgesteld. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Het voorlopige akkoord dat met het Europees Parlement is bereikt, moet nu door beide instellingen worden bekrachtigd en formeel aangenomen. Naar verwachting worden de{" "}
            <span style={{ color: "#2b5baa" }}>
            nieuwe normen halverwege 2028 van kracht voor nieuwe voertuigen. 
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 10,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Er wordt nogal wat gevraagd van de transportsector als het gaat om de implementatie van de normen. Toch is het belangrijk 
            voor de transportbranche om proactief te zijn met de implementatie van de nieuwe normen en flexibel te zijn in het aanpassen 
            van de bedrijfsstrategie aan de veranderende regelgeving en technologische ontwikkelingen. Onttrekking aan de normen is geen 
            optie. Door tijdig voorbereidingen te treffen, kan de overgang naar de Euro 7-normen versoepeld worden en mogelijke verstoringen 
            in de activiteiten beperken. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle37;
