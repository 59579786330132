import { Box, Typography, Grid } from "@mui/material";
import React from "react";
import DeltaGreen from "../../assets/DeltaGreen.png";
import { Container } from "@mui/system";
import Image from "mui-image";

const ThirtyPlusYearsOfExperience = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={{ m: 0, p: 0, backgroundColor: "#FAFCFF" }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  display: "flex",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Image
                  sx={{ mt: 15 }}
                  style={{
                    alignSelf: "center",
                    ObjectFit: "cover",
                    maxWidth: "650px",
                  }}
                  img
                  src={DeltaGreen}
                  alt="cur"
                  // height={352}
                  // width={500}
                />
              </Box>
            </Grid>
            <Grid item lg={6} md={12}>
              <Box
                sx={{
                  position: "relative",
                  p: { xs: 3, md: 6 },
                  pr: { md: 0 },
                }}
              >
                <Typography
                  sx={{
                    mt: { xs: 5, md: 8 },
                    color: "#2B5BAA",
                    textAlign: "left",
                    fontSize: { xs: "40px", md: "71px" },
                    fontFamily: "Century Gothic",
                    fontWeight: "Bold",
                  }}
                >
                  30+ jaren ervaring
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  <span style={{ color: "#9c29ff" }}>
                    Delta is de hernieuwde visie{" "}
                  </span>
                  op een eerder enorm succesvolle onderneming in de buitenlandse
                  btw- en accijns terugvorder branche.
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  Gedurende de afgelopen 30+ jaren evolueerde de visie om de
                  meest <span style={{ color: "#2b5baa" }}>moderne- </span>,
                  <span style={{ color: "#2b5baa" }}> flexibele- </span> en
                  <span style={{ color: "#2b5baa" }}> transparante </span>
                  terugvorder agent te worden in de transportsector.
                </Typography>
                <Typography
                  sx={{
                    mt: 3,
                    color: "#6b6a6a",
                    textAlign: "left",
                    fontSize: "1.8rem",
                    lineHeight: "2.4rem",
                    "@media (min-width:600px)": {
                      mt: 5,
                      fontSize: "2.2rem",
                      lineHeight: "2.8rem",
                    },
                    fontFamily: "HelveticaNeue-Light",
                  }}
                >
                  De uitwerking hiervan?
                  <br />
                  Delta Refund Solutions
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default ThirtyPlusYearsOfExperience;
