import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle23 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Belastingfraude zorgt voor meer complexiteit in het BTW- & accijns-teruggaafproces 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage23.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                31-08-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            > 
            <span style={{color: "#2b5baa"}}>
            BTW- & accijnsfraude{" "}
            </span>
            is een alarmerende realiteit geworden voor Europese overheden. Fraudeurs gebruiken{" "} 
            <span style={{color: "#9c29ff"}}>
            complexe methoden{" "}
            </span>
            om onterechte BTW & accijns terug te vragen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De groeiende BTW- & accijnsfraude legt extra druk op belastingautoriteiten, transportondernemers en intermediairs. Naast dat dit het BTW- & accijns{" "}
            <span style={{ color: "#2b5baa" }}>
            teruggaafproces vertraagt
              </span>
            , maakt dit het ook{" "}
            <span style={{ color: "#2b5baa" }}>
            complexer en gevoeliger
              </span>
            . Als reactie hierop hebben belastingautoriteiten de documentatievereisten aangescherpt en moeten transportbedrijven meer bewijsmateriaal en 
            transactiedetails verstrekken om BTW- & accijnsteruggaaf te rechtvaardigen. Dit heeft geleid tot{" "}
            <span style={{color: "#9c29ff"}}>
            intensievere gegevensvalidatie{" "}
            </span>
            en{" "}
            <span style={{color: "#9c29ff"}}>
            uitgebreidere audits van terugvorderingsverzoeken
            </span>
            , wat kan leiden tot{" "}
            <span style={{color: "#9c29ff"}}>
            diepgaandere financiële controles{" "}
            </span>
            en meer communicatie tussen transportbedrijven en overheidsinstanties. Kortom, de eerlijke transporteur is de dupe van de kleine groep fraudeurs, 
            soms resulterend in meer werk en een hoger risico op afwijzing van teruggaafverzoeken vanwege menselijke fouten. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>Hoe vermijd je verdenking van belastingfraude en bevorder je zo een soepele BTW- en/of accijns-teruggaaf:</span>
            <table cellpadding="10" cellspacing="0">
                <tbody>
                    <ol>
                 <tr>
                    <td>
                        <li style={{color: "#2b5baa"}}>  Boekhouding en Nauwkeurige documentatie 
                        <br></br>
                        <span style={{ color: "#6b6a6a" }}> Het bewaren van alle relevante documenten, zoals facturen, ontvangstbewijzen en vergunningen</span> 
                        </li>
                        <br></br>
                        <li style={{color: "#2b5baa"}}>  Technologie en Automatisering    
                        <br></br>
                        <span style={{ color: "#6b6a6a" }}> Implementeren van moderne technologieën en automatisering kan helpen bij het stroomlijnen van financiële processen en het minimaliseren van typfouten die snel gemaakt kunnen worden. Een oplossing hiervoor kan een systeem zijn die automatisch gegevens invoert </span> 
                        </li>
                        <br></br>
                        <li style={{color: "#2b5baa"}}>  Compliance met Belastingwetten
                        <br></br>
                        <span style={{ color: "#6b6a6a" }}> Op de hoogte blijven van de alsmaar veranderende specifieke BTW- en accijnsregels van elk land en je houden aan deze voorschriften </span> 
                        </li>
                    </td>
                 </tr>
                    </ol>
             </tbody>
            </table>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In de dynamische transportsector is er een {" "}
            <span style={{ color: "#9c29ff" }}>
            hoge maat van controle{" "}
              </span>
            ontstaan op het BTW- & accijns-teruggaafproces. Groeiende complexiteiten in internationale belastingregels zorgen voor vele{" "}
            <span style={{ color: "#2b5baa" }}>
            nalevingsrisico's
              </span>
            . Dit artikel belicht de noodzaak van{" "}
            <span style={{ color: "#2b5baa" }}>
            documentatie, controles, structuur en bewustzijn van actuele regelgevingen
              </span>
            . BTW- & accijnsfraude beïnvloeden het BTW- & accijns-teruggaafproces, vertragen validatie en vergroten de complexiteit voor zowel overheden als transportondernemers en intermediairs. Om verdenking van belastingfraude te 
            vermijden is een nauwkeurige boekhouding, naleving van belastingwetten en technologische automatisering essentieel.  
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle23;
