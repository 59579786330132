import React from "react";
import NewsArticle2 from "../elements/NewsArticle2";
import NewsNav from "../elements/NewsNav";

const NewsDetails2 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle2 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails2;
