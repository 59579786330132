import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle50 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            EU rolt CO2-tax op tol verder uit 
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage50.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                08-05-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Eind vorig jaar implementeerde{" "}
            <span style={{color: "#2b5baa"}}>
            Duitsland{" "}
            </span>
            CO2-heffing op tol voor vrachtwagens, wat veel aandacht trok in de logistieke sector vanwege de aanzienlijke kostenstijgingen die het met zich meebracht. Echter, een aantal {" "}
            <span style={{color: "#9c29ff"}}>
            andere Europese landen{" "}
            </span>
            hebben sindsdien soortgelijke tolheffingen ingevoerd in overeenstemming met de EU-wetgeving. 
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Naast{" "}
            <span style={{ color: "#2b5baa" }}>
            Duitsland{" "}
            </span>
            zijn er momenteel wegentolsystemen met het CO2-heffings component van kracht in respectievelijk {" "}       
            <span style={{ color: "#2b5baa" }}>
            Oostenrijk, Tsjechië en Hongarije.{" "}
            </span>    
            <span style={{ color: "#9c29ff" }}>
            Zweden{" "}
            </span>    
            heeft zojuist zijn eigen systeem gelanceerd, met{" "}
            <span style={{ color: "#9c29ff" }}>
            Denemarken{" "}
            </span>
            dat zich in{" "}
            <span style={{ color: "#9c29ff" }}>
            januari 2025{" "}
            </span>
            zal aansluiten.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De onderstaande tarieven gelden voor <span style={{ color: "#9c29ff" }}>
            Euro 6 Klasse 1:{" "}
            </span>
                </Typography>
            <Typography>
              
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage50_Map.png")}
                alt="news image"
                maxWidth="1119px"
               
              />
            </div>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Vooruitkijkend overwegen{" "}
            <span style={{ color: "#2b5baa" }}>
            Nederland{" "}
            </span>
            en{" "}
            <span style={{ color: "#2b5baa" }}>
            Roemenië{" "}
            </span>
            een C02-heffing over tol in{" "}
            <span style={{ color: "#2b5baa" }}>
            2026{" "}
            </span>
            in te voeren. Sommige snelwegen in{" "}
            <span style={{ color: "#9c29ff"}}>
            Frankrijk
            </span>
            , zoals de A35 in de Elzas, zouden al in 2025 mogelijk een CO2-tolcomponent kunnen hebben. Maar voor Frankrijk zal de 
            CO2-tolheffing op grotere schaal worden ingezet, wanneer concessies worden heronderhandeld, waarvan de eerste in {" "}
            <span style={{ color: "#9c29ff"}}>
            2032 {" "}
            </span>
            zal plaatsvinden. Tot slot heeft {" "}
            <span style={{ color: "#2b5baa" }}>
            Bulgarije{" "}
            </span>
            aangegeven te werken aan een vorm van CO2-tolheffing, maar{" "}
            <span style={{ color: "#2b5baa" }}>
            zonder duidelijk tijdsbestek.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Wat betreft de mogelijke invoering van CO2-gebaseerde tolheffingen in enkele andere Europese landen, moet{" "}
            <span style={{ color: "#9c29ff"}}>
            Italië {" "}
            </span>
            zijn methode voor het berekenen van tolheffingen op wegen aanpassen om de toepassing van de CO2-heffing te kunnen implementeren. Hoewel{" "}
            <span style={{ color: "#2b5baa" }}>
            Polen{" "}
            </span>
            een digitaal afstandstolberekeningssysteem heeft, is er geen duidelijke informatie over wanneer CO2-tolheffingen in het land kunnen worden geïmplementeerd.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
                De invoering van CO2-gebaseerde tolheffingen in verschillende Europese landen wijst op een{" "}
                <span style={{ color: "#9c29ff"}}>
            groeiende trend {" "}
            </span>
            binnen de transportsector om milieukosten mee te nemen in het tolbeleid. Transportonderneming moeten zich voorbereiden op mogelijke kostenstijgingen en operationele aanpassingen. Het{" "}
            <span style={{ color: "#2b5baa"}}>
            optimaliseren van routes{" "}
            </span>
            is hierbij van groot belang. Daarnaast is het essentieel voor Nederlandse transportbedrijven om op de hoogte te blijven van de wetgeving en ontwikkelingen in de landen waar ze actief zijn, 
            zodat ze tijdig kunnen reageren op veranderingen en mogelijke boetes kunnen voorkomen. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle50;
