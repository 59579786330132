import * as React from "react";
// import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
// import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { red } from "@mui/material/colors";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const NewsCard = (props) => {
  const newsItems = props.newsItems;
  const selectedCategory = props.selectedCategory;
  const [filteredNewsItems, setFilteredNewsItems] = React.useState(newsItems);

  const filterByCategory = (category) => {
    setFilteredNewsItems(
      newsItems.filter((item) => {
        if (selectedCategory === "Alles weergeven") {
          return true;
        }
        return item.category === selectedCategory;
      })
    );
  };
  React.useEffect(() => {
    filterByCategory();
  });
  return (
    <>
      {filteredNewsItems.map((newsItem) => (
        <Grid
          item
          md={12}
          lg={6}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Card
            sx={{
              // display: "flex",
              // direction: "column",
              // justifyContent: "space-between",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              maxWidth: "672px",
              maxHeight: "717px",
              borderRadius: 0,
            }}
          >
            {/* <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                R
              </Avatar>
            }
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title="Shrimp and Chorizo Paella"
            subheader="September 14, 2016"
          /> */}
            <div style={{ position: "relative" }}>
              <Link
                sx={{
                  color: "#2b5baa",
                  textAlign: "left",
                  fontSize: { md: "1.1rem", lg: "1.4rem" },
                  // lineHeight: { md: "2.4rem", lg: "2.8rem" },
                  fontFamily: "HelveticaNeue-Light",
                }}
                to={newsItem.navTo}
              >
                <CardMedia
                  // style={{ height: 100 }}
                  // height="375"
                  component="img"
                  src={newsItem.imageUrl}
                  alt="news image"
                />
              </Link>
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "white",
                  top: 0,
                  left: 0,
                  height: "50px",
                  backgroundColor: "#2b5baa",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "Century Gothic",
                  fontWeight: "bold",
                }}
              >
                {newsItem.category}
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "21px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.27px",
                }}
              >
                {newsItem.publishingDate}
              </Box>
            </div>
            <CardContent>
              <Link
                style={{ textDecoration: "none" }}
                sx={{
                  color: "#2b5baa",
                  textAlign: "left",
                  fontSize: { md: "1.1rem", lg: "1.4rem" },
                  // lineHeight: { md: "2.4rem", lg: "2.8rem" },
                  fontFamily: "HelveticaNeue-Light",
                }}
                to={newsItem.navTo}
              >
                <Typography
                  sx={{
                    mt: { md: 0, lg: 0 },
                    color: "#2b5baa",
                    textAlign: "left",
                    fontSize: { xs: "22px", lg: "25px" },
                    lineHeight: { md: "25px", lg: "30px" },
                    fontFamily: "Century Gothic",
                    fontWeight: "bold",
                    minHeight: 60,
                  }}
                >
                  {newsItem.headerText}
                </Typography>
              </Link>
              <Typography
                sx={{
                  mt: { md: 3, lg: 3 },
                  color: "#6b6a6a",
                  textAlign: "left",
                  fontSize: { md: "1.1rem", lg: "1.4rem" },
                  // lineHeight: { md: "2.4rem", lg: "2.8rem" },
                  fontFamily: "HelveticaNeue-Light",
                  // minHeight: { sx: 0, sm: 170, md: 170, lg: 120 },
                }}
              >
                {newsItem.bodyText}
              </Typography>
            </CardContent>
            <CardActions
              disableSpacing
              sx={{ mt: "auto", ml: "10px", mb: "10px" }}
            >
              <Link
                sx={{
                  color: "#2b5baa",
                  textAlign: "left",
                  fontSize: { md: "1.1rem", lg: "1.4rem" },
                  // lineHeight: { md: "2.4rem", lg: "2.8rem" },
                  fontFamily: "HelveticaNeue-Light",
                }}
                to={newsItem.navTo}
              >
                Volledig artikel
              </Link>
              {/* <IconButton aria-label="share" sx={{ marginLeft: "auto" }}>
                <ShareIcon />
              </IconButton> */}
            </CardActions>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default NewsCard;
