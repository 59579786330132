import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle31 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Een Sector in Transitie: Het verdwijnen van de middelgrote vervoerders   
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage31.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                02-11-23
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            In het voorbije decennium is er een aanzienlijk aantal {" "} 
            <span style={{color: "#2b5baa"}}>
            faillissementen en overnames{" "}
            </span>
            in de transportsector waargenomen, waarbij de grote spelers hun kleinere (middelgrote) concurrenten hebben overgenomen. De verdwijning van{" "}
            <span style={{color: "#2b5baa"}}>
            middelgrote internationale vervoerders{" "}
            </span>
            kan worden verklaard door diverse factoren. Een belangrijke factor is de relatief{" "}
            <span style={{color: "#9c29ff"}}>
            beperkte liquide middelen
            </span>
            , wat hun vermogen om de benodigde investeringen bij te houden, bemoeilijkt. Een andere uitdaging is de groeiende{" "}
            <span style={{color: "#9c29ff"}}>
            prijsdruk in de transportsector
            </span>
            . Klanten eisen concurrerende tarieven en zijn bereid om te winkelen bij verschillende aanbieders, wat druk uitoefent op de winstmarges van middelgrote vervoerders. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>
            Kleine internationale vervoerders{" "}
            </span>
            hebben verschillende strategieën ontwikkeld om te overleven in deze uitdagende omgeving. Veel van hen zijn{" "}
            <span style={{ color: "#9c29ff" }}>
            familiebedrijven met financiële reserves{" "}
            </span>
            die ze kunnen aanspreken in tijden van crisis. Bovendien hebben sommige van deze vervoerders zich{" "}
            <span style={{ color: "#9c29ff" }}>
            gespecialiseerd in nichemarkten
            </span>
            , waarin ze weinig concurrentie ondervinden. Dit stelt hen in staat om hogere tarieven te hanteren en trouwe klantenkringen op te bouwen. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            <span style={{ color: "#2b5baa" }}>
            Grote internationale vervoerders {" "}
            </span>
            staan voor andere uitdagingen, maar hebben over het algemeen{" "}
            <span style={{ color: "#9c29ff" }}>
            meer liquide middelen {" "}
            </span>
            tot hun beschikking in financieel zware tijden. Dit stelt hen in staat om te investeren in moderne technologieën en efficiëntieverbeteringen. Ze kunnen profiteren van{" "}
            <span style={{ color: "#9c29ff" }}>
            schaalvoordelen {" "}
            </span>
            en daardoor concurrerende prijzen bieden, wat hen aantrekkelijk maakt voor klanten die op zoek zijn naar{" "}
            <span style={{ color: "#9c29ff" }}>
            kosteneffectieve oplossingen.
            </span>
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De verdwijning van middelgrote internationale vervoerders heeft een aanzienlijke{" "}
            <span style={{ color: "#2b5baa" }}>
            invloed op de gehele Europese markt
            </span>
            . Het afnemen van diversiteit op de markt leidt tot{" "}
            <span style={{ color: "#9c29ff" }}>
            beperktere keuzemogelijkheden voor klanten
            </span>
            , wat uiteindelijk resulteert in {" "} 
            <span style={{ color: "#2b5baa" }}>
            lagere tarieven voor kleine internationale vervoerders
            </span>
            . Hierdoor behouden grote bedrijven hun dominantie, wat resulteert in een meer{" "}
            <span style={{ color: "#9c29ff" }}>
            uniforme Europese markt{" "}
            </span>
            met verminderde concurrentie. Het blijven zoeken naar niche markten, persoonlijke aandacht voor de klant en het blijven 
            leveren van kwaliteit zal voor klein- en middelgrote transportondernemingen de overlevingsstrategie moeten zijn. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle31;
