import React from "react";
import NewsArticle19 from "../elements/NewsArticle19";
import NewsNav from "../elements/NewsNav";

const NewsDetails19 = () => {
  return (
    <>
      <NewsNav top />
      <NewsArticle19 />
      <NewsNav bottom />
    </>
  );
};

export default NewsDetails19;