import { Box, Card, CardMedia, Container, Typography } from "@mui/material";
import React from "react";

const NewsArticle42 = () => {
  return (
    <>
      <Container
        maxWidth="100%"
        sx={
          {
            // backgroundColor: "#FAFCFF",
          }
        }
      >
        <Container maxWidth="lg">
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <Typography
              sx={{
                mt: 8,
                mb: 5,
                color: "#2B5BAA",
                textAlign: "left",
                fontSize: { xs: "40px", md: "50px", xl: "50px" },
                lineHeight: { xs: "45px", md: "57px", xl: "57px" },
                letterSpacing: "0px",
                fontFamily: "Century Gothic",
                fontWeight: "Bold",
              }}
            >
            Duitsland trekt steun in voor EU-klimaatregels  
            </Typography>
            <div style={{ position: "relative" }}>
              <CardMedia
                component="img"
                src={require("../../assets/NewsImage42.png")}
                alt="news image"
                maxWidth="1119px"
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  color: "#6b6a6a",
                  bottom: 0,
                  right: 0,
                  height: "37px",
                  backgroundColor: "#dbdbdb",
                  opacity: "0.9",
                  // justifyContent: "center",
                  alignItems: "center",
                  pl: "20px",
                  pr: "20px",
                  fontFamily: "HelveticaNeue.Light",
                  fontSize: "27px",
                  lineHeight: "25.2px",
                  letterSpacing: "-0.35px",
                }}
              >
                08-02-24
              </Box>
            </div>
            {/* <CardMedia
              // style={{ height: 100 }}
              component="img"
              src={require("../../assets/NewsImage1x2.png")}
              alt="news image"
              maxWidth="1119px"
            /> */}
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            De{" "}
            <span style={{color: "#2b5baa"}}>
            strengere EU-voorschriften{" "}
            </span>
            voor de CO2-uitstoot van vrachtwagens en bussen staan op losse schroeven nu{" "}
            <span style={{color: "#2b5baa"}}>
            Duitsland{" "}
            </span>
            haar steun intrekt, ondanks een eerder voorlopig akkoord binnen de EU. Deze nieuwe regels zijn bedoeld om zwaar transport ook{" "}
            <span style={{color: "#9c29ff"}}>
            milieuvriendelijker te maken
            </span>
            . Ze leggen vast dat tegen{" "}
            <span style={{color: "#9c29ff"}}>
            2040{" "}
            </span>
            nieuwe vrachtwagens{" "}
            <span style={{color: "#9c29ff"}}>
            90% minder CO2 uitstoot
            </span>
            . Tegen{" "}
            <span style={{color: "#2b5baa"}}>
            2035{" "}
            </span>
            zou dit al{" "}
            <span style={{color: "#2b5baa"}}>
            65%{" "}
            </span>
            moeten zijn en in{" "}
            <span style={{color: "#9c29ff"}}>
            2030 al 45%.
            </span>
            </Typography> 
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
           Hoewel de transportministers van EU-landen en het Europees Parlement vorige maand al overeenstemming bereikten, trekt Duitsland nu zijn steun in, waardoor de{" "}
            <span style={{ color: "#2b5baa"}}>
            bekrachtiging{" "}
            </span>
            van de plannen{" "}
            <span style={{ color: "#2b5baa"}}>
            in gevaar komt
            </span>          
            . De liberale FDP verwijst naar een afspraak waarbij Duitsland zich onthoudt van stemming over EU-plannen als een van de regeringspartijen erop tegen is. 
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 0,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
             <span style={{color: "#9c29ff"}}>
             Zonder de steun van Duitsland{" "}
            </span>
            is de kans groot dat{" "}
            <span style={{color: "#9c29ff"}}>
            het voorstel
            </span>
            ,  dat ook al weerstand ondervond van enkele kleinere EU-landen,{" "}
            <span style={{color: "#9c29ff"}}>
            het niet haalt
            </span>
            . De stem van Duitsland is cruciaal vanwege de omvang van zijn bevolking. Als reactie heeft EU-voorzitter 
            België de definitieve stemming met twee dagen uitgesteld, in de hoop dat Berlijn van gedachten verandert. Op{" "}
            <span style={{color: "#2b5baa"}}>
            09-02-2024{" "}
            </span>
            zou er dus een{" "}
            <span style={{color: "#2b5baa"}}>
            definitief besluit{" "}
            </span>
            gemaakt moeten worden.
            </Typography>
            <Typography
              sx={{
                mt: 5,
                mb: 10,
                color: "#6b6a6a",
                textAlign: "left",
                fontSize: { xs: "24px", md: "30px", xl: "30px" },
                lineHeight: { xs: "32px", md: "40px", xl: "40px" },
                letterSpacing: "0px",
                fontFamily: "HelveticaNeue-Light",
              }}
            >
            Voor transportondernemers blijft het momenteel {" "}
            <span style={{ color: "#9c29ff" }}>
            spannend of het definitieve akkoord{" "}
            </span>
            over de regelgeving {" "}
            <span style={{ color: "#9c29ff" }}>
            tot stand zal komen {" "}
            </span>
            en of er mogelijk nog wijzigingen in de regels en voorwaarden zullen worden doorgevoerd. 
            Zodra het besluit bekend is, is het raadzaam voor transportondernemers om een duidelijk 
            plan op te stellen voor het omgaan met deze aanstaande veranderingen. Het opstellen van 
            een {" "}
            <span style={{ color: "#2b5baa" }}>
            toekomstgericht plan {" "}
            </span>
            op voorhand kan{" "}
            <span style={{ color: "#2b5baa" }}>
            gunstig zijn voor de marktpositie {" "}
            </span>
            en het vermogen om effectief te reageren op de nieuwe regelgeving. 
            </Typography>
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default NewsArticle42;
