import React from "react";
import ContactCallMail from "../elements/ContactCallMail";
import FAQs from "../elements/FAQs";
// import ResponsiveAppBar from "../ResponsiveAppBar";

const FAQ = () => {
  return (
    <>
      <FAQs />
      <ContactCallMail />
    </>
  );
};

export default FAQ;
